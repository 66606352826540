import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { modalContext } from "../../components/modal/modal";
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Ailments } from "../../components/inq/data/ailments";
import {
  ButtonContainer,
  NegativeButton,
  PositiveButton,
} from "../../components/Buttons/styles";
import { styled } from "@mui/styles";
import { AlgActions } from "../../global/redux/alg";

const Form = styled(FormControl)({
  "&&": {
    marginBottom: "1rem",
    background: "white",
    width: "100%",
    borderRadius: "10px",
  },
  "&label": {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  "&legend": {
    fontSize: "0.6rem",
  },
  "&.MuiInputBase-input": {
    width: "100%",
  },
  "& fieldset": {
    width: "100%",
    borderRadius: "10px",
  },
});
const Item = styled(MenuItem)({
  textTransform: "capitalize",
  fontSize: "1rem",
});
export default function CreateAlgoModal({ patient, dataLoader }) {
  const [illness, setIllness] = useState(null);
  const { setShow } = useContext(modalContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Container>
      <Form fullWidth>
        <InputLabel id="demo-simple-select-label">Ailment</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={illness}
          label="Ailment"
          MenuProps={{
            PaperProps: {
              sx: {
                width: "inherit",
                "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                  maxHeight: "40vh",
                },
              },
            },
          }}
          onChange={(e) => setIllness(e.target.value)}
        >
          {Ailments.map((illness, index) => {
            return (
              <Item value={index + 1} key={index}>
                {illness}
              </Item>
            );
          })}
        </Select>
      </Form>
      &nbsp;
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={() => {
            setShow(false);
            dispatch(
              AlgActions.setAlg({
                ailment: Ailments[illness - 1],
                patient,
              })
            );
            navigate(`/algorithms/algorithm/${Ailments[illness - 1]}`);
          }}
        >
          Next
        </Button>
      </ButtonContainer>
    </Container>
  );
}
