
export default {
    index: {
        handleSelect: (index) => {
            if (index == 0) {
                return 'symptoms_exp'
            }
            return 'pregnant'
        },
        type: 'multi',
        select: 1,
        question: 'What is your gender?',
        formId: 'index',
        answers: [
            { text: 'Male' },
            { text: 'Female' },
            { text: 'Other' }
        ],
        optionalKey: 2,
        label: "Please self-identify:",
        placeholder: "(optional)",
        inputType: "text",
        require: true
    },

    pregnant: {
        handleSelect: (index) => { return 'breastfeeding' },
        type: 'simple',
        question: 'Are you (or could you be) pregnant?',
        formId: 'pregnant',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    breastfeeding: {
        handleSelect: (index) => { return 'symptoms_exp' },
        type: 'simple',
        question: 'Are you breastfeeding?',
        formId: 'breastfeeding',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },


    symptoms_exp: {
        handleSelect: (index) => { return 'other_symptoms' },
        type: 'multi',
        question: 'What symptoms are you experiencing?',
        formId: 'symptoms_exp',
        cancleAllIndex: 5,
        answers: [
            { text: 'Tingling' },
            { text: 'Burning' },
            { text: 'Itching' },
            { text: 'Other' },
        ],
        require: true
    },

    other_symptoms: {
        handleSelect: (index) => { return 'cold_sore' },
        type: 'multi',
        question: 'Do you have any other symptoms?',
        formId: 'other_symptoms',
        cancleAllIndex: 5,
        answers: [
            { text: 'Fever (temperature higher than 38°C), chills, or lack of energy' },
            { text: 'Swollen Glands' },
            { text: 'Feeling unwell' },
            { text: 'Nausea or vomiting' },
            { text: 'None of the above' },
            { text: "I don't know" },
        ],
        require: true
    },

    cold_sore: {
        handleSelect: (index) => {
            if (index === 1)
                return 'symptoms_start'
            return 'cold_sore_time'
        },
        type: 'simple',
        question: 'Has the cold sore appeared?',
        formId: 'cold_sore',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },

    cold_sore_time: {
        handleSelect: (index) => { return 'cold_sore_loc' },
        type: 'simple',
        question: 'Has your cold sore been present for more than 14 days?',
        formId: 'cold_sore_time',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },

    cold_sore_loc: {
        handleSelect: (index) => { return 'noticed_pus' },
        type: 'multi',
        question: 'On a daily basis, are you experiencing:',
        formId: 'cold_sore_loc',
        cancleAllIndex: -1,
        answers: [
            { text: 'On or around the mouth' },
            { text: 'Around the nose' },
            { text: 'Near the eyes' },
            { text: 'Other' },
        ],
    },

    noticed_pus: {
        handleSelect: (index) => { return 'symptoms_start' },
        type: 'simple',
        question: 'Have you noticed any pus from the sores?',
        formId: 'noticed_pus',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },


    symptoms_start: {
        handleSelect: (index) => { return 'cold_sore_history' },
        type: 'multi',
        select: 1,
        question: 'Do you experience any of the symptoms listed below?',
        formId: 'symptoms_start',
        cancleAllIndex: -1,
        answers: [
            { text: 'Today' },
            { text: '1-2 days ago' },
            { text: 'More than 2 days ago' },
        ],
        require: true
    },

    cold_sore_history: {
        handleSelect: (index) => {
            if (index == 1)
                return 'immune_system'
            return 'cold_sore_num'
        },
        type: 'simple',
        question: 'Have you ever had cold sores in the past?',
        formId: 'cold_sore_history',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' },
        ],
        require: true
    },

    cold_sore_num: {
        handleSelect: (index) => { return 'immune_system' },
        type: 'multi',
        select: 1,
        question: "Do you have conditions that suppress your immune system?",
        formId: 'cold_sore_num',
        answers: [
            { text: 'None' },
            { text: '1 to 5 episodes' },
            { text: '6 or more episodes' }
        ]
    },

    immune_system: {
        handleSelect: (index) => { return 'immune_medications' },
        type: 'multi',
        question: 'Do you have conditions that suppress your immune system?',
        formId: 'immune_system',
        cancleAllIndex: 5,
        answers: [
            { text: 'HIV/AIDS' },
            { text: 'Cancer' },
            { text: 'Removal of Spleen' },
            { text: 'Transplant' },
            { text: 'Other' },
            { text: 'None of the above' },
            { text: "I don't know" }
        ],
        require: true
    },

    immune_medications: {
        handleSelect: (index) => { return 'treatments' },
        type: 'multi',
        question: 'Are you taking any medications that suppress your immune system?',
        formId: 'immune_medications',
        cancleAllIndex: 5,
        answers: [
            { text: 'Steroids' },
            { text: 'Chemotherapy' },
            { text: 'Biological agents' },
            { text: 'Other' },
            { text: 'None of the above' },
            { text: "I don't know" }
        ],
        require: true
    },

    treatments: {
        handleSelect: (index) => {
            if (index == 1)
                return 'medications'
            else
                return 'feel_better'
        },
        type: 'simple',
        question: 'Have you tried any treatments (eg creams, medication) for your symptoms?',
        formId: 'treatments',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },


    feel_better: {
        handleSelect: (index) => { return 'feel_worse' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel better?',
        formId: 'feel_better',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate",
        placeholder: "e.g. Tums",
        inputType: "text"
    },


    feel_worse: {
        handleSelect: (index) => { return 'effect' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel worse?',
        formId: 'feel_worse',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate which treatments made your symptoms feel worse",
        placeholder: "Type hear ...",
        inputType: "text"
    },


    effect: {
        path: 'effect',
        handleSelect: (index) => { return 'medications' },
        type: 'simple',
        question: 'Did any of the treatments have no effect on your symptoms?',
        formId: 'effect',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate ",
        placeholder: "Type hear ...",
        inputType: "text"
    },

    medications: {
        handleSelect: (index) => { return 'anything' },
        type: 'simple',
        question: 'Do you have any allergies to medications?',
        formId: 'medications',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: 'Please specify medication allergies',
        placeholder: '... ',
        inputType: 'addItem',
    },

    anything: {
        handleSelect: (index) => { return 'finish' },
        type: 'simple',
        question: 'Is there anything else we should know?',
        formId: 'anything',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        placeholder: "Type hear ...",
        inputType: "text"
    },


}
