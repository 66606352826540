import React, { useContext } from "react";
import { PasswordInput } from "../../components/Input/passwordInput";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InputAdornment } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";

import {
  ButtonContainer,
  NegativeButton,
  PositiveButton,
} from "../../components/Buttons/styles";
import { modalContext } from "../../components/modal/modal";
import { CustomTextField } from "../../components/TextFields";
import useProfileController from "./profileController";
import useNotification from "../../components/notification";

const Schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
  newPassword: yup.string().required(),
  repeatNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), undefined], "Password must match")
    .required(),
});

export const ResetPassModal = () => {
  const { setShow } = useContext(modalContext);
  const {
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(Schema),
  });
  const { resetPassword } = useProfileController();
  const { toastError, toastSuccess } = useNotification();
  function onSubmit(data) {
    resetPassword(data)
      .then(() => {
        toastSuccess("Successfully reset password");
        setShow(false);
      })
      .catch((err) => {
        toastError("Problem to reset password");
      });
  }

  return (
    <React.Fragment>
      <CustomTextField
        label="Email"
        type="email"
        variant="outlined"
        error={!!errors.email}
        onChange={(e) => setValue("email", e.target.value)}
        helperText={errors.email?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <MailIcon />
            </InputAdornment>
          ),
        }}
      />
      <PasswordInput
        label="Password"
        error={!!errors.password}
        onChange={(e) => setValue("password", e)}
        helperText={errors.password?.message}
      />
      <PasswordInput
        label="New Password"
        error={!!errors.newPassword}
        onChange={(e) => setValue("newPassword", e)}
        helperText={errors.newPassword?.message}
      />
      <PasswordInput
        label="Repeat Password"
        error={!!errors.repeatNewPassword}
        onChange={(e) => setValue("repeatNewPassword", e)}
        helperText={errors.repeatNewPassword?.message}
      />
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={handleSubmit(onSubmit, (err) => {
            console.log(err);
          })}
        >
          reset
        </Button>
      </ButtonContainer>
    </React.Fragment>
  );
};
