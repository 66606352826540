import React, { useCallback, useContext, useEffect, useState } from "react";
import { Line } from "recharts";
import dayjs from "dayjs";
import { Box, Paper, TableCell, TableRow } from "@mui/material";
// import useTrackApi from "../../../api/track.api";
import { useLocation, useParams } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/BorderColor";
import { DeleteModal, AddModal } from "./modal";
import MainFrame from "../../components/MainFrame";
import NavBar from "../../components/NavBar";
import LineChart from "../forms/components/charts/lineChart";
import {
  CustomTableBody,
  CustomTableContainer,
  TableHeader,
} from "../../components/table";
import Table, { Row } from "../../global/table";
import colors from "../../global/colors";
import { modalContext } from "../../components/modal/modal";
import { Btn, ButtonContainer } from "../../components/Buttons/styles";
import YearMonthInput from "../../components/Input/year-month";
import useTrackesController from "./trackes.controller";
import { usePdf } from "../../pdf";
import TrackPdf from "./pdf";

function Track() {
  const [query, setQuery] = useState({
    date: new Date(),
  });
  const [values, setValues] = useState([]);
  const { state: track } = useLocation();
  const { setShow, setContent } = useContext(modalContext);
  const { getTrackItems, addTrackItem, editTrackItem, deleteTrackItem } =
    useTrackesController();
  const dataLoader = useCallback(() => {
    let isActive = true;
    getTrackItems(track?._id, query)
      .then((result) => {
        if (isActive) setValues(result);
      })
      .catch((err) => console.error(err));

    return () => {
      isActive = false;
    };
  }, [track?._id, query]);
  const openModal = (type, id, value = 0) => {
    if (type === "add") {
      setContent({
        title: "Add Value",
        body: (
          <AddModal
            onSubmit={(value) => {
              addTrackItem(track?._id, { value })
                .then(() => {
                  dataLoader();
                  setShow(false);
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          />
        ),
      });
      setShow(true);
      return;
    }
    if (type === "edit") {
      setContent({
        title: "Edit Value",
        body: (
          <AddModal
            defualtValue={value}
            onSubmit={(value) => {
              editTrackItem(id, { value })
                .then(() => {
                  dataLoader();
                  setShow(false);
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          />
        ),
      });
      setShow(true);
      return;
    }
    if (type === "delete") {
      setContent({
        title: "Delete Value",
        body: (
          <DeleteModal
            onSubmit={() => {
              deleteTrackItem(id)
                .then(() => {
                  dataLoader();
                  setShow(false);
                })
                .catch((err) => console.error(err));
            }}
          />
        ),
      });
      setShow(true);
      return;
    }
  };
  useEffect(dataLoader, [query]);
  const now = dayjs();

  const { generatePdf } = usePdf();

  return (
    <Box
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <NavBar topic={`Track ${track?.title}`}>
        <ButtonContainer>
          <Btn
            variant="contained"
            onClick={() => {
              generatePdf({
                component: TrackPdf,
                props: { values },
              });
            }}
          >
            <img
              src={require("../../assets/images/export.png")}
              width={20}
              alt="export"
            />
          </Btn>
          <Btn variant="contained" onClick={() => openModal("add")}>
            Add value
          </Btn>
        </ButtonContainer>
      </NavBar>
      <YearMonthInput
        label={"Time"}
        onChange={(e) => {
          setQuery({
            ...query,
            date: e,
          });
        }}
      />
      <LineChart
        data={values.map((value) => ({
          name: dayjs(value.updatetAt).format("DD HH:mm"),
          value: Number(value.value),
        }))}
        XAxisDataKey="name"
      >
        <Line
          type="linear"
          dot={{ r: 5 }}
          dataKey="value"
          stroke={colors.darkBlue}
        />
      </LineChart>
      <CustomTableContainer component={Paper} style={{ maxHeight: "35vh" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHeader>
            <TableRow>
              <TableCell style={{ padding: 5 }} width="50%">
                Date
              </TableCell>
              <TableCell style={{ padding: 5 }} width="50%">
                Value
              </TableCell>
              <TableCell style={{ padding: 5 }}>Actions</TableCell>
            </TableRow>
          </TableHeader>
          <CustomTableBody>
            {values?.map((track, index) => {
              const updatetAt = dayjs(track.updatedAt);
              const diff = now.diff(updatetAt, "hour");
              return (
                <Row style={{ cursor: "pointer" }} key={index}>
                  <TableCell style={{ padding: 5, fontSize: "1rem" }}>
                    {updatetAt.format("YYYY-MMM-DD")}&nbsp;&nbsp;
                    <span style={{ color: colors.darkBlue, fontSize: ".9rem" }}>
                      {updatetAt.format("HH:mm")}
                    </span>
                  </TableCell>
                  <TableCell style={{ padding: 5, fontSize: "1rem" }}>
                    {track.value}
                  </TableCell>
                  <TableCell style={{ padding: 5, fontSize: "1rem" }}>
                    {!diff ? ( // 1 houre
                      <div style={{ display: "flex" }}>
                        <IconButton
                          size="small"
                          onClick={() =>
                            openModal("edit", track._id, track.value)
                          }
                        >
                          <EditIcon style={{ fontSize: "20px" }} />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => openModal("delete", track._id)}
                        >
                          <DeleteIcon style={{ fontSize: "20px" }} />
                        </IconButton>
                      </div>
                    ) : null}
                  </TableCell>
                </Row>
              );
            })}
          </CustomTableBody>
        </Table>
      </CustomTableContainer>
    </Box>
  );
}

export default MainFrame(Track);
