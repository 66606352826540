import useApi from "./httpService";

export default function useAlgApi() {
  const api = useApi();

  const getAlgs = async (patientId) => {
    const result = await api({
      method: "GET",
      url: `/patients/${patientId}/algorithms`,
    });
    return result.data;
  };
  const addAlg = async (algState) => {
    const { patient, ...params } = algState;
    console.log("heare in addAlg");
    console.log({ patient });
    await api({
      method: "POST",
      url: `/patients/${patient._id}/algorithms`,
      data: params,
    });
  };

  return {
    getAlgs,
    addAlg,
  };
}
