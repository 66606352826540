export default {
    index: {
        handleSelect: (index) => { return 'gender' },
        type: 'simple',
        question: 'Where would you like the appointment to take place?',
        formId: 'index',
        answers: [
            {
                text: 'inPerson'
            },
            {
                text: 'phone call'
            }
        ]
    },
    gender: {
        handleSelect: (index) => {
            if (index == 0) {
                return 'exp_before'
            }
            return 'pregnant'
        },
        type: 'simple',
        question: 'What is your gender?',
        formId: 'gender',
        answers: [
            { text: 'Male', invalid: true, message: 'Invalid' },
            { text: 'Female' },
            { text: 'Other' }
        ],
        optionalKey: 2,
        label: "Please self-identify:",
        placeholder: "(optional)",
        inputType: "text",
        require: true
    },

    pregnant: {
        handleSelect: (index) => { return 'breastfeeding' },
        type: 'simple',
        question: 'Are you (or could you be) pregnant?',
        formId: 'pregnant',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    breastfeeding: {
        handleSelect: (index) => { return 'exp_before' },
        type: 'simple',
        question: 'Are you breastfeeding?',
        formId: 'breastfeeding',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    exp_before: {
        handleSelect: (index) => { return 'rate_pain' },
        type: 'simple',
        question: 'Have you been officially diagnosed with dysmenorrhea (painful periods) before?',
        formId: 'exp_before',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },


    rate_pain: {
        handleSelect: (index) => { return 'where_pain' },
        type: 'slider',
        question: 'Please rate your pain on a scale of 1-10. (1 being no pain and 10 being the worst possible pain).',
        formId: 'rate_pain',
        label: 'Pain',
        prefix: '',
        min: 1,
        max: 10,
        step: 1
    },

    where_pain: {
        handleSelect: (index) => { return 'when_pain_start' },
        type: 'multi',
        question: 'Where are you experiencing pain?',
        formId: 'where_pain',
        cancleAllIndex: 4,
        answers: [
            { text: 'Lower stomach' },
            { text: 'Lower back' },
            { text: 'Inner thighs' },
            { text: 'Other' },
            { text: 'None of the above' },
        ],
        require: true
    },

    when_pain_start: {
        handleSelect: (index) => { return 'how_long_pain' },
        type: 'multi',
        question: 'When does your pain usually start?',
        formId: 'when_pain_start',
        cancleAllIndex: -1,
        answers: [
            { text: 'A few hours before or just after beginning of your period' },
            { text: 'During the first three days of your periods' },
            { text: 'After the first three days of your period' },
            { text: 'Other' },
        ],
        require: true
    },
    how_long_pain: {
        handleSelect: (index) => { return 'first_pain_exp' },
        type: 'multi',
        select: 1,
        question: 'How long does the pain usually last for?',
        formId: 'how_long_pain',
        answers: [
            { text: '1 - 2 days' },
            { text: '2 - 3 days' },
            { text: 'More than 3 days' },
        ],
        require: true
    },

    first_pain_exp: {
        handleSelect: (index) => { return 'most_periods' },
        type: 'multi',
        select: 1,
        question: 'When did you first start experiencing painful periods?',
        formId: 'first_pain_exp',
        answers: [
            { text: 'During the first 2 years of my first period in my life' },
            { text: 'More than 2 years after the first period in my life' },
            { text: 'This is my first time having a painful period' },
            { text: 'Other' },
        ],
        require: true
    },



    most_periods: {
        handleSelect: (index) => { return 'getting_worse' },
        type: 'simple',
        question: 'Are most of your periods painful?',
        formId: 'most_periods',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },


    getting_worse: {
        handleSelect: (index) => { return 'first_period' },
        type: 'simple',
        question: 'Has your pain been getting worse with each period?',
        formId: 'getting_worse',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },


    first_period: {
        handleSelect: (index) => { return 'birth_control' },
        type: 'simple',
        question: 'Did you get your first period in your life within the last 6 months?',
        formId: 'first_period',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    birth_control: {
        handleSelect: (index) => { return 'iud' },
        type: 'simple',
        question: 'Are you using any form of birth control?',
        formId: 'birth_control',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ],
        optionalKey: 0,
        inputType: 'text',
        label: 'Please specify ...',
    },

    iud: {
        handleSelect: (index) => { return 'symptoms' },
        type: 'simple',
        question: 'Have you had an IUD inserted within the past 6 months?',
        formId: 'iud',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ],
        optionalKey: 0,
        inputType: 'text',
        label: 'Please specify ...',
    },

    symptoms: {
        handleSelect: (index) => { return 'period_describe' },
        type: 'multi',
        question: 'Are you experiencing any of these symptoms?',
        formId: 'symptoms',
        cancleAllIndex: 5,
        answers: [
            { text: 'Nausea or vomiting' },
            { text: 'Tiredness' },
            { text: 'Diarrhea' },
            { text: 'Headache' },
            { text: 'Other' },
            { text: 'None of the above' },
        ],
        require: true
    },

    period_describe: {
        handleSelect: (index) => { return 'symptoms_exp' },
        type: 'multi',
        question: 'Do any of these describe your period flow?',
        formId: 'period_describe',
        cancleAllIndex: 3,
        answers: [
            { text: 'I am bleeding heavier than usual' },
            { text: 'I am bleeding longer than usual' },
            { text: 'I am spotting or bleeding in between my periods' },
            { text: 'None of the above' },
        ],
        require: true
    },

    symptoms_exp: {
        handleSelect: (index) => { return 'other_symptoms' },
        type: 'multi',
        question: 'Are you experiencing any of these symptoms?',
        formId: 'symptoms_exp',
        cancleAllIndex: 4,
        answers: [
            { text: 'Fever (temperature higher than 38°C) or chills' },
            { text: 'Unusual vaginal discharge' },
            { text: 'Painful intercourse' },
            { text: 'A lump or tenderness around your pelvic area' },
            { text: 'None of the above' },
        ],
        require: true
    },

    other_symptoms: {
        handleSelect: (index) => { return 'h_medical_conditions_1' },
        type: 'simple',
        question: 'Are you experiencing any other symptoms?',
        formId: 'other_symptoms',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        inputType: "text"

    },


    h_medical_conditions_1: {
        handleSelect: (index) => { return 'h_medical_conditions_2' },
        type: 'multi',
        question: 'Do you have a history of any of the following medical conditions?',
        formId: 'h_medical_conditions_1',
        cancleAllIndex: 4,
        answers: [
            { text: 'Endometriosis' },
            { text: 'Pelvic inflammatory disease' },
            { text: 'Ovarian cysts' },
            { text: 'Irritable bowel syndrome (IBS) or Inflammatory bowel disease (IBD)' },
            { text: 'None of the above' },
            { text: 'I am not sure' },
        ],
        require: true
    },
    h_medical_conditions_2: {
        handleSelect: (index) => { return 'blood_thinners' },
        type: 'multi',
        question: 'Do you have a history of any of the following medical conditions?',
        formId: 'h_medical_conditions_2',
        cancleAllIndex: 5,
        answers: [
            { text: 'Poor kidney function' },
            { text: 'Bleeding disorder' },
            { text: 'High blood pressure' },
            { text: 'History of heart attack or stroke' },
            { text: 'Any other heart conditions' },
            { text: 'None of the above' },
            { text: 'I am not sure' },
        ],
        require: true
    },

    blood_thinners: {
        handleSelect: (index) => { return 'treatments' },
        type: 'simple',
        question: 'Are you taking any blood thinners?',
        formId: 'blood_thinners',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },

    treatments: {
        handleSelect: (index) => {
            if (index == 1)
                return 'medications'
            else
                return 'feel_better'
        },
        type: 'simple',
        question: 'Have you tried any medications or treatments for your period pain?',
        formId: 'treatments',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },


    feel_better: {
        handleSelect: (index) => { return 'feel_worse' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel better?',
        formId: 'feel_better',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate",
        placeholder: "e.g. Tums",
        inputType: "text"
    },
    feel_worse: {
        handleSelect: (index) => { return 'effect' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel worse?',
        formId: 'feel_worse',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate which treatments made your symptoms feel worse",
        placeholder: "Type hear ...",
        inputType: "text"
    },
    effect: {
        path: 'effect',
        handleSelect: (index) => { return 'medications' },
        type: 'simple',
        question: 'Did any of the treatments have no effect on your symptoms?',
        formId: 'effect',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate ",
        placeholder: "Type hear ...",
        inputType: "text"
    },

    medications: {
        handleSelect: (index) => { return 'finish' },
        type: 'simple',
        question: 'Do you have any allergies to medications?',
        formId: 'medications',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: 'Please specify medication allergies',
        placeholder: '... ',
        inputType: 'addItem',
    },
   
}
