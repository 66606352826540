import { isEqual } from "lodash";
import { Fragment, memo, useState } from "react";
import { connect } from "react-redux";
import NavBar from "../../../../components/NavBar";
import { Box } from "@material-ui/core";
import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DownloadIcon from "@mui/icons-material/Download";

import { Btn, ButtonContainer } from "../../../../components/Buttons/styles";
import getFormComponent from "../resolver";
import questions from "../questions";
import { useNavigate } from "react-router-dom";
import generatePdf from "../pdf";
import { FormActiosn } from "../../../../global/redux/form";
import { usePdf } from "../../../../pdf";
import FinalQuestions from "./finalQuestions";

import RenderDocument from "../pdf";

function Preview({ illness, formState, dispatch }) {
  const { stack, data } = formState;
  const formQuestions = questions[illness];
  const navigate = useNavigate();
  const onEdit = (formId) => {
    const index = stack.findIndex((item) => item.formId === formId);
    if (index >= 0) {
      dispatch(FormActiosn.navigateTo({ index }));
      navigate(-1);
    }
  };

  const { generatePdf } = usePdf();

  if (!formQuestions) return <p>Unsuported illness</p>;
  return (
    <Fragment>
      <NavBar
        topic={
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>Preview</span>
          </Box>
        }
      >
        <ButtonContainer>
          <Btn
            variant="contained"
            onClick={() => {
              generatePdf({
                component: RenderDocument,
                props: { illness, formState },
              });
            }}
          >
            <DownloadIcon style={{ fontSize: "1.5rem", color: "#222" }} />
          </Btn>
        </ButtonContainer>
      </NavBar>
      <Box style={{ maxHeight: "80vh", overflow: "auto" }}>
        {stack.map((form, index) => {
          const FormComponent = getFormComponent(form.resolver, true);
          const formData = data[form.formId];
          return (
            <FormComponent
              key={index}
              {...formQuestions[form.formId]}
              formData={formData}
              onEdit={onEdit}
            />
          );
        })}
        <FinalQuestions metadata={data["metadata"]} />
      </Box>
    </Fragment>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    formState: state.forms,
  };
}

export default connect(mapStateToProps)(
  memo(Preview, function (prev, next) {
    return (
      isEqual(prev.formState, next.formState) && prev.illness == next.illness
    );
  })
);
