import axios from "axios";
import config from "../constants/http";
import { useNavigate } from "react-router-dom";

export default function useApi() {
  const navigate = useNavigate();
  const api = axios.create({
    ...config,
    withCredentials: true,
  });

  api.interceptors.response.use(
    /**
     *
     * @param {AxiosResponse} response
     */
    (response) => {
      if (response.status == 301 || response.status == 302) {
        navigate(response.headers.location);
        throw new Error("");
      }

      return response;
    },
    /**
     *
     * @param {AxiosError} error
     */
    (error) => {
      if (error.response) {
        if (error.response.status == 500) {
          throw new Error("");
        }

        if (error.response.status == 403 || error.response.status == 401) {
          const location = error.response.headers?.location;
          if (location) {
            navigate(location);
            throw new Error("");
          }
        }
      }
      throw error;
    }
  );

  return api;
}
