import { useCallback, useEffect, useState } from "react";
import { AddModal } from "../../../Patients/modal";
import { Autocomplete, Box } from "@mui/material";
import { styled } from "@mui/styles";
import { CustomTextField } from "../../../../components/TextFields";
import usePatientsApi from "../../../../api/patient.api";
import useNotification from "../../../../components/notification";

const CustomAutoComplete = styled(Autocomplete)({
  "&&.css-1hsevg8-MuiAutocomplete-listbox .MuiAutocomplete-option": {
    fontSize: ".9rem",
  },
});
export const PatientSelectionInput = ({
  onChange,
  err,
  helperText,
  defaultValue,
}) => {
  const [patient, setPatient] = useState(defaultValue);
  const [options, setOptions] = useState([]);
  const api = usePatientsApi();
  const { addNotification } = useNotification();
  const loadPatients = useCallback(() => {
    let isActive = true;
    api
      .getPatients({})
      .then((patients) => {
        if (isActive)
          setOptions(
            patients.map((patient) => ({
              ...patient,
              userName: `${patient.firstName} ${patient.lastName}`,
            }))
          );
      })
      .catch((err) => {
        console.error(err);
        addNotification({
          severity: "error",
          text: "problem to get patient list",
          duration: 2000,
          onClick: null,
        });
      });
  });
  useEffect(loadPatients, []);

  return (
    <Box
      style={{
        display: "flex",
        gap: "1rem",
        justifyContent: "center ",
        width: "100%",
      }}
    >
      <CustomAutoComplete
        disablePortal
        fullWidth
        id="combo-box-demo"
        options={options}
        getOptionLabel={(option) => option.userName}
        renderInput={(params) => (
          <CustomTextField
            error={err}
            helperText={helperText}
            {...params}
            fullWidth
            variant="outlined"
            label="Patient"
          />
        )}
        onChange={(e, value) => {
          if (value == null) return setPatient({});
          setPatient(value);
          if (onChange) onChange(value);
        }}
        value={Object.keys(patient ?? {}).length ? patient : undefined}
      />
      <AddModal dataLoader={loadPatients} />
    </Box>
  );
};
