import * as React from "react";
import { useTheme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import colors from "../../global/colors";
import GroupsIcon from "@mui/icons-material/Groups";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useContext } from "react";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import QuizIcon from "@mui/icons-material/Quiz";
import { modalContext } from "../modal/modal";
import SearchModal, { LogoutModal } from "./modal";
import TextContainer from "../Texts/TextContainer";
import { useEffect } from "react";
import CustomAvatar from "../avatar";
import SearchInput from "../Input/searchInput";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import SettingsIcon from "@mui/icons-material/Settings";
import getUsername from "../../utils/getUsername";

const drawerWidth = 220;
const MenuItems = [
  {
    id: "patient",
    text: "Patients",
    icon: <GroupsIcon />,
    navigation: "/Patients",
  },
  {
    id: "followup",
    text: "Folllowups",
    icon: <QuizIcon />,
    navigation: "/followUps",
  },
  {
    id: "request",
    text: "Requests",
    icon: <TouchAppIcon />,
    navigation: "/requests",
  },
  {
    id: "appointments",
    text: "Appointments",
    icon: <EditCalendarIcon />,
    navigation: "/appointments",
  },
  {
    id: "settings",
    text: "Settings",
    icon: <SettingsIcon />,
    navigation: "/profile",
  },
];
const UsernameWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: "1rem",
  cursor: "pointer",
  alignItems: "center",
  width: "100%",
  textAlign: "center",
  padding: "0 .5rem",
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    marginTop: "3rem",
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function DrawerMenu({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [searchResult, setSearchResult] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState("Patients");
  const navigate = useNavigate();
  const { setShow, setContent } = useContext(modalContext);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleOpenModal = (type, ...args) => {
    if (type === "logout")
      setContent({
        title: "Logout",
        body: <LogoutModal />,
      });
    if (type === "search") {
      setContent({
        title: "search ailments...",
        body: <SearchModal />
      })
    } else {

    }
    setShow(true);
  };
  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);
  const handleItemClick = (item) => {
    setSelectedItem(item);
    navigate(item.navigation);
  };
  useEffect(() => {
    if (screenWidth < 868) {
      setOpen(false);
    }
  }, [screenWidth]);

  const userName = getUsername();
  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "...";
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{ background: colors.darkBlue, zIndex: 10 }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <img
              alt="logo"
              src={require("../../assets/images/logo.png")}
              width="4%"
              height="4%"
              style={{
                minWidth: "2rem",
                minHeight: "2rem",
                maxWidth: "2.5rem",
                maxHeight: "2.5rem",
              }}
            />
            &nbsp;
            <Typography variant="h6" noWrap component="div">
              PharmaCanada
            </Typography>
          </Box>
          <SearchInput handleClick={() => handleOpenModal("search")} />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            zIndex: 1,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <UsernameWrapper onClick={() => navigate("/profile")}>
            <CustomAvatar name={userName} />
            <Typography
              noWrap
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="100px"
              fontSize=".8rem"
              cursor="pointer"
            >
              {truncateText(userName, 15)}
            </Typography>
          </UsernameWrapper>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {MenuItems.map((item, index) => (
            <ListItem
              selectedItem
              key={index}
              disablePadding
              onClick={() => {
                handleItemClick(item);
              }}
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <TextContainer fontSize="1rem">{item.text}</TextContainer>
              </ListItemButton>
            </ListItem>
          ))}
          <Divider />
          <ListItem disablePadding onClick={() => handleOpenModal("logout")}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <TextContainer fontSize="1rem">Logout</TextContainer>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        {children}
      </Main>
    </Box>
  );
}
