import { Paper, TableCell, TableRow } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@material-ui/core";
import { AddTrackModal, DeleteTrackModal } from "./modal";
import MainFrame from "../../components/MainFrame";
import Filter from "../../components/filter/filter";
import NavBar from "../../components/NavBar";
import { modalContext } from "../../components/modal/modal";
import Table, { Row } from "../../global/table";
import AddIcon from "@mui/icons-material/Add";

import Footer, {
  CustomTableBody,
  CustomTableContainer,
  TableHeader,
} from "../../components/table";
import colors from "../../global/colors";
import { styled } from "@mui/styles";
import { ButtonContainer } from "../../components/Buttons/styles";
import { Btn } from "../../components/Buttons/styles";
import useTrackesController from "./trackes.controller";

const filters = [
  { label: "Illness", type: "name", collection: "subject" },
  { label: "Subject", type: "name", collection: "title" },
];
const CustomRow = styled(Row)({
  cursor: "pointer",
  ":hover": {
    background: colors.lightBgGray0,
  },
});
function PatientTrackes() {
  const { state: patient } = useLocation();
  const [trackes, setTrackes] = React.useState([]);
  const [query, setQuery] = React.useState({
    page: 0,
    size: 20,
  });
  const navigate = useNavigate();
  // const api = useTrackApi();
  const { setShow, setContent } = useContext(modalContext);
  const openModal = (type, trackId) => {
    if (type === "delete") {
      setContent({
        title: "Delete Track",
        body: (
          <DeleteTrackModal
            onDelete={() => {
              deleteTrack(trackId)
                .then(() => {
                  dataLoader();
                  setShow(false);
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          />
        ),
      });
    } else if (type === "add") {
      setContent({
        title: "Add new Track",
        body: (
          <AddTrackModal
            onAdd={(data) => {
              addTrack(patient?._id, data)
                .then(() => {
                  dataLoader();
                  setShow(false);
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          />
        ),
      });
    }
    setShow(true);
  };
  const { getTracks, addTrack, deleteTrack } = useTrackesController();
  const dataLoader = useCallback(() => {
    let isActive = true;
    getTracks(patient?._id, query)
      .then((result) => {
        if (isActive) setTrackes(result);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isActive = false;
    };
  }, [patient?._id, query]);

  useEffect(dataLoader, [query]);

  return (
    <>
      <NavBar topic={`${patient?.firstName} ${patient?.lastName}`}>
        <ButtonContainer>
          <Btn variant="contained" onClick={() => openModal("add")}>
            <AddIcon />
          </Btn>
        </ButtonContainer>
      </NavBar>
      <Filter
        convertor={"toMongoo"}
        filters={filters}
        dataLoader={(newQuery) => {
          setQuery({
            page: query.page,
            size: query.size,
            ...newQuery,
          });
        }}
      />
      <CustomTableContainer component={Paper} style={{ maxHeight: "73vh" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHeader>
            <TableRow>
              <TableCell width="30.5%">Illness</TableCell>
              <TableCell width="30.5%">Subject</TableCell>
              <TableCell width="30.5%">Date Created</TableCell>
              <TableCell >Actions</TableCell>
            </TableRow>
          </TableHeader>
          <CustomTableBody>
            {trackes?.map((track, index) => {
              return (
                <CustomRow
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() => {
                    // navigate(`/trackes/track/${track.id}`)
                    navigate("/tracks/track", { state: track });
                  }}
                >
                  <TableCell style={{ padding: 5 }}>{track.subject}</TableCell>
                  <TableCell style={{ padding: 5 }}>{track.title}</TableCell>
                  <TableCell style={{ padding: 5 }}>
                    {dayjs(track.createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell style={{ display: "flex", flexDirection: "row", padding: 5 }}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        openModal("delete", track._id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </CustomRow>
              );
            })}
          </CustomTableBody>
        </Table>
        <Footer value={query} handleChange={(e) => {
                    setQuery({
                        ...query,
                        ...e
                    })
                }} />
      </CustomTableContainer>
    </>
  );
}

export default MainFrame(PatientTrackes);
