import { connect, useSelector } from "react-redux";
import CollapsibleButton from "../../../../components/Buttons/collapsibleButton";
import TextContainer from "../../../../components/Texts/TextContainer";
import { Box, IconButton, Typography } from "@mui/material";
import colors from "../../../../global/colors";
import { memo, useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from "@emotion/styled";
import EditIcon from '@mui/icons-material/Edit';
import { isEqual } from "lodash";

const ButtonsContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    padding: 0,
    justifyContent: "flex-end"
})


export default function SimpleQuestion({
    formId,
    onEdit,
    question,
    answers,
    formData,
}) {
    return (
        <CollapsibleButton
            titleComponent={() => {
                return (
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "flex-start", gap: "5%", width: "100%" }}>
                            <TextContainer>
                                <Typography textTransform="capitalize" fontSize="1rem">
                                    {question}
                                </Typography>
                            </TextContainer>
                            <TextContainer>
                                <Typography color={colors.darkBlue} textTransform="capitalize" fontSize="1rem">
                                    {answers[formData.answer].text}
                                </Typography>
                            </TextContainer>
                        </div>
                        <IconButton style={{ marginRight: "1rem" }}
                            onClick={(e) => {
                                e.stopPropagation()
                                onEdit(formId)
                            }}
                        >
                            <EditIcon
                                style={{ color: colors.darkBlue }}
                                fontSize="22px" />
                        </IconButton>
                    </div>
                )
            }}
            isOpen={false}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                maxHeight: "35vh",
                overflow: "auto"
            }}>
                <TextContainer>
                    <Typography borderBottom="1px solid #EEE" marginBottom="2%" paddingBottom="1%" width="100%" textAlign="left" textTransform="capitalize" fontSize=".9rem">
                        <b>optional:</b>&nbsp;
                        {
                            (formData?.optional && formData.optional != '') ?
                                "optional items"
                                :
                                "nothing!"
                        }
                    </Typography>
                </TextContainer>
                <TextContainer>
                    <Typography width="100%" textAlign="left" textTransform="capitalize" fontSize=".9rem">
                        <b style={{fontSize:"14px"}}>comment:</b>&nbsp;
                        {
                            (formData?.comment && formData.comment != '') ?
                                formData.comment
                                :
                                "no comment!"
                        }
                    </Typography>
                </TextContainer>
            </div>
        </CollapsibleButton>
    )
}

