import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Camera as CameraIcon } from "@mui/icons-material";
import CustomAvatar from "../avatar";
import colors from "../../global/colors";
import { styled } from "@mui/styles";
import getUsername from "../../utils/getUsername";
const UploadButton = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  opacity: 0,
  visibility: "hidden",
  transition: "all 0.3s ease",
  cursor: "pointer",
});
const ProfileImageUploader = ({ onChange }) => {
  const [image, setImage] = useState(null);
  const handleImageUpload = (event) => {
    setImage(event.target.files[0]);
    onChange(event.target.files[0]);
  };

  const userName = getUsername();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        mb: "1rem",
      }}
    >
      <Box
        position="relative"
        width="6rem"
        height="6rem"
        borderRadius="50%"
        overflow="hidden"
        boxShadow={colors.boxShadow}
        sx={{
          "&:hover .upload-overlay": {
            opacity: 1,
            visibility: "visible",
          },
        }}
      >
        {image ? (
          <img
            src={URL.createObjectURL(image)}
            alt="Profile"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <CustomAvatar
            name={userName}
            size={{ width: "6rem", height: "6rem" }}
          />
        )}
        <UploadButton className="upload-overlay">
          <IconButton
            component="label"
            color="primary"
            aria-label="Upload profile image"
          >
            <CameraIcon />
            <input
              type="file"
              accept="image/png"
              hidden
              onChange={handleImageUpload}
            />
          </IconButton>
        </UploadButton>
      </Box>
    </Box>
  );
};

export default ProfileImageUploader;
