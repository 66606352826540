import useApi from "./httpService";

export default function useAppointmentApi() {
  const api = useApi();
  const userId = localStorage.getItem("user_id");

  async function addAppointment(data) {
    await api({
      method: "POST",
      url: `/owners/${userId}/appointments`,
      data,
    });
  }

  async function getAppointment(query) {
    return api({
      method: "GEt",
      url: `/owners/${userId}/appointments`,
      params: query,
    }).then((result) => result.data);
  }

  async function deleteAppointment(appointmentId) {
    await api({
      method: "DELETE",
      url: `/appointments/${appointmentId}`,
    });
  }

  async function appointmentComment(appointmentId, data) {
    await api({
      method: "PATCH",
      url: `/appointments/${appointmentId}`,
      data,
    });
  }

  return {
    addAppointment,
    getAppointment,
    deleteAppointment,
    appointmentComment,
  };
}
