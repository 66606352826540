import styled from "@emotion/styled";


export default styled.text`
    direction: ${props => props.direction ? props.direction : "ltr"};
    margin: ${props => props.margin ? props.margin : "0"};
    font-size: ${props => props.fontSize ? props.fontSize : "inherit"};
    text-wrap: ${props => props.textWrap ? props.textWrap : "inherit"};
    color: ${(props) => props.color ? props.color : "inherit"};
    -webkit-text-stroke-color: ${(props) => props.stroke ? props.stroke : "whtie"};;
    -webkit-text-stroke-width: ${(props) => props.stroke ? props.strokeFont ? props.strokeFont : "2.5px" : "0px"};;
    align-self: ${(props) => props.alignSelf ? props.alignSelf : "none"};
    padding: ${(props) => props.padding ? props.padding : "initial"};
    ${(props) => props.gradient ? "background:" + props.gradient + ";" : ""}
    ${(props) => props.gradient ? "-webkit-background-clip: text;" : ""}
    ${(props) => props.gradient ? "-webkit-text-fill-color: transparent;" : ""}
    max-width: ${props => props.maxWidth ? props.maxWidth : "initial"};
    width: ${props => props.width ? props.width : "initial"};
    height: ${props => props.maxHeight ? props.maxHeight : "initial"};
    overflow: ${props => props.overflow ? props.overflow : "initial"};
    text-overflow: ${props => props.textOverflow ? props.textOverflow : "initial"};
    text-align: ${props => props.textAlign ? props.textAlign : "initial"};
    text-transform: ${props => props.textTransform ? props.textTransform : ""};
    cursor: ${props => props.cursor ? props.cursor : ""};
    transition: ${props => props.transition ? props.transition : ""};
    white-space: ${props => props.whiteSpace ? props.whiteSpace : "initial"};
    font-weight: ${props => props.fontWeight ? props.fontWeight : "normal"};
    line-height: ${props => props.lignHeight ? props.lignHeight : "30px"};
    display: ${props => props.display ? props.display : "flex"};;
    flex-direction: row;
    text-decoration:${props => props.textDecoration ? props.textDecoration : "none"};
    align-items: ${props => props.alignItems ? props.alignItems : "center"};
    justify-content: ${props => props.justifyContent ? props.justifyContent : "center"};
    &:hover{
        background:${props => props.hoveredBg ? props.hoveredBg : ""};
        transform:${props => props.hoveredTransform ? props.hoveredTransform : ""}
    }
`