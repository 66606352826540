
const _ = 
{
    baseURL:'/apiv1',
    headers:{
    },
}

export default _ ;

