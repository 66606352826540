import { Fragment } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import MainFrame from '../../../components/MainFrame'
import questions from "./questions";
import colors from "../../../global/colors";
import Edit from "./edit";
import Preview from "./preview";


function FormBuilder() {
    const { illness } = useParams();
    const [query] = useSearchParams()
    const isPreview = !!query.get('preview');
    if (!(illness in questions)) {
        return <p style={{ textTransform: "capitalize", color: colors.darkBlue }}>Somthing went wrong we dont support this ailment yet!</p>
    }
    return (
        <Fragment>
            {
                isPreview ?
                    <Preview
                        illness={illness}
                    />
                    :
                    <Edit
                        illness={illness}
                    />
            }
        </Fragment>
    )
}

export default MainFrame(FormBuilder)
