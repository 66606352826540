import {
  createTheme,
  responsiveFontSizes
} from '@mui/material/styles';

let totalTheme = createTheme({

  typography: {
  },
  overrides: {
    // Style rules for the scrollbar
    '*::-webkit-scrollbar': {
      width: '0.4em',
      height: '0.4em',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'red',
      borderRadius: '1em',
    },
    '*::-webkit-scrollbar-track': {
      borderRadius: '1em',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
      },
    },
  },

  palette: {
    neutral: {
      light: '#64748B',
    },
  },


  breakpoints: {
    values: { xs: 600, sm: 800, md: 1000, lg: 1200, xl: 1536, }
  },
  typography: {
    h1: { fontSize: 69 },
    h2: { fontSize: 57 },
    h3: { fontSize: 48 },
    h4: { fontSize: 40 },
    h5: { fontSize: 33 },
    h6: { fontSize: 28 },
    subtitle1: { fontSize: 23 },
    subtitle2: { fontSize: 19 },
    body1: { fontSize: 19 },
    body2: { fontSize: 16 }
  }
});

// theme = responsiveFontSizes(theme);

export default totalTheme;

