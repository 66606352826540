
export default {
    index: {
        handleSelect: (index) => { return 'gender' },
        type: 'simple',
        question: 'Where would you like the appointment to take place?',
        formId: 'index',
        answers: [
            {
                text: 'inPerson'
            },
            {
                text: 'phone call'
            }
        ]
    },
    gender: {
        handleSelect: (index) => {
            if (index == 0) {
                return 'gender'
            }
            return 'pregnant'
        },
        type: 'simple',
        question: 'What is your gender?',
        formId: 'gender',
        answers: [
            { text: 'Male', invalid: true, message: 'Invalid' },
            { text: 'Female' },
            { text: 'Other' }
        ],
        optionalKey: 2,
        label: "Please self-identify:",
        placeholder: "(optional)",
        inputType: "text",
        require: true
    },
    pregnant: {
        handleSelect: (index) => {
            if (!index)
                return 'pregnant'
            return 'breastfeeding'
        },
        type: 'simple',
        question: 'Are you (or could you be) pregnant?',
        formId: 'pregnant',
        answers: [
            { text: 'yes', invalid: true, message: 'Invalid' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    breastfeeding: {
        handleSelect: (index) => { return 'exp_before' },
        type: 'simple',
        question: 'Are you breastfeeding?',
        formId: 'breastfeeding',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },


    exp_before: {
        handleSelect: (index) => {
            if (index == 1)
                return 'symptoms_exp'
            return 'another_uti_1'

        },
        type: 'simple',
        question: 'Have you been diagnosed with a urinary tract infection (UTI) before?',
        formId: 'exp_before',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    another_uti_1: {
        handleSelect: (index) => { return 'another_uti_2' },
        type: 'simple',
        question: 'Have you had another UTI in the last 30 days?',
        formId: 'another_uti_1',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    another_uti_2: {
        handleSelect: (index) => { return 'another_uti_3' },
        type: 'simple',
        question: 'Have you had another UTI in the last 6 months?',
        formId: 'another_uti_2',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    another_uti_3: {
        handleSelect: (index) => { return 'symptoms_exp' },
        type: 'simple',
        question: 'Have you had at least 2 other UTIs in the last 12 months?',
        formId: 'another_uti_3',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },



    symptoms_exp: {
        handleSelect: (index) => { return 'symptoms' },
        type: 'multi',
        question: 'What symptoms are you experiencing?',
        formId: 'symptoms_exp',
        cancleAllIndex: 5,
        answers: [
            { text: 'Discomfort, burning or pain with urination' },
            { text: 'Urinating more often than normal' },
            { text: 'Sudden urges to urinate' },
            { text: 'Blood in your urine' },
            { text: 'Pain in your back, side, or ribs' },
            { text: 'Pain or discomfort in your lower stomach area' },
            { text: 'None of the above' },
        ],
        require: true
    },

    symptoms: {
        handleSelect: (index) => { return 'other_symptoms' },
        type: 'multi',
        question: 'What symptoms are you experiencing?',
        formId: 'symptoms',
        cancleAllIndex: 5,
        answers: [
            { text: 'Painful intercourse' },
            { text: 'Itching or sores in the genital area' },
            { text: 'Fever (temperature higher than 38°C), chills, or lack of energy' },
            { text: 'Nausea or vomiting' },
            { text: 'Foul smelling or unusual vaginal discharge' },
            { text: 'None of the above' },
        ],
        require: true
    },

    other_symptoms: {
        handleSelect: (index) => { return 'diabetes' },
        type: 'simple',
        question: 'Do you have any other symptoms not mentioned in the previous questions?',
        formId: 'other_symptoms',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        inputType: "text"

    },

    diabetes: {
        handleSelect: (index) => { return 'immune_system' },
        type: 'simple',
        question: 'Do you have diabetes?',
        formId: 'diabetes',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        inputType: "text"

    },


    immune_system: {
        handleSelect: (index) => { return 'immune_medications' },
        type: 'multi',
        question: 'Do you have conditions that suppress your immune system?',
        formId: 'immune_system',
        cancleAllIndex: 5,
        answers: [
            { text: 'HIV/AIDS' },
            { text: 'Cancer' },
            { text: 'Removal of Spleen' },
            { text: 'Transplant' },
            { text: 'Other' },
            { text: 'None of the above' },
            { text: "I don't know" }
        ],
        require: true
    },

    immune_medications: {
        handleSelect: (index) => { return 'poor_kidney' },
        type: 'multi',
        question: 'Is your baby taking any medications that suppress their immune system?',
        formId: 'immune_medications',
        cancleAllIndex: 5,
        answers: [
            { text: 'Steroids' },
            { text: 'Chemotherapy' },
            { text: 'Biological agents' },
            { text: 'Other' },
            { text: 'None of the above' },
            { text: "I don't know" }
        ],
        require: true
    },


    poor_kidney: {
        handleSelect: (index) => { return 'spinal_cord_injury' },
        type: 'simple',
        question: 'Has a healthcare provider ever told you that you have poor kidney function?',
        formId: 'poor_kidney',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },

    spinal_cord_injury: {
        handleSelect: (index) => { return 'abnormal' },
        type: 'simple',
        question: 'Have you ever had a spinal cord injury?',
        formId: 'spinal_cord_injury',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },


    abnormal: {
        handleSelect: (index) => { return 'treatments' },
        type: 'multi',
        question: 'Do you have an abnormal urinary tract structure or function?',
        formId: 'abnormal',
        cancleAllIndex: 3,
        answers: [
            { text: 'Indwelling Catheter' },
            { text: 'Kidney Stones' },
            { text: 'Other' },
            { text: 'None of the above' },
            { text: "I don't know" }
        ],
        require: true
    },

    treatments: {
        handleSelect: (index) => {
            if (index == 1)
                return 'medications'
            else
                return 'feel_better'
        },
        type: 'simple',
        question: 'Have you tried any treatments (eg creams, medication) for your symptoms?',
        formId: 'treatments',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },


    feel_better: {
        handleSelect: (index) => { return 'feel_worse' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel better?',
        formId: 'feel_better',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate",
        placeholder: "e.g. Tums",
        inputType: "text"
    },


    feel_worse: {
        handleSelect: (index) => { return 'effect' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel worse?',
        formId: 'feel_worse',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate which treatments made your symptoms feel worse",
        placeholder: "Type hear ...",
        inputType: "text"
    },


    effect: {
        path: 'effect',
        handleSelect: (index) => { return 'medications' },
        type: 'simple',
        question: 'Did any of the treatments have no effect on your symptoms?',
        formId: 'effect',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate ",
        placeholder: "Type hear ...",
        inputType: "text"
    },

    medications: {
        handleSelect: (index) => { return 'new_medications' },
        type: 'simple',
        question: 'Do you have any allergies to medications?',
        formId: 'medications',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: 'Please specify medication allergies',
        placeholder: '... ',
        inputType: 'addItem',
    },


    new_medications: {
        handleSelect: (index) => { return 'anything' },
        type: 'simple',
        question: 'Have you started any new medications in the past month?',
        formId: 'new_medications',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: 'Please specify',
        placeholder: '... ',
        inputType: 'addItem',
    },

    anything: {
        handleSelect: (index) => { return 'finish' },
        type: 'simple',
        question: 'Is there anything else we should know?',
        formId: 'anything',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        placeholder: "Type hear ...",
        inputType: "text"
    },
}
