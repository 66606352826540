import { React, useState } from "react";
import { connect } from "react-redux";
import Layout from "./edit/layout";
import useFormApi from "../../../api/forms.api";
import { FormActiosn } from "../../../global/redux/form";
import { useNavigate } from "react-router-dom";
import useNotification from "../../../components/notification";
import { Btn, ButtonContainer } from "../../../components/Buttons/styles";
import { Box } from "@mui/material";
import TextContainer from "../../../components/Texts/TextContainer";
import NavBar from "../../../components/NavBar";
import PersonIcon from "@mui/icons-material/Person";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";

import colors from "../../../global/colors";
import generatePdf from "./pdf";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import BasicAccordion from "../../../components/Buttons/collapsibleButton";
import VoiceInput from "../../../components/Input/voiceInput";
import Medications from "./medications";
import { usePdf } from "../../../pdf";
import PrescriptionReceipt from "./pdf/prescription";
import { CustomTextField } from "../../../components/TextFields";
import { RowBox } from "../../../global/table";
import DateInput from "../../../components/Input/dateInput";
const CustomContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: ".3rem",
  overflow: "auto",
  maxHeight: "50vh",
});
const CustomBox = styled(Box)({
  width: "100%",
  display: "flex",
  gap: "1rem",
  flexDirection: "row",
  justifyContent: "flex-start",
});

function Submit({ formNavigator, dispatch, formState, illness }) {
  const [state, setState] = useState(true);
  const [metadata, setMetadata] = useState({
    referredToDoctor: {
      answer: undefined,
      comment: "",
    },
    medicationsPrescribed: {
      answer: undefined,
      comment: "",
    },
    medications: [],
    ...formState.data["metadata"],
  });
  const api = useFormApi();
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const saveMetadata = () => {
    dispatch(
      FormActiosn.updateFormData({
        formId: "metadata",
        formData: metadata,
      })
    );
  };
  function onSubmit() {
    api
      .createForm(formState.data.patient._id, {
        illness,
        content: { stack: formState.stack, data: formState.data },
      })
      .then(() => {
        dispatch(FormActiosn.clear());
        addNotification({
          severity: "success",
          text: "Submit form",
          duration: 1500,
          onClick: null,
        });
        navigate(-1);
      })
      .catch((err) => {
        console.err(err);
        addNotification({
          severity: "success",
          text: "problem to submit form",
          duration: 1500,
          onClick: null,
        });
      });
  }
  const HandleChangeQuestion = (e) => {
    setMetadata((prev) => ({
      ...prev,
      referredToDoctor: {
        ...prev.referredToDoctor,
        answer: e,
      },
    }));
  };
  const { generatePdf } = usePdf();

  const { patient } = formState.data;
  return state ? (
    <SubmitInfoState
      metadata={metadata}
      saveMetadata={saveMetadata}
      setMetadata={setMetadata}
      formNavigator={formNavigator}
      illness={illness}
      patient={patient}
      goNext={() => {
        setState(false);
      }}
    />
  ) : (
    <SubmitState
      goBack={() => setState(true)}
      onSubmit={onSubmit}
      illness={illness}
      patient={patient}
      metadata={metadata}
      saveMetadata={saveMetadata}
      setMetadata={setMetadata}
      generatePdf={() => {
        generatePdf({
          component: PrescriptionReceipt,
          props: {
            illness,
            formState: {
              ...formState,
              data: { ...formState.data, metadata },
            },
          },
        });
      }}
    />
  );
}

function SubmitInfoState({
  metadata,
  setMetadata,
  saveMetadata,
  formNavigator,
  goNext,
  illness,
  patient,
}) {
  return (
    <Layout
      firstButton={{
        text: "Previous",
        onClick: () => {
          saveMetadata();
          formNavigator(-1);
        },
      }}
      secondButton={{
        text: "Next",
        onClick: goNext,
      }}
    >
      <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <NavBar>
          <TextContainer
            style={{ width: "100%", textAlign: "center", justifyContent: "center" }}
          >
            Submit Form
          </TextContainer>
          {/* <ButtonContainer
            style={{ justifyContent: "flex-end", width: "100%" }}
          >
            <Btn
              variant="contained"
              onClick={() => {
                saveMetadata();
                navigate(`/forms/preview/${illness}?preview=true`);
              }}
            >
              Preview
            </Btn>
            <Btn
              variant="contained"
              style={{ background: colors.darkBlue, color: colors.white }}
              onClick={() => {
                generatePdf({
                  component: PrescriptionReceipt,
                  props: {
                    illness,
                    formState: {
                      ...formState,
                      data: { ...formState.data, metadata },
                    },
                  },
                });
              }}
            >
              prescription order
            </Btn>
          </ButtonContainer> */}
        </NavBar>
        <CustomContainer>
          <CustomBox>
            <TextContainer
              textTransform="capitalize"
              fontSize="1rem"
              color={colors.darkBlue}
              fontWeight="bold"
            >
              <CoronavirusIcon fontSize="22px" />
              &nbsp;Illness:
            </TextContainer>
            <TextContainer textTransform="capitalize" fontSize="1rem">
              {illness}
            </TextContainer>
          </CustomBox>
          <CustomBox>
            <TextContainer
              textTransform="capitalize"
              fontSize="1rem"
              color={colors.darkBlue}
              fontWeight="bold"
            >
              <PersonIcon fontSize="22px" />
              &nbsp;patient:
            </TextContainer>
            <TextContainer textTransform="capitalize" fontSize="1rem">
              {patient?.userName}
            </TextContainer>
          </CustomBox>
          <YesNoQuestion
            answers={["yes", "no"]}
            question="Has the patient been referred to the doctor?"
            value={metadata.referredToDoctor.answer}
            onChange={(value) => {
              setMetadata((prev) => ({
                ...prev,
                referredToDoctor: {
                  ...prev.referredToDoctor,
                  answer: value,
                },
              }));
            }}
          />
          <BasicAccordion title="comment" isOpen={false}>
            <VoiceInput
              value={metadata.referredToDoctor.comment}
              handleChange={(value) => {
                setMetadata((prev) => ({
                  ...prev,
                  referredToDoctor: {
                    ...prev.referredToDoctor,
                    comment: value,
                  },
                }));
              }}
            />
          </BasicAccordion>
          <YesNoQuestion
            answers={["yes", "no"]}
            question="Have any medications been prescribed?"
            value={metadata.medicationsPrescribed.answer}
            onChange={(value) => {
              setMetadata((prev) => ({
                ...prev,
                medicationsPrescribed: {
                  ...prev.medicationsPrescribed,
                  answer: value,
                },
              }));
            }}
          />
          <BasicAccordion title="comment" isOpen={false}>
            <VoiceInput
              value={metadata.medicationsPrescribed.comment}
              handleChange={(value) => {
                setMetadata((prev) => ({
                  ...prev,
                  medicationsPrescribed: {
                    ...prev.medicationsPrescribed,
                    comment: value,
                  },
                }));
              }}
            />
          </BasicAccordion>
          <Medications
            medications={metadata.medications}
            onChange={(data) => {
              setMetadata((prev) => ({
                ...prev,
                medications: data,
              }));
            }}
          />
        </CustomContainer>
      </Box>
    </Layout>
  );
}
function SubmitState({
  goBack,
  onSubmit,
  metadata,
  setMetadata,
  saveMetadata,
  illness,
  generatePdf,
  patient,
}) {
  const navigate = useNavigate();
  return (
    <Layout
      firstButton={{
        text: "Previous",
        onClick: goBack,
      }}
      secondButton={{
        text: "Submit",
        onClick: onSubmit,
      }}
    >
      <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <NavBar>
          <TextContainer
            style={{ width: "100%", textAlign: "center", justifyContent: "cneter" }}
          >
            Submit Form
          </TextContainer>
          <ButtonContainer
            style={{ justifyContent: "flex-end", width: "100%" }}
          >
            <Btn
              variant="contained"
              onClick={() => {
                saveMetadata();
                navigate(`/forms/preview/${illness}?preview=true`);
              }}
            >
              Preview
            </Btn>
            <Btn
              variant="contained"
              style={{ background: colors.darkBlue, color: colors.white,textWrap:"no-wrap",fontSize:".8rem" }}
              onClick={generatePdf}
            >
              prescription order
            </Btn>
          </ButtonContainer>
        </NavBar>
        <CustomContainer>
          <CustomBox>
            <TextContainer
              textTransform="capitalize"
              fontSize="1rem"
              color={colors.darkBlue}
              fontWeight="bold"
            >
              <CoronavirusIcon fontSize="22px" />
              &nbsp;Illness:
            </TextContainer>
            <TextContainer textTransform="capitalize" fontSize="1rem">
              {illness}
            </TextContainer>
          </CustomBox>
          <CustomBox>
            <TextContainer
              textTransform="capitalize"
              fontSize="1rem"
              color={colors.darkBlue}
              fontWeight="bold"
            >
              <PersonIcon fontSize="22px" />
              &nbsp;patient:
            </TextContainer>
            <TextContainer textTransform="capitalize" fontSize="1rem">
              {patient?.userName}
            </TextContainer>
          </CustomBox>
          <RowBox>
            <CustomTextField
              value={metadata?.ABULI}
              onChange={(e) =>
                setMetadata((prev) => ({
                  ...prev,
                  ABULI: e.target.value,
                }))
              }
              label="AB ULI"
              type="tel"
              variant="outlined"
              InputProps={{
                inputProps: {
                  maxLength: 9,
                },
              }}
            />
            <CustomTextField
              value={metadata?.DIN}
              onChange={(e) =>
                setMetadata((prev) => ({
                  ...prev,
                  DIN: e.target.value,
                }))
              }
              label="DIN"
              type="tel"
              variant="outlined"
              InputProps={{
                inputProps: {
                  maxLength: 8,
                },
              }}
            />
          </RowBox>
          <CustomTextField
            value={metadata?.NETCARE_ORDER}
            onChange={(e) =>
              setMetadata((prev) => ({
                ...prev,
                NETCARE_ORDER: e.target.value,
              }))
            }
            label="NetCare Order"
            type="tel"
            variant="outlined"
            InputProps={{
              inputProps: {
                maxLength: 8,
                pattern: "[a-zA-Z0-9]*",
              },
            }}
          />
          <YesNoQuestion
            question="status:"
            answers={["active", "inActive"]}
            value={metadata?.status}
            onChange={(value) =>
              setMetadata((prev) => ({
                ...prev,
                status: value,
              }))
            }
          />
          <TotalInformations setMetadata={setMetadata} metadata={metadata} />
        </CustomContainer>
      </Box>
    </Layout>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    formState: state.forms,
  };
}
export default connect(mapStateToProps)(Submit);
const YesNoQuestion = ({ question, value, onChange, answers }) => {
  return (
    <CustomBox>
      <TextContainer textTransform="capitalize" fontSize=".9rem">
        {question}
      </TextContainer>
      <TextContainer
        color={colors.darkBlue}
        style={{ textTransform: "capitalize", fontSize: "1rem" }}
      >
        <RadioGroup
          row
          aria-labelledby="demo-form-control-label-placement"
          name="position"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          <FormControlLabel
            value={answers[0]}
            control={<Radio size="small" color="primary" />}
            label={answers[0]}
            labelPlacement="left"
          />
          <FormControlLabel
            value={answers[1]}
            control={<Radio size="small" color="primary" />}
            label={answers[1]}
            labelPlacement="left"
          />
        </RadioGroup>
      </TextContainer>
    </CustomBox>
  );
};
const TotalInformations = ({ setMetadata, metadata }) => {
  return (
    <>
      <RowBox>
        <DateInput
          onChange={(e) =>
            setMetadata((prev) => ({
              ...prev,
              dontDispenseBefore: e,
            }))
          }
          value={metadata?.dontDispenseBefore}
          label="dont dispense before"
        />
        <DateInput
          onChange={(e) =>
            setMetadata((prev) => ({
              ...prev,
              expiryDate: e,
            }))
          }
          value={metadata?.expiryDate}
          label="last fill expiry date"
        />
      </RowBox>
      <RowBox>
        <CustomTextField
          onChange={(e) =>
            setMetadata((prev) => ({
              ...prev,
              dispenseQuantity: e.target.value,
            }))
          }
          value={metadata?.dispenseQuantity}
          variant="outlined"
          label="dispense quantity"
        />
        <CustomTextField
          onChange={(e) =>
            setMetadata((prev) => ({
              ...prev,
              dispenseDays: e.target.value,
            }))
          }
          value={metadata?.dispenseDays}
          variant="outlined"
          label="dispense days supply"
        />
      </RowBox>
      <RowBox>
        <CustomTextField
          onChange={(e) =>
            setMetadata((prev) => ({
              ...prev,
              totalQuantity: e.target.value,
            }))
          }
          value={metadata?.totalQuantity}
          variant="outlined"
          type="number"
          label="total prescribed quantity"
        />
        <CustomTextField
          onChange={(e) =>
            setMetadata((prev) => ({
              ...prev,
              totalSupply: e.target.value,
            }))
          }
          value={metadata?.totalSupply}
          type="number"
          variant="outlined"
          label="total days supply"
        />
      </RowBox>
      <RowBox>
        <CustomTextField
          onChange={(e) =>
            setMetadata((prev) => ({
              ...prev,
              route: e.target.value,
            }))
          }
          value={metadata?.route}
          variant="outlined"
          label="route"
        />
        <CustomTextField
          onChange={(e) =>
            setMetadata((prev) => ({
              ...prev,
              refills: e.target.value,
            }))
          }
          value={metadata?.refills}
          type="number"
          variant="outlined"
          label="refills"
        />
      </RowBox>
      <YesNoQuestion
        answers={["yes", "no"]}
        question="trial:"
        value={metadata?.trial}
        onChange={(value) =>
          setMetadata((prev) => ({
            ...prev,
            trial: value,
          }))
        }
      />
    </>
  );
};
