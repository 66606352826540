import React, { useEffect, useState, useContext, Fragment } from "react";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { CustomTextField } from "../../components/TextFields";
import { Grid, ListItemIcon, ListItemText, Button } from "@mui/material";
import TextContainer from "../../components/Texts/TextContainer";
import colors from "../../global/colors";
import { useStyles } from "../../components/Texts/styles";
import useIllnessApi from "../../api/illness.api";
import { modalContext } from "../../components/modal/modal";
import { Box } from "@material-ui/core";
import { AddAilmentsModal, EditAilmentModal } from "./modal";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import styled from "@emotion/styled";

const CustomText = styled(ListItemText)({
  "&& .MuiTypography-root": {
    fontSize: "1rem",
  },
});

export default function PatientIllnessList({ patientId }) {
  const { setShow, show, setContent, content } = useContext(modalContext);
  const [illnesses, setIllnesses] = useState([]);
  const classes = useStyles();
  const api = useIllnessApi();

  function handleOpenModal(type, illness) {
    if (type === "desc") {
      setContent({
        title: `Comment ${illness.name}`,
        body: (
          <EditAilmentModal
            ailmentId={illness._id}
            createdAt={illness.createdAt}
            desc={illness.desc}
            onSubmit={() => {
              getPatientIllnesses();
            }}
          />
        ),
      });
    }
    if (type === "addAilment") {
      setContent({
        title: "Add Comment",
        body: (
          <AddAilmentsModal
            patientId={patientId}
            onSubmit={getPatientIllnesses}
          />
        ),
      });
    }
    setShow(true);
  }
  const getPatientIllnesses = () => {
    api
      .getPatientIllnesses(patientId)
      .then((data) => {
        setIllnesses(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPatientIllnesses();
  }, []);

  const handleDeleteItem = (id) => {
    api
      .deleteIllness(id)
      .then(() => {
        getPatientIllnesses();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Fragment>
      <TextContainer margin="0 0 1rem 0">
        <ListItemIcon>
          <CoronavirusIcon />
        </ListItemIcon>
        <CustomText primary="Minor Ailments" />
        <Button
          variant="contained"
          onClick={() => {
            handleOpenModal("addAilment");
          }}
        >
          + Add
        </Button>
      </TextContainer>
      <div style={{ width: "100%" }}>
        <Grid
          container
          spacing={1}
          style={{
            width: "100%",
            padding: ".5rem",
            maxHeight: "7vh",
            overflow: "auto",
            borderRadius: ".5rem",
          }}
        >
          {illnesses.map((illness, index) => (
            <Grid
              item
              xs="auto"
              md="auto"
              sm="auto"
              lg="auto"
              key={index}
              style={{
                width: "fit-content",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                padding: "0 .5rem",
                borderRadius: ".5rem",
                background: colors.lightBlue,
                border: "1px solid #fafafa",
              }}
            >
              <TextContainer
                fontSize="1rem"
                cursor="pointer"
                onClick={() => handleOpenModal("desc", illness)}
              >
                {illness.name}
              </TextContainer>
              <TextContainer fontSize="1rem" cursor="pointer">
                {illness.description}
              </TextContainer>
              <IconButton
                className={classes.smallestIcon}
                onClick={() => handleDeleteItem(illness._id)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </div>
    </Fragment>
  );
}
