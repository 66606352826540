import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Tooltip,
    TableHead
} from "@mui/material";
import TextContainer from "../../../components/Texts/TextContainer";
import { styled } from "@mui/styles";
import colors from "../../../global/colors";
import { useEffect } from "react";
import truncateText from "../../../utils/truncatedText";
const CustomPaper = styled(Paper)({
    "& .MuiPaper-elevation1": { boxShadow: "none" },
});
const Header = styled(TableHead)({
    background: colors.lightBgGray1,
    zIndex: 10,
    padding: ".5rem 0",
});

export const TherapyTable = ({ ailment, headers, body }) => {
    useEffect(() => {
    }, [ailment]);
    return (
        <TableContainer component={CustomPaper}>
            <Table>
                <Header>
                    {headers.map((header, index) => (
                        <TableCell key={index} fontSize="1rem" padding="0 1rem">{header}</TableCell>
                    ))}
                </Header>
                <TableBody>
                    {body?.map((cells, index) => {
                        return (<TableRow
                            key={index}
                            sx={{
                                cursor: "pointer",
                                ":hover": { bgcolor: colors.lightBgGray1 },
                            }}
                        >
                            {cells?.map((item, indx) => (
                                <Tooltip title={item} key={indx} >
                                    <TableCell
                                        sx={{
                                            width: "auto",
                                            border: 0,
                                            fontSize: ".85rem",
                                            padding: ".5rem",
                                        }}
                                    >
                                        {truncateText(item, 100)}
                                    </TableCell>
                                </Tooltip>
                            ))}
                        </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}