import Allergies from "./Allergies";
import ColdSores from "./ColdSores";
import HurtBurn from "./HurtBurn";
import Hemorrohiods from "./Hemorrohiods";
import OralThrush from "./OralThrush";
import Menstrual from "./Menstrual";
import UrinaryTractInfection from "./UrinaryTractInfection";
import TickBite from "./TickBite";
import PinkEye from "./PinkEye";
import Other from "./Other";
export default {
  heartburn: HurtBurn,
  Allergy: Allergies,
  "cold sores": ColdSores,
  hemorrhoids: Hemorrohiods,
  Allergy: Allergies,
  "oral thrush": OralThrush,
  "pink eye": PinkEye,
  "tick bite": TickBite,
  period: Menstrual,
  "urinary tract infection": UrinaryTractInfection,
  other: Other,
};
