import { IconButton, ListItem, List } from "@material-ui/core";
import { useState, useContext } from "react";
import styled from "@emotion/styled";
import nameFilter from "./name";
import booleanFilter from "./boolean";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { modalContext } from "../modal/modal";
import colors from "../../global/colors";
import { useEffect } from "react";
import { useStyles } from "../Texts/styles";
import { Button, Popover, Typography } from "@mui/material";
import TextContainer from "../Texts/TextContainer";

const filterStaticMapper = {
  name: nameFilter,
  boolean: booleanFilter,
};
const FilterBuilder = ({ type, ...props }) => {
  const FilterModal = filterStaticMapper[type].modal;
  return <FilterModal {...props} />;
};
const FilterBtn = styled(IconButton)({
  fontSize: "15px",
  background: colors.white,
  padding: "0.3rem",
  height: "fit-content",
  borderRadius: "5px",
  marginBottom: ".5rem",
  alignSelf: "flex-start",
  width: "fit-content",
  boxShadow: colors.boxShadow,
  "&:hover": {
    background: colors.lightBgGray0,
    height: "fit-content",
    width: "fit-content",
    padding: "0.3rem",
  },
});
export default function Filter({ convertor, filters, dataLoader }) {
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [anchor, setAnchore] = useState(null);
  const { setShow, setContent } = useContext(modalContext);
  const addFilter = (filter, value) => {
    let tmp = [...activeFilters];
    const isSingle = filterStaticMapper[filter.type]?.single;
    if (isSingle) {
      const isExist = tmp.findIndex((item) => item.label == filter.label);
      console.log(isExist);
      if (isExist >= 0) return;
    }

    tmp.push({
      ...filter,
      value,
    });
    setActiveFilters(tmp);
    setShow(false);
    setShowFilter(false);
  };
  const handleClose = () => {
    setAnchore(null);
  };
  const deleteFilter = (index) => {
    let tmp = [...activeFilters];
    tmp.splice(index, 1);
    setActiveFilters(tmp);
  };
  const showFilterModal = (filter) => {
    const mapper = filterStaticMapper[filter.type];
    if (mapper.single && mapper.modal == null) {
      return addFilter(filter, true);
    }
    setAnchore(null);
    setContent({
      title: `filter by ${filter.label}`,
      body: (
        <FilterBuilder
          type={filter.type}
          onConfirm={(value) => addFilter(filter, value)}
          label={filter.label}
          value={null}
        />
      ),
    });
    setShow(true);
  };
  useEffect(() => {
    let query = {};
    activeFilters.forEach((filter) => {
      filterStaticMapper[filter.type].createFilterQuery(query, filter);
    });
    Object.keys(query).forEach((filter) => {
      query[filter] = query[filter][convertor]();
    });

    dataLoader(query);
  }, [activeFilters]);
  const open = Boolean(anchor);
  const id = open ? "right-start-popover" : undefined;
  return (
    <>
      <FilterBtn
        onClick={(e) => {
          setAnchore(e.target);
          setShowFilter(!showFilter);
        }}
      >
        <FilterAltIcon />
      </FilterBtn>
      <Popover
        id={id}
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {filters.map((filter, index) => {
          return (
            <TextContainer
              hoveredBg={colors.lightBlue}
              style={{ padding: ".5rem 1rem", cursor: "pointer" }}
              onClick={() => showFilterModal(filter)}
              key={index}
            >
              <Typography fontSize=".9rem" textAlign="left">
                {filter.label}
              </Typography>
            </TextContainer>
          );
        })}
      </Popover>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          width: "100%",
          flexWrap: "wrap",
          maxHeight: "15vh",
          overflow: "auto",
        }}
      >
        {activeFilters.map((filter, index) => {
          return (
            <ListItem
              key={index}
              style={{ width: "fit-content", padding: ".5rem .2rem" }}
            >
              {filterStaticMapper[filter.type].render(filter, () => {
                deleteFilter(index);
              })}
            </ListItem>
          );
        })}
      </div>
    </>
  );
}
