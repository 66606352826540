import { Table, TableRow } from "@material-ui/core"
import styled from "@emotion/styled"
import colors from "./colors"
import { Box } from "@mui/material"


export default styled(Table)({
    width: "100%",
    '& .MuiTableCell-head': {
        textAlign: "center",
        fontSize: "1rem",
    },
    '& .MuiTableHead-root': {
        background: colors.lightBgGray0,
        borderRadius: "10px",
    },
    '& .MuiTableCell-body': {
        textAlign: "center"
    }

})



export const Row = styled(TableRow)({
    width: "100%",
    '&:hover': {
        background: colors.lighterGray
    },
})

export const FormBox = styled.div`
  box-sizing: content-box;
  width: 60%;
  max-width: 25rem;
  height: fit-content;
  gap:1rem;
  max-height: 50%;
  min-height: 50%;
  padding: 1.5rem 2.5rem;
  max-height: 80vh;
  background: ${colors.white};
  border-radius: 5px 10px;
  box-shadow: ${colors.boxShadow};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;


export const RowBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    width: "100%"
})
