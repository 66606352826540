

import edit from "./edit";
import preview from "./preview";
import pdf from './pdf'

export default {
    edit,
    preview,
    pdf
}
