import Navbar from "../../components/NavBar";
import { useState, useContext, useEffect, Fragment } from "react";
import MainFrame from "../../components/MainFrame";
import { Btn, ButtonContainer } from "../../components/Buttons/styles";
import { Box } from "@mui/material";
import FormBuilder from "./components/formBuilder";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";


function FormDetails() {

    const location = useLocation();
    const formData = useSelector((state) => { return state.formData });
        


    return (
        <Fragment>
            <Navbar topic="Form name" >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <ButtonContainer  >
                        <Btn variant="contained">
                            Save Change
                        </Btn>
                    </ButtonContainer>
                    <ButtonContainer  >
                        <Btn variant="contained">
                            <img src={require("../../assets/images/export.png")} width="22px" style={{ cursor: "pointer" }} />
                        </Btn>
                    </ButtonContainer>
                </div>
            </Navbar>
            <Box style={{ maxHeight: "75vh", overflow: "auto", marginTop: "1rem" }}>
                <FormBuilder />
                {/* {Questions.map((question, index) => {
                    return (
                        <CollapsibleButton title={question.title} isOpen={true}>
                            {question.content}
                        </CollapsibleButton>
                    )
                })} */}
            </Box>

        </Fragment>
    )
}


export default MainFrame(FormDetails, true);

