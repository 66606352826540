
export default
    {

        white: 'white',
        lightBgGray0: '#fafafa',
        lighterBgGray0: 'rgb(242 247 248 / 73%)',
        lightBgGray1: 'rgb(230, 234, 242)',
        lightGray:"rgb(204 212 223 / 71%)",
        lighterGray:"rgb(204 212 223 / 30%)",
        darkBlue: '#2f5895',
        lightBlue: "rgb(199, 223, 255)",
        lighterBlue: 'rgba(0, 0, 0, 0.08)',
        mainBorder: 'rgb(230, 234, 242)',
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
    }