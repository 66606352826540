import {
  Box,
  Button,
  Container as MUIContainer,
  styled,
} from "@material-ui/core";
import colors from "../../../../global/colors";
import NavBar from "../../../../components/NavBar";
import React from "react";
import TextContainer from "../../../../components/Texts/TextContainer";
import { FormActiosn } from "../../../../global/redux/form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const Container = styled(MUIContainer)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  textAlign: "center",
  height: "75vh",
  maxHeight: "75vh",
  boxShadow: colors.boxShadow,
  borderRadius: ".5rem",
  gap: "auto",
  background: colors.white,
  padding: "2rem",
  width: "60%",
  maxWidth: "40rem",
  minWidth: "22rem",
});

const StyledOutlinedButton = styled(Button)({
  background: colors.white,
  color: colors.darkBlue,
  padding: "2% 3%",
  alignItems: "center",
  cursor: "pointer",
  minWidth: "7rem",
  borderRadius: ".5rem",
  boxShadow: colors.boxShadow,
  border: `1px solid ${colors.darkBlue}`,
  textTransform: "capitalize",
  transition: "all .2s",
  textAlign: "center",
  justifyContent: "center",
  fontSize: "1rem",
  "&:hover": {
    background: colors.darkBlue,
    color: colors.white,
    transform: "scale(.95) translateY(1px)",
  },
});
const StyledButton = styled(Button)({
  background: colors.darkBlue,
  color: colors.white,
  padding: "2% 3%",
  alignItems: "center",
  cursor: "pointer",
  minWidth: "7rem",
  borderRadius: ".5rem",
  boxShadow: colors.boxShadow,
  textTransform: "capitalize",
  transition: "all .2s",
  textAlign: "center",
  justifyContent: "center",
  fontSize: "1rem",
  "&:hover": {
    background: colors.darkBlue,
    transform: "scale(.95) translateY(1px)",
  },
});

export default function Layout({
  showCancle = true,
  children,
  firstButton = {
    text: "previous",
    onClick: null,
  },
  secondButton = {
    text: "next",
    onClick: null,
  },
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { illness } = useParams();

  return (
    <React.Fragment>
      <NavBar topic={illness}>
        {showCancle && (
          <TextContainer
            textDecoration="underline"
            fontSize="1rem"
            cursor="pointer"
            transition="all .3s"
            hoveredTransform="scale(1.1)"
            textTransform="capitalize"
            color={colors.darkBlue}
            onClick={() => {
              dispatch(FormActiosn.clear());
              navigate(0);
            }}
          >
            cancel
          </TextContainer>
        )}
      </NavBar>
      <Container>
        {children}
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: "2rem",
          }}
        >
          <StyledOutlinedButton
            variant="outlined"
            onClick={firstButton.onClick}
          >
            {firstButton.text}
          </StyledOutlinedButton>

          <StyledButton variant="contained" onClick={secondButton.onClick}>
            {secondButton.text}
          </StyledButton>
        </Box>
      </Container>
    </React.Fragment>
  );
}
