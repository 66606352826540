import React from "react";
import { LineChart as MuiLineChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const LineChart = ({ data, XAxisDataKey,legend, children }) => {
    const screenHeight = window.innerHeight;

    return (
        <ResponsiveContainer width="100%" height={screenHeight * .3}>
            <MuiLineChart data={data} >
                <CartesianGrid strokeDasharray="4 4"  />
                <XAxis dataKey={XAxisDataKey} fontSize="1rem"/>
                <YAxis fontSize="1rem"/>
                <Tooltip/>
                {children}
            </MuiLineChart>
        </ResponsiveContainer>
    );
};

export default LineChart;