import React, { useState, useEffect } from 'react';
import TextContainer from '../Texts/TextContainer';

export default function ({ initialSeconds, ResendCode }) {
    const [totalSeconds, setTotalSeconds] = useState(initialSeconds);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (totalSeconds > 0) {
                setTotalSeconds(prevSeconds => prevSeconds - 1);
            }
        }, 1000);

        if (totalSeconds === 0) {
            ResendCode(true);
        }

        return () => clearInterval(intervalId);
    }, [totalSeconds]);

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return (
        <TextContainer>Resend code in {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</TextContainer>
    );
}
