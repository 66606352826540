
import Avatar from '@mui/material/Avatar';
import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import colors from '../../global/colors';

export default function CustomAvatar({
    name,
    isOnline = false,
    size = {
        width: "2rem",
        height: "2rem",
        minWidth: "2rem",
        maxWidth: "3rem",
        minHeight: "2rem",
        maxHeight: "3rem",
    } }) {
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        if (name.includes(" "))
            return {
                sx: {
                    bgcolor: stringToColor(name),
                    textTransform: "uppercase",
                    fontSize: ".9rem",
                    ...size
                },
                children: `${name.split(' ')[0][0]}${name.split(' ')[1][0] ? name.split(' ')[1][0] : ''}`,
            }
        else
            return {
                sx: {
                    bgcolor: stringToColor(name),
                    textTransform: "uppercase",
                    fontSize: ".9rem",
                    ...size
                },
                children: `${name.split(' ')[0][0]}${name.split(' ')[0][1]}`,
            }
    }
    return (
        <div style={{ width: "fit-content", height: "fit-content", position: "relative" }}>
            <Avatar {...stringAvatar(name)} style={{boxShadow:colors.boxShadow}} />
            {isOnline &&
                <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        borderRadius: "50%",
                        bgcolor: "customColors.secondaryLightGreen",
                        boxShadow: 1,
                        width: "8px",
                        height: "8px"
                    }}></Box>
            }
        </div>

    )
}

const avatarSize = "4rem";
const useStyles = makeStyles((theme) => ({
    avatarHover: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: avatarSize,
        height: avatarSize,
        borderRadius: '50%',
        backgroundColor: 'lightgreen',
        opacity: 0,
        transition: 'opacity 0.3s ease',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            opacity: 1,
        },
    },
}));

export const HoverableAvatar = ({
    name=" ",
    size = {
        width: "2rem",
        height: "2rem",
        minWidth: "2rem",
        maxWidth: "3rem",
        minHeight: "2rem",
        maxHeight: "3rem",
    } }) => {
    function stringToColor(string) {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                textTransform: "uppercase",
                fontSize: ".9rem",
                ...size
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0] ? name.split(' ')[1][0] : ''}`,
        };
    }
    const classes = useStyles();
    return (
        <React.Fragment>
            <Avatar {...stringAvatar(name)} alt="User Avatar" src="/path_to_avatar.jpg" style={{ width: avatarSize, height: avatarSize }} />
            <div className={classes.avatarHover}>
                camera
            </div>
        </React.Fragment>
    );
};


