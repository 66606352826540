import { Box } from "@mui/material";
import TextContainer from "../../components/Texts/TextContainer";

export default function ({ items, listItemStyle = false }) {


    return (
        <Box
            width="100%"
            padding="0 1rem"
            display="flex"
            flexDirection="column">
            {items.map((item, indx) => {
                return (
                    <TextContainer
                        width="100%"
                        justifyContent="left"
                        fontSize=".75rem"
                        lignHeight="1.5rem"
                        margin="0 0 .5rem 0"
                        display={listItemStyle && "list-item"}
                        key={indx}>
                        {item}
                    </TextContainer>
                )
            })}
        </Box>
    )
}