import { useNavigate } from "react-router-dom";
import { FormActiosn } from "../../../../global/redux/form";
import questions from "../questions";
import { connect } from "react-redux";
import { memo } from "react";
import { isEqual } from "date-fns";
import getFormComponent from "../resolver";

function Builder({
    dispatch,
    currentForm: {
        formId = undefined,
        resolver = undefined,
    },
    finish,
    illness
}) {
    const forms = questions[illness];
    const navigate = useNavigate();
    const handleGoBack = () => {
        if (!formId) {
            dispatch(FormActiosn.clear())
            return navigate(-1);
        }
        dispatch(FormActiosn.pop())
    }
    const formNavigator = (formId) => {
        if (formId == -1) {
            if (finish)
                return dispatch(FormActiosn.unFinish())
            return handleGoBack();
        }

        if (formId == 'finish') {
            return dispatch(FormActiosn.finish())
        }

        const nState = forms[formId]
        if (!nState)
            return console.error('invalid form id');

        const resolver = nState.type;
        dispatch(FormActiosn.push({
            formId,
            resolver
        }))
    }

    const FormComponent = formId ? getFormComponent(resolver, false) : getFormComponent('p_info', false);

    let props = {};
    if (formId)
        props = forms[formId];

    return (
        <FormComponent
            formNavigator={formNavigator}
            illness={illness}
            {...props}
        />
    )
}
function mapStateToProps(state, ownProps) {
    const { stack, finish } = state.forms;
    console.log(stack)
    let currentForm = stack.length ? stack[stack.length - 1] : {};
    currentForm = !finish ? currentForm : {
        formId: 'finish',
        resolver: 'submit'
    }
    return {
        finish,
        currentForm,
        ...ownProps
    }
}
export default connect(mapStateToProps)(
    memo(Builder, function (prev, next) {
        return (
            (prev.finish == next.finish) &&
            (prev.illness == next.illness) &&
            (isEqual(prev.currentForm, next.currentForm)))
    })
)