import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Paper,
    Box,
    Tooltip,
    IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import truncateText from "../../../utils/truncatedText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextContainer from "../../../components/Texts/TextContainer";
import { styled } from "@mui/styles";
import colors from "../../../global/colors";
import { useEffect, useState } from "react";
import Data from "../../../constants/ailments.db.json"

const Header = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    background: colors.lightBgGray1,
    zIndex: 10,
    padding: ".5rem 0",
});

export const DrugTable = ({ ailment }) => {
    const [hover, setHover] = useState([-1, -1]);
    const {
        headers = [],
        drugClasses = []
    } = Data[ailment].drugTables;
    async function handleCopy(text) {
        await navigator.clipboard.writeText(text)
    }
    useEffect(() => {
    }, [ailment]);
    return (
        <TableContainer component={Paper}>
            <Table aria-label="Accordion Table">
                <Header>
                    {headers.map((header) => (
                        <TextContainer fontSize="1rem" padding="0 1rem">{header}</TextContainer>
                    ))}
                </Header>
                <TableBody>
                    {Object.keys(drugClasses).map((drugClass, index) => (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel-${index}-content`}
                                id={`panel-${index}-header`}
                            >
                                <Typography
                                    style={{
                                        color: colors.darkBlue,
                                        textTransform: "capitalize",
                                    }}
                                >
                                    {drugClass}
                                </Typography>
                            </AccordionSummary>
                            {drugClasses[drugClass].map((columns, row) => {
                                return (
                                    <AccordionDetails
                                        sx={{ border: `1px solid ${colors.lightBgGray0}` }}
                                    >
                                        <TableRow
                                            sx={{
                                                cursor: "pointer",
                                                ":hover": { bgcolor: colors.lightBgGray1 },
                                            }}
                                        >
                                            {Object.keys(columns)?.map((key, indx) => (
                                                <Tooltip title={columns[key]}>
                                                    <TableCell
                                                        sx={{
                                                            width: "auto",
                                                            border: 0,
                                                            position: "relative",
                                                            padding: ".1rem",
                                                            fontSize: ".9rem"
                                                        }}
                                                        onMouseEnter={() => setHover([row, indx])}
                                                        onMouseLeave={() => setHover([row, indx])}
                                                    >
                                                        {((hover[0] === row) && (hover[1] === indx)) && (
                                                            <IconButton
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleCopy(columns[key]);
                                                                }}
                                                                sx={{
                                                                    position: "absolute",
                                                                    top: 0,
                                                                    left: 0,
                                                                    bgcolor: colors.white,
                                                                    ":hover": { bgcolor: colors.lightBlue }
                                                                }}
                                                            >
                                                                <ContentCopyIcon style={{ fontSize: "1rem" }} />
                                                            </IconButton>
                                                        )}
                                                        {truncateText(columns[key], 100)}
                                                    </TableCell>
                                                </Tooltip>
                                            ))}
                                        </TableRow>
                                    </AccordionDetails>
                                );
                            })}
                        </Accordion>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}