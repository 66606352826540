import useApi from "./httpService";

export default function useAuthApi() {
  const api = useApi();

  /**
   *
   * @param {String} email
   */
  const requestCode = async (email) => {
    await api({
      method: "POST",
      url: "/auth/code",
      data: { email },
    });
  };

  /**
   *
   * @param {String} email
   * @param {Code} code
   */
  const validateCode = async (email, code) => {
    await api({
      method: "POST",
      url: "auth/validate",
      data: { email, code },
    });
  };

  const login = async (email, password) => {
    const result = await api({
      method: "POST",
      url: "/auth/login",
      data: { email, password },
    });
    const id = result.data.id;
    localStorage.setItem("user_id", id);
    localStorage.setItem("userInfo", JSON.stringify({ email }));
  };

  const signup = async (data) => {
    const result = await api({
      method: "POST",
      url: "/auth/signup",
      data,
    });
    const id = result.data.id;

    localStorage.setItem("user_id", id);
    localStorage.setItem("userInfo", JSON.stringify({ email: data.email }));
  };

  const resetPassword = async (data) => {
    await api({
      method: "POST",
      url: "/auth/resetPassword",
      data,
    });
  };

  return { requestCode, validateCode, login, signup, resetPassword };
}
