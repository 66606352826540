


export default function MultiSelectQuestion({
    question,
    answers,
    formId,
    formData
}) {
    return (
        <div
            style={{
                padding: ".5rem 1rem",
                width: "100%",
                margin:".5rem 0",
                borderBottom: "1px dashed gray",
            }}>
            <p style={{ fontWeight: "bold", fontSize: "14px" }}>{question}</p>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                <ul>
                    {
                        formData?.selectedItems.map((item, indx) => {
                            return (
                                <li style={{ fontSize: "14px" }}>{answers[indx].text}</li>
                            )
                        })
                    }
                </ul>
                {(formData?.comment && formData.comment !== '') &&
                    <p style={{ fontSize: "14px" }}>
                        <b >comment:</b>&nbsp;
                        {formData?.comment}
                    </p>
                }
            </div>
        </div>
    )

}