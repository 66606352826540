import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from '@emotion/styled';

const Provider = styled(LocalizationProvider)({
    width: "100%",
    '& .MuiFormControl-root': {
        width: "100%"
    },
})
const DateInputt = styled(DatePicker)({
    "&& .MuiOutlinedInput-root": {
        borderRadius: "0.5rem",
        marginBottom: ".7rem",
    },
    "&.MuiInputLabel-root": {
        fontSize: "1rem"
    },
    '&.MuiInputBase-input': {
        fontSize: "1rem",

    },
    "& .css-1eaxhlq-MuiFormLabel-root-MuiInputLabel-root": {
        fontSize: "1rem",
        color: "rgba(0, 0, 0, 0.54)"
    },
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
        fontSize: "1rem"
    }

})
export default function DateInput({ onChange, value, label, helperText, error }) {
    return (
        <Provider dateAdapter={AdapterDayjs}>
            <DateInputt
                sx={{ width: "100%" }}
                label={label}
                value={value}
                onChange={onChange}
                slotProps={{
                    textField: {
                        helperText,
                        error
                    },
                }}
            />
        </Provider>
    );
}
