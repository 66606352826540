import React from "react";
import "./styles.css";
import dayjs from "dayjs";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";

export default function PrescriptionReceipt({ illness, formState }) {
  const { data } = formState;
  const { patient, owner } = data;
  const {
    ABULI = "",
    NETCARE_ORDER = "",
    createdAt = "",
    medications = [],
    DIN = "",
  } = data?.metadata ?? {};
  return (
    <div
      className="prescription-pdf"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="container">
        <h2 className="title">prescription order</h2>
        <PharmacistBox pharmacistInfo={owner} />
        <PatientBox patientInfo={patient} AB_ULI={ABULI} />
        <hr style={{ width: "100%" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <p className="capitalize">netcare order #:</p>&nbsp;
              <p>{NETCARE_ORDER}</p>
            </p>
            <p
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <p className="capitalize">status:</p>&nbsp;
              <p>{true ? "active" : "not active"}</p>
            </p>
          </div>
          <div className="row">
            <p className="capitalize">date prescribed:</p>&nbsp;
            <p style={{ width: "100%" }}>
              {dayjs(createdAt).format("DD-MMM-YYYY")}
            </p>
          </div>
        </div>
        <Medications medications={medications} DIN={DIN} />
        &nbsp;
        <Informations metadata={data.metadata ?? {}} />
        <div
          style={{
            display: "flex",
            fontSize: "1rem",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "flex-start",
            width: "70%",
          }}
        >
          <p>Signature:</p>&nbsp;
          <hr style={{ width: "100%" }} />
        </div>
        <p
          style={{
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: ".8rem",
            width: "100%",
            textAlign: "center",
          }}
        >
          ***please give this prescription to your pharmacist***
        </p>
        <hr style={{ width: "100%" }} />
        <p
          style={{
            textTransform: "capitalize",
            fontSize: ".8rem",
            width: "100%",
          }}
        >
          printed <b>{dayjs(createdAt).format("DD-MMM-YYYY")}</b> at{" "}
          <b>{owner?.businessName}</b> pharmacy in {owner?.address}
        </p>
      </div>
    </div>
  );
}

const PharmacistBox = ({ pharmacistInfo }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <div className="pharmacist-row">
        <p>{`${pharmacistInfo?.role} ${pharmacistInfo?.firstName} ${pharmacistInfo?.lastName}`}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "start",
          width: "50%",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <p style={{ width: "100%", textAlign: "left", margin: 0, padding: 0 }}>
          {pharmacistInfo?.postalCode} {pharmacistInfo?.address}
        </p>
        <p style={{ width: "100%", textAlign: "left", margin: 0, padding: 0 }}>
          {pharmacistInfo?.city}
        </p>
        <p style={{ width: "100%", textAlign: "left", margin: 0, padding: 0 }}>
          {pharmacistInfo?.province}
        </p>
      </div>
      <div className="pharmacist-row">
        <p className="label">phone:</p>&nbsp;
        <p>{formatPhoneNumber(pharmacistInfo?.phone)}</p>
      </div>
      <div className="pharmacist-row">
        <p className="label">FAX:</p>&nbsp;<p>{pharmacistInfo?.fax}</p>
      </div>
      {/* <div className="pharmacist-row">
        <p className="label">fax:</p>&nbsp;<p>{pharmacistInfo.fax}</p>
      </div> */}
    </div>
  );
};
const PatientBox = ({ patientInfo, AB_ULI }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "1rem",
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: ".5rem",
            width: "fit-content",
            justifyContent: "flex-start",
          }}
        >
          <p style={{ textWrap: "no-wrap" }}>{patientInfo?.userName}</p>
        </div>
        <div style={{ minWidth: "fit-content" }}>
          <p className="label">
            {dayjs(patientInfo?.birthDay).format("DD-MMM-YYYY")}-
            {patientInfo?.gender}
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
          justifyContent: "flex-start",
        }}
      >
        <p>
          {patientInfo?.postalCode} {patientInfo?.address}
        </p>
        <p>{patientInfo?.city}</p>
        <p>{patientInfo?.province}</p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <p className="label">phone:</p>&nbsp;
          <p>{formatPhoneNumber(patientInfo?.phone)}</p>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            textAlign: "right",
          }}
        >
          <p className="">AB ULI:</p>&nbsp;<p>{AB_ULI}</p>
        </div>
      </div>
    </div>
  );
};
const Medications = ({ medications, DIN }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {medications.map((medication, index) => {
        return (
          <div key={index}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <h5>{medication?.inq1}</h5>
              {index === 0 ? (
                <p
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>DIN:</p>&nbsp;<p>{DIN}</p>
                </p>
              ) : null}
            </div>
            <p>{medication?.inq2}</p>
            <p>{medication?.inq3}</p>
          </div>
        );
      })}
    </div>
  );
};
const Informations = ({ metadata }) => {
  const {
    dontDispenseBefore = "",
    expiryDate = "",
    dispenseQuantity = "",
    dispenseDays = "",
    totalQuantity = "",
    totalSupply = "",
    route = "",
    refills = "",
    trial = "",
  } = metadata;
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <p className="capitalize">do not despense before:</p>&nbsp;
          <p>{dayjs(dontDispenseBefore).format("DD-MMM-YYYY")}</p>
        </p>
        <p
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <p className="capitalize">last fill expiry date:</p>&nbsp;
          <p>{dayjs(expiryDate).format("DD-MMM-YYYY")}</p>
        </p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <p className="capitalize">route:</p>&nbsp;<p>{route}</p>
        </p>
        <p></p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <p className="capitalize">dispense quantity:</p>&nbsp;
          <p>{dispenseQuantity}</p>
        </p>
        <p
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <p className="capitalize">dispense days supply :</p>&nbsp;
          <p>{dispenseDays}</p>
        </p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <p className="capitalize">refills:</p>&nbsp;<p>{refills}</p>
        </p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <p className="capitalize">total prescribed quantity:</p>&nbsp;
          <p>{totalQuantity}</p>
        </p>
        <p
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <p className="capitalize">total days supply:</p>&nbsp;
          <p>{totalSupply}</p>
        </p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <p className="capitalize">trial:</p>&nbsp;<p>{trial}</p>
        </p>
      </div>
    </div>
  );
};
