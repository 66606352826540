import { makeStyles } from '@material-ui/core/styles';
export const TOPIC = { fontSize: '1.2rem',textTransform:"capitalize"}
export const useStyles = makeStyles((theme) => ({
  smallestIcon: {
    '& svg': {
      fontSize: 15
    }
  },
  smallerIcon: {
    '& svg': {
      fontSize: 20
    }
  },
  smallIcon: {
    '& svg': {
      fontSize: 30
    }
  },
  largeIcon: {
    '& svg': {
      fontSize: 40
    }
  },
  largerIcon: {
    '& svg': {
      fontSize: 50
    }
  }
}));