import { useState, useContext, useEffect, useRef } from "react"
import { NegativeButton, PositiveButton, ButtonContainer } from "../../components/Buttons/styles"
import { modalContext } from '../../components/modal/modal'
import { CustomNumberField, CustomTextField, handleNumber } from "../../components/TextFields"
import styled from "@emotion/styled"
import { AxiosError, HttpStatusCode } from "axios"
import { Button, MenuItem } from "@mui/material"
const patients = ["1", "2", "3"]
export const AddModal = ({ organ, dataLoader }) => {
    const { setShow } = useContext(modalContext)
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const ref = useRef();
    useEffect(() => {
        //api
    },
        []
    )
    const [err, setErr] = useState({ title: "", description: "" });

    const addOrgan = () => {
        let errMsg = { title: '', description: '' };
        if (title === '') {
            errMsg.title = ('نام ارگان نمی تواند خالی باشد');
            setErr({ ...errMsg })
            return;
        }
        if (description === '') {
            errMsg.description = ('نام دسته نمی تواند خالی باشد');
            setErr({ ...errMsg })
            return;
        }
        //call Api
    }
    const updateOrgan = async () => {
        let errMsg = { title: '', description: '' };
        if (title === '') {
            errMsg.title = ('نام ارگان نمی تواند خالی باشد');
            setErr({ ...errMsg })
            return;
        }
        if (description === '') {
            errMsg.description = ('نام دسته نمی تواند خالی باشد');
            setErr({ ...errMsg })
            return;
        }

        //api

    }

    const onSubmitHandler = organ ? updateOrgan : addOrgan;
    const width = ref.current ? ref.current.getBoundingClientRect().width : null;
    return (
        <>
            <CustomTextField
                label='title'
                variant="outlined"
                value={title}
                helperText={err.title}
                error={err.title != ''}
                onChange={e => setTitle(e.target.value)}
            />

            <CustomTextField
                label='description'
                variant="outlined"
                value={description}
                helperText={err.description}
                error={err.description != ''}
                onChange={e => setDescription(e.target.value)}
            />
            <ButtonContainer>
                <Button variant="outlined" onClick={() => setShow(false)} style={NegativeButton} >cancel</Button>&nbsp;
                <Button variant="contained" style={PositiveButton} onClick={onSubmitHandler}  >ok</Button>
            </ButtonContainer>
        </>
    )
}



const Container = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
`
export const DeleteModal = ({ id, dataLoader }) => {
    const { setShow } = useContext(modalContext);
    const deleteOrgan = () => {
        //api
    }

    return (
        <Container>
            <p style={{ textTransform: "capitalize" }}>Are You Sure You want to delete this reminder?</p>
            <ButtonContainer>
                <Button variant="outlined" onClick={() => setShow(false)} style={NegativeButton}>cancel</Button>&nbsp;
                <Button variant="contained" onClick={deleteOrgan} style={PositiveButton} >ok</Button>
            </ButtonContainer>
        </Container>
    )
}

