import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import colors from '../../global/colors';
import { useState } from 'react';
import TextContainer from '../Texts/TextContainer';
import styled from '@emotion/styled';
const CustomAccordin = styled(Accordion)({
    "&.css-15v22id-MuiAccordionDetails-root": {
        padding: 0
    },
    "&&. css-1h09kut-MuiPaper-root-MuiAccordion-root.Mui-expanded":{
        margin:0
    },
    boxShadow:"unset"
})
export default function ({
    title,
    titleComponent,
    children,
    isOpen,
}) {
    const [open, setOpen] = useState(isOpen)
    return (
        <CustomAccordin expanded={open}
            onChange={(e) => {
                console.log("here in accordin")
                setOpen(!open)
            }
            }
        >
            <AccordionSummary
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}
                expandIcon={<ExpandMoreIcon />}
            >
                {
                    titleComponent ?
                        titleComponent() :
                        <TextContainer>
                            <Typography textTransform="capitalize" fontSize="1rem">
                                {title}
                            </Typography>
                        </TextContainer>
                }

            </AccordionSummary>
            <AccordionDetails>
                <Typography textTransform="capitalize" fontSize=".9rem" color={colors.darkBlue}>
                    {children}
                </Typography>
            </AccordionDetails>
        </CustomAccordin>
    )
}