import React, { useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { styled } from "@mui/styles";
import TextContainer from "../../../components/Texts/TextContainer";
import colors from "../../../global/colors";
import MedicationLiquidIcon from "@mui/icons-material/MedicationLiquid";
import { IconButton, List } from "@mui/material";
import { ButtonContainer } from "../../../components/Buttons/styles";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { modalContext } from "../../../components/modal/modal";
import { Inqs } from "../../../components/inq";
import ClearIcon from "@mui/icons-material/Clear";
const Card = styled(Box)({
  boxShadow: colors.boxShadow,
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  bgcolor: colors.white,
  margin: "0 1rem",
  borderRadius: ".5rem",
});
const Medications = ({ medications, onChange, preview }) => {
  const { setShow, setContent } = useContext(modalContext);
  const handleOpenModal = (type) => {
    if (preview) return;
    if (type === "add-medication") {
      setContent({
        title: "add new medication",
        body: (
          <Inqs
            onSubmit={(data) => {
              onChange([...medications, data]);
            }}
          />
        ),
      });
    }
    setShow(true);
  };
  function onDelete(indx) {
    if (preview) return;
    const tmp = [...medications];
    tmp.splice(indx, 1);
    onChange(tmp);
  }
  return (
    <React.Fragment>
      <ButtonContainer>
        <TextContainer
          fontSize=".9rem"
          width="100%"
          justifyContent="flex-start"
          textTransform="capitalize"
          fontWeight="bold"
          color={colors.darkBlue}
        >
          <MedicationLiquidIcon
            sx={{
              margin: "0 .5rem",
              color: colors.darkBlue,
              cursor: "pointer",
            }}
          />
          medications:
        </TextContainer>
        <IconButton onClick={() => handleOpenModal("add-medication")}>
          <AddBoxIcon style={{ color: "#555" }} />
        </IconButton>
      </ButtonContainer>
      <List
        sx={{
          pb: ".2rem",
          gap: ".5rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {medications?.map((medication, index) => {
          return (
            <Card key={index}>
              <IconButton
                onClick={onDelete}
                size="small"
                sx={{
                  maxWidth: "fit-content",
                  alignSelf: "flex-end",
                  bgcolor: colors.darkBlue,
                  ":hover": {
                    bgcolor: "gray",
                  },
                }}
              >
                <ClearIcon style={{ fontSize: ".8rem", color: colors.white }} />
              </IconButton>
              <TextContainer
                width="100%"
                justifyContent="flex-start"
                fontSize=".8rem"
                textTransform="capitalize"
                color="#333"
              >
                {medication?.inq1}
              </TextContainer>
              <TextContainer
                width="100%"
                justifyContent="flex-start"
                fontSize=".8rem"
                color={colors.darkBlue}
              >
                {medication?.inq2}
              </TextContainer>
              <TextContainer
                width="100%"
                justifyContent="flex-start"
                fontSize=".8rem"
                color="gray"
              >
                {medication?.inq3}
              </TextContainer>
            </Card>
          );
        })}
      </List>
    </React.Fragment>
  );
};

export default Medications;
