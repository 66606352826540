export default function OtherQuestion({ question, formId, formData }) {
  return (
    <div
      style={{
        margin: ".5rem 0",
        padding: ".5rem 1rem",
        width: "100%",
        borderBottom: "1px dashed gray",
      }}
    >
      <p style={{ fontWeight: "bold", fontSize: "14px" }}>{question}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
      >
        <p style={{ fontSize: "14px" }}>
          <b >comment:</b>&nbsp;
          {formData?.comment}
        </p>
      </div>
    </div>
  );
}
