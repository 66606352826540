import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from "@emotion/styled"
import dayjs from 'dayjs';
import colors from '../../global/colors';

const Provider = styled(LocalizationProvider)({
    "& .css-a643pv-MuiStack-root": {
        width: '100%',
    },
})

const FullWidthDateTimePicker = styled(DatePicker)({
    "&&": {
        marginBottom: "1rem",
        width: "100%"
    },
  
});

export default function YearMonthInput({ onChange, label }) {
    const [value, setValue] = React.useState(dayjs(new Date()))
    return (
        <Provider dateAdapter={AdapterDayjs}>
            <FullWidthDateTimePicker
                slotProps={{
                    layout: {
                        sx: {
                            ".css-dplwbx-MuiPickersCalendarHeader-label":{color:colors.darkBlue},
                            ".css-vgjnn0-MuiPickersMonth-monthButton":{fontSize:"1rem"},
                            ".css-3qocl4-MuiPickersYear-yearButton":{fontSize:"1rem"}
                        }
                    }
                }}
                sx={{
                    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": { fontSize: "1rem" },
                    "& .css-vgjnn0-MuiPickersMonth-monthButton": { fontSize: "1rem" }
                }}
                views={['month', 'year']}
                value={value}
                label={label}
                onChange={(v, _) => {
                    setValue(v);
                    onChange(v.toDate())
                }}
            />
        </Provider>
    );
}