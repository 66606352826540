export default {
    index: {
        handleSelect: (index) => {
            if (index == 0) {
                return 'infested_loc'
            }
            return 'pregnant'
        },
        type: 'simple',
        question: 'What is your gender?',
        formId: 'index',
        answers: [
            { text: 'Male' },
            { text: 'Female' },
            { text: 'Other' }
        ],
        optionalKey: 2,
        label: "Please self-identify:",
        placeholder: "(optional)",
        inputType: "text",
        require: true
    },

    pregnant: {
        handleSelect: (index) => { return 'breastfeeding' },
        type: 'simple',
        question: 'Are you (or could you be) pregnant?',
        formId: 'pregnant',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    breastfeeding: {
        handleSelect: (index) => { return 'infested_loc' },
        type: 'simple',
        question: 'Are you breastfeeding?',
        formId: 'breastfeeding',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    infested_loc: {
        handleSelect: (index) => {
            if (index == 1)
                return 'bitten_by_tick'
            return 'time_in_loc'
        },
        type: 'simple',
        question: 'Were you recently in an outdoor location considered to be tick infested?',
        formId: 'infested_loc',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' },
        ],
        optionalKey: 0,
        label: 'Describe where you were:',
        inputType: 'text'
    },

    time_in_loc: {
        handleSelect: (index) => { return 'bitten_by_tick' },
        type: 'simple',
        question: 'When were you in this location?',
        formId: 'time_in_loc',
        answers: [],
        optionalKey: -1,
        inputType: 'text',
        placeholder: "e.g. two week ago"

    },

    bitten_by_tick: {
        handleSelect: (index) => {
            if (index == 0)
                return 'bitten_time'
            return 'tick_swollen'
        },
        type: 'simple',
        question: 'When were you in this location?',
        formId: 'bitten_by_tick',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' },
        ],
    },


    bitten_time: {
        handleSelect: (index) => { return 'tick_attach' },
        type: 'simple',
        question: 'When were you bitten by a tick?',
        formId: 'bitten_time',
        answers: [],
        optionalKey: -1,
        placeholder: 'e.g. two weeks ago',
        inputType: 'text',
    },

    tick_attach: {
        handleSelect: (index) => { return 'tick_remove' },
        type: 'simple',
        question: 'Was the tick attached to your skin for more than 24 hours?',
        formId: 'tick_attach',
        answers: [
            { text: "Yes" },
            { text: "No" },
            { text: "Not Sure" },
        ],

    },

    tick_remove: {
        handleSelect: (index) => { return 'tick_swollen' },
        type: 'simple',
        question: 'Was the tick removed from your skin?',
        formId: 'tick_remove',
        answers: [
            { text: "Yes" },
            { text: "No" },
            { text: "Not Sure" },
        ],

    },


    tick_swollen: {
        handleSelect: (index) => { return 'tick_black_leg' },
        type: 'simple',
        question: 'Was the tick swollen (and not flat)?',
        formId: 'tick_swollen',
        answers: [
            { text: "Yes" },
            { text: "No" },
            { text: "Not Sure" },
        ],

    },

    tick_black_leg: {
        handleSelect: (index) => { return 'where_gottent_by_tick' },
        type: 'simple',
        question: 'Did the tick have black legs??',
        formId: 'tick_black_leg',
        answers: [
            { text: "Yes" },
            { text: "No" },
            { text: "Not Sure" },
        ],

    },

    where_gottent_by_tick: {
        handleSelect: (index) => { return 'symptoms' },
        type: 'simple',
        question: 'Do you have a rash where you may have gotten bitten by the tick?',
        formId: 'where_gottent_by_tick',
        answers: [
            { text: "Yes" },
            { text: "No" },
            { text: "Not Sure" },
        ],

    },


    symptoms: {
        handleSelect: (index) => { return 'treatments' },
        type: 'multi',
        question: 'What symptoms are you experiencing in your eyes?',
        formId: 'symptoms',
        cancleAllIndex: 8,
        answers: [
            { text: 'Fever (temperature higher than 38°C), chills, or lack of energy' },
            { text: 'Decreased appetite' },
            { text: 'Headache or stiff neck' },
            { text: 'Swollen lymph nodes' },
            { text: 'Pain or numbness in your face or body' },
            { text: 'Chest pain or slower heart rate' },
            { text: 'Dizziness or shortness of breath' },
            { text: 'other' },
            { text: 'None of the above' },
        ]
    },


    treatments: {
        handleSelect: (index) => {
            if (index == 1)
                return 'medications'
            else
                return 'feel_better'
        },
        type: 'simple',
        question: 'Have you tried any treatments (creams, medications) to help with your symptoms?',
        formId: 'treatments',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },


    feel_better: {
        handleSelect: (index) => { return 'feel_worse' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel better?',
        formId: 'feel_better',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate",
        placeholder: "e.g. Tums",
        inputType: "text"
    },


    feel_worse: {
        handleSelect: (index) => { return 'effect' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel worse?',
        formId: 'feel_worse',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate which treatments made your symptoms feel worse",
        placeholder: "Type hear ...",
        inputType: "text"
    },


    effect: {
        path: 'effect',
        handleSelect: (index) => { return 'medications' },
        type: 'simple',
        question: 'Did any of the treatments have no effect on your symptoms?',
        formId: 'effect',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate ",
        placeholder: "Type hear ...",
        inputType: "text"
    },

    medications: {
        handleSelect: (index) => { return 'anything' },
        type: 'simple',
        question: 'Do you have any allergies to medications?',
        formId: 'medications',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: 'Please specify medication allergies',
        placeholder: '... ',
        inputType: 'addItem',
    },




    anything: {
        handleSelect: (index) => { return 'finish' },
        type: 'simple',
        question: 'Is there anything else we should know?',
        formId: 'anything',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        placeholder: "Type hear ...",
        inputType: "text"
    }
}
