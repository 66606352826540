import React, { useContext, createContext } from 'react';
import { Button, Modal, Backdrop, Fade, useMediaQuery } from '@material-ui/core';
import { ModalCloseBtn } from '../Buttons/styles';
import styled from '@emotion/styled';
import { TOPIC } from '../Texts/styles';
import TextContainer from '../Texts/TextContainer';
import colors from '../../global/colors';
const Container = styled.div`
    position: absolute;
    top: 45%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translate(-50%, -50%) scale(0.8);
    background-color: #fff;
    border-radius: 8px;
    align-items:center;
    box-shadow: ${colors.boxShadow};
    transition: all 0.3s ease-in-out;
    max-width: 600px;
    width: 100%;
    padding-bottom: 1rem;
    `
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    margin-bottom: 16px;
    background-color: #C5D4EA;
    padding: 1rem;
    border-radius: 8px 8px 0 0;
    `
const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow:auto;
    align-items: center;
    width: 100%;
    max-height:85vh;
    padding: 1rem;
`
export const modalContext = createContext();

export default () => {
    const { show, setShow, content, setContent } = useContext(modalContext);
    const isSmallScreen = useMediaQuery('(min-width:700px)');
    return (
        <>
            {isSmallScreen ?
                <Modal
                    disableEnforceFocus
                    open={show}
                    onClose={() => setShow(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                        style: {
                            backdropFilter: "blur(2px)",
                            background: "rgba(87, 99, 117, 0.5)"
                        }
                    }}
                >
                    <Fade in={show}>
                        <Container>
                            <Header>
                                <TextContainer {...TOPIC}>{content.title}</TextContainer>
                                <Button onClick={() => setShow(false)} style={ModalCloseBtn}>
                                    X
                                </Button>
                            </Header>
                            <Content>
                                {content.body}
                            </Content>
                        </Container>
                    </Fade>
                </Modal>
                :
                ""
            }
        </>
    );
}

