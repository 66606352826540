import Footer, {
  CustomTableBody,
  CustomTableContainer,
  TableHeader,
} from "../../components/table";
import { TableRow, Paper, Table, TableCell } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { Row } from "../../global/table";
import { DeleteModal, AddModal, EditModal } from "./modal";
import { modalContext } from "../../components/modal/modal";
import { ButtonContainer } from "../../components/Buttons/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import Filter from "../../components/filter/filter";
import Navbar from "../../components/NavBar";
import MainFrame from "../../components/MainFrame";
import usePatientsApi from "../../api/patient.api";
import styled from "@emotion/styled";
import colors from "../../global/colors";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import TextContainer from "../../components/Texts/TextContainer";

const filters = [
  { label: "Name", type: "name", collection: "name" },
  { label: "Illness", type: "name", collection: "illness" },
];
const CustomRow = styled(Row)({
  cursor: "pointer",
  ":hover": {
    background: colors.lightBgGray0,
  },
});
function Patients() {
  const [patients, setPatients] = useState([]);
  const { setShow, setContent } = useContext(modalContext);
  const navigate = useNavigate();

  const [query, setQuery] = useState({
    page: 0,
    size: 20,
  });
  const api = usePatientsApi();

  const loadPatients = (query = {}) => {
    api
      .getPatients(query)
      .then((result) => {
        setPatients(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    loadPatients(query);
  }, [query]);
  const handleOpenModal = (type, ...args) => {
    if (type === "delete")
      setContent({
        title: "Remove Patient",
        body: <DeleteModal id={args[0]} dataLoader={loadPatients} />,
      });
    else if (type === "Add") {
      setContent({
        title: "add new patient",
        body: <AddModal dataLoader={loadPatients} />,

      });
    }
    setShow(true);
  };
  return (
    <React.Fragment>
      <Navbar topic="Patients">
        <ButtonContainer>
          <AddModal dataLoader={loadPatients} />
        </ButtonContainer>
      </Navbar>
      <Filter
        convertor={"toMongoo"}
        filters={filters}
        dataLoader={(q) =>
          setQuery((prev) => ({
            page: prev.page,
            size: prev.size,
            ...q,
          }))
        }
      />
      <CustomTableContainer component={Paper} style={{ maxHeight: "70vh" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHeader>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Birthday</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Algorithms</TableCell>
              <TableCell width={"5%"} style={{ textAlign: "center" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHeader>
          <CustomTableBody>
            {patients?.map((patient, index) => {
              return (
                <CustomRow
                  key={index}
                  onClick={() =>
                    navigate("/PersonalPatientPage", {
                      state: patient,
                    })
                  }
                >
                  <TableCell>{patient.firstName}</TableCell>
                  <TableCell>{patient.lastName}</TableCell>
                  <TableCell>{patient.birthDay?.split("T")[0]}</TableCell>
                  <TableCell>{patient.gender}</TableCell>
                  <TableCell>
                    <TextContainer
                      textDecoration="underline"
                      width="100%"
                      justifyContent="left"
                      color={colors.darkBlue}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/algorithms/${patient.firstName}_${patient.lastName}`, { state: patient });
                      }}>
                      see
                    </TextContainer>
                  </TableCell>
                  <TableCell
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <EditModal patient={patient} dataLoader={loadPatients} />
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenModal("delete", patient._id);
                        }}
                      >
                        <DeleteIcon
                          width="20px"
                          style={{ cursor: "pointer" }}
                        />
                      </IconButton>
                    </div>
                  </TableCell>
                </CustomRow>
              );
            })}
          </CustomTableBody>
        </Table>
        <Footer
          value={query}
          handleChange={(e) => {
            setQuery((prev) => ({ ...prev, ...e }));
          }}
        />
      </CustomTableContainer>
    </React.Fragment>
  );
}

export default MainFrame(Patients, true);
