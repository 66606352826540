import { React, useEffect, useMemo, useState } from "react";
import { IconButton, InputAdornment } from "@material-ui/core";
import colors from "../../global/colors";
import { CustomTextField } from "../TextFields";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";

export default function VoiceInput({
  label,
  placeholder,
  handleChange,
  value,
}) {
  const [record, setRecord] = useState(false);
  const [text, setText] = useState(value);
  useEffect(() => {
    setText(value);
  }, [value]);
  const recognition = useMemo(() => {
    if (typeof window == undefined) return null;

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;
    return recognition;
  }, []);
  if (recognition) {
    recognition.onresult = (event) => {
      setText(
        value +
          " " +
          Object.values(event.results)
            .map((result) => result[0].transcript)
            .join("")
      );
    };
    recognition.onend = () => {
      handleChange(text);
    };
  }
  useEffect(() => {
    if (record) {
      recognition?.start();
    } else {
      recognition?.stop();
    }
  }, [record]);

  return (
    <CustomTextField
      variant="outlined"
      label={label}
      placeholder={placeholder}
      multiline={true}
      InputLabelProps={{ shrink: true }}
      maxRows={5}
      minRows={3}
      onChange={(e) => {
        e.stopPropagation();
        handleChange(e.target.value);
        setText(e.target.value);
      }}
      value={text}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="start"
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <IconButton
              size="small"
              style={{
                background: colors.white,
                zIndex: 3,
                border: record ? `3px solid ${colors.lightBlue}` : "none",
              }}
              onClick={() => setRecord(!record)}
            >
              <KeyboardVoiceIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
