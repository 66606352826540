export default {
  index: {
    handleSelect: (index) => {
      return "finish";
    },
    type: "other",
    question: "Put your comment about ailment",
    formId: "index",
  },
};
