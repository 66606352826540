import React, { memo, useCallback, useContext } from "react";
import { Autocomplete } from "@mui/material";
import NavBar from "../../../../components/NavBar";
import { Box } from "@material-ui/core";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { FormActiosn } from "../../../../global/redux/form";
import Layout from "../edit/layout";
import { isEqual } from "lodash";
import usePatientsApi from "../../../../api/patient.api";
import useNotification from "../../../../components/notification";
import { modalContext } from "../../../../components/modal/modal";
import { PatientSelectionInput } from "./patientSelectionInput";

function PatientSelection({ formData, dispatch, formNavigator }) {
  const [err, setErr] = useState("");
  const [patient, setPatient] = useState({
    ...formData,
  });
  const [options, setOptions] = useState([]);
  const api = usePatientsApi();
  const { addNotification } = useNotification();
  const loadPatients = useCallback(() => {
    let isActive = true;
    api
      .getPatients({})
      .then((patients) => {
        if (isActive)
          setOptions(
            patients.map((patient) => ({
              _id: patient._id,
              userName: `${patient.firstName} ${patient.lastName}`,
              phone: patient.phone,
              address: patient.address,
              postalCode: patient.postalCode,
              gender: patient.gender,
              birthDay: patient.birthDay,
            }))
          );
      })
      .catch((err) => {
        console.error(err);
        addNotification({
          severity: "error",
          text: "problem to get patient list",
          duration: 2000,
          onClick: null,
        });
      });

    return () => {
      isActive = false;
    };
  }, []);
  useEffect(loadPatients, []);
  useEffect(() => {
    setPatient({
      ...formData,
    });
  }, [formData]);
  const setFormData = () => {
    dispatch(FormActiosn.setPatient(patient));
  };
  const onGoNext = () => {
    if (patient?._id === -1 || !patient?._id) {
      return setErr("select patient");
    }
    setFormData();
    formNavigator("index");
    setErr("");
  };
  return (
    <Layout
      showCancle={false}
      firstButton={{
        text: "Cancel",
        onClick: () => formNavigator(-1),
      }}
      secondButton={{
        text: "Next",
        onClick: onGoNext,
      }}
    >
      <React.Fragment>
        <Box style={{ width: "100%" }}>
          <NavBar topic="Patient selection" />
          <PatientSelectionInput
            err={err?.length}
            helperText="select the patient among patients list or add a new patient first and select it after."
            onChange={(value) => setPatient(value)}
            defaultValue={patient}
          />
        </Box>
      </React.Fragment>
    </Layout>
  );
}

function mapStateToProps(state, ownProps) {
  const { patient } = state.forms.data;

  return {
    ...ownProps,
    formData: patient,
  };
}

export default connect(mapStateToProps)(
  memo(PatientSelection, function (prev, next) {
    return isEqual(prev.formData, next.formData);
  })
);
