import { useNavigate } from "react-router";
import useAuthApi from "../../api/auth.api";
import useNotification from "../../components/notification";
import { HttpStatusCode } from "axios";

export default function useLoginController() {
  const api = useAuthApi();
  const { addNotification } = useNotification();
  const navigate = useNavigate();

  async function requestCode(email) {
    return api.requestCode(email).catch(
      /**
       *
       * @param {AxiosError} err
       */
      (err) => {
        if (err?.response?.status == 400)
          addNotification({
            severity: "error",
            text: "bad request try again later",
            duration: 2000,
            onClick: null,
          });
        else if (err?.response?.status == HttpStatusCode.TooManyRequests)
          addNotification({
            severity: "error",
            text: "to many attemp;s by this email try again later",
            duration: 2000,
            onClick: null,
          });
        addNotification({
          severity: "error",
          text: "unknown error please try again later",
          duration: 2000,
          onClick: null,
        });
        throw Error(); // to catch on next chain
      }
    );
  }
  async function validateCode(email, code, onSuccess) {
    return api
      .validateCode(email, code)
      .then(() => onSuccess())
      .catch(
        /**
         *
         * @param {AxiosError} err
         */
        (err) => {
          if (err.response.status == HttpStatusCode.TooManyRequests)
            return addNotification({
              severity: "error",
              text: "to many attemp's by this email please try again later",
              duration: 2000,
              onClick: null,
            });
          if (err.response.status == HttpStatusCode.BadRequest)
            return addNotification({
              severity: "error",
              text: "somthing went wrong please try again later",
              duration: 2000,
              onClick: null,
            });
          if (err.response.status == HttpStatusCode.Unauthorized)
            return addNotification({
              severity: "error",
              text: "invalid code",
              duration: 2000,
              onClick: null,
            });
        }
      );
  }
  function login(email, password) {
    api
      .login(email, password)
      .then(() => {
        navigate("/Patients");
      })
      .catch(
        /**
         *
         * @param {AxiosError} err
         */
        (err) => {
          if (err.response.status == HttpStatusCode.TooManyRequests)
            return addNotification({
              severity: "error",
              text: "to many attemp's by this email please try again later",
              duration: 2000,
              onClick: null,
            });
          if (err.response.status == HttpStatusCode.BadRequest)
            return addNotification({
              severity: "error",
              text: "somthing went wrong please try again later",
              duration: 2000,
              onClick: null,
            });
          if (err.response.status == HttpStatusCode.Unauthorized)
            return addNotification({
              severity: "error",
              text: "invalid code",
              duration: 2000,
              onClick: null,
            });
        }
      );
  }
  function signup(data) {
    api
      .signup(data)
      .then(() => {
        navigate("/Patients");
      })
      .catch((err) => {
        console.error(err);
        addNotification({
          severity: "error",
          text: "Problem to signup please try again later",
          duration: 2000,
          onClick: null,
        });
      });
  }

  async function resetPassword(email, password) {
    await api
      .resetPassword({ email, password })
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        console.error(err);
        addNotification({
          severity: "error",
          text: "Problem to reset password please try again later",
          duration: 2000,
          onClick: null,
        });
      });
  }

  return {
    requestCode,
    login,
    validateCode,
    signup,
    resetPassword,
  };
}
