import { Button } from "@mui/material";
import { ButtonContainer, NegativeButton, PositiveButton } from "../../components/Buttons/styles";
import { useCallback, useContext } from "react";
import { modalContext } from "../../components/modal/modal";
import styled from "@emotion/styled";
// import useRequestApi from "../../api/requests.api";

const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
`

export default function CancleModal({
    onCancle,
    requestId,
}) {
    const { setShow} = useContext(modalContext)
    // const api = useRequestApi();
    const onSubmit = useCallback(() => {
            // api.cancleRequest(requestId)
            //     .then(() => { onCancle() })
            //     .then(() => { setShow(false) })
            //     .catch(err => console.log(err))
    }, [requestId])


    return (
        <Container>
            <p style={{ textTransform: "capitalize", fontSize: "1.05rem" }}>are you sure you want to cancel this request?</p>
            <ButtonContainer>
                <Button variant="outlined" onClick={() => setShow(false)} style={NegativeButton}>cancel</Button>&nbsp;
                <Button variant="contained" style={PositiveButton} onClick={onSubmit} >Delete</Button>
            </ButtonContainer>
        </Container>
    )
}