import questions from "../questions";
import { getToPdfComponent } from "../resolver";
import "./prescription/styles.css";

export default function RenderDocument({ illness, formState }) {
  const question = questions[illness];
  if (!question) {
    console.error("invalid illness");
    return <div></div>;
  }
  const { stack, data } = formState;
  const { patient, owner } = data;

  const {
    referredToDoctor = {
      answer: undefined,
      comment: "",
    },
    medicationsPrescribed = {
      answer: undefined,
      comment: "",
    },
    medications = [],
  } = data["metadata"] || {};

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        padding: "2rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            width: "100%",
            margin: 0,
            padding: 0,
            textAlign: "center",
            textTransform: "capitalize",
            textDecoration: "underline",
          }}
        >
          {patient?.userName}'s form of {illness}
        </h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "1rem",
          justifyContent: "center",
          width: "100%",
          gap: ".5rem",
          alignItems: "flex-start",
          padding: "0 1rem",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: ".5rem",
            width: "100%",
          }}
        >
          <span
            style={{
              textTransform: "capitalize",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Patient:
          </span>
          <span style={{ textTransform: "capitalize", fontSize: "14px" }}>
            {patient?.userName}
          </span>
        </span>
        <span
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: ".5rem",
            width: "100%",
          }}
        >
          <span
            style={{
              textTransform: "capitalize",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            ailment:
          </span>
          <span style={{ textTransform: "capitalize", fontSize: "14px" }}>
            {illness}
          </span>
        </span>
        <span
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: ".5rem",
            width: "100%",
          }}
        >
          <span
            style={{
              textTransform: "capitalize",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            created At:
          </span>
          <span style={{ textTransform: "capitalize", fontSize: "14px" }}>
            {data?.createdAt}
          </span>
        </span>
        <span
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: ".5rem",
            width: "100%",
          }}
        >
          <span
            style={{
              textTransform: "capitalize",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            created by:
          </span>
          <span style={{ textTransform: "capitalize", fontSize: "14px" }}>
            {" "}
            {owner?.businessName}
          </span>
        </span>
      </div>
      {stack.map((form, index) => {
        const formData = data[form.formId];
        const props = question[form.formId];
        const FormComponent = getToPdfComponent(form.resolver);
        return <FormComponent key={index} {...props} formData={formData} />;
      })}
      <div
        className="preview-pdf-container"
        style={{
          borderRadius: ".5rem",
          border: "2px groove gray",
          marginTop:"1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          gap: ".5rem",
          alignItems: "flex-start",
          padding: ".5rem 1rem",
        }}
      >
        <div>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: ".5rem",
              width: "100%",
            }}
          >
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              1. Has the patient been referred to the doctor?
            </span>
            <span style={{ textTransform: "capitalize", fontSize: "14px" }}>
              {referredToDoctor.answer}
            </span>
          </span>
          {referredToDoctor.comment && (
            <p style={{ fontSize: "14px", marginLeft: "1rem" }}>
              comment: {referredToDoctor.comment}
            </p>
          )}
        </div>
        <div>
          <span
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "1rem",
              width: "100%",
            }}
          >
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              2. Have any medications been prescribed?
            </span>
            <span style={{ textTransform: "capitalize", fontSize: "14px" }}>
              {medicationsPrescribed.answer}
            </span>
          </span>
          {medicationsPrescribed.comment && (
            <p style={{ fontSize: "14px", marginLeft: "1rem" }}>
              comment: {medicationsPrescribed.comment}
            </p>
          )}
        </div>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "1rem",
            width: "100%",
          }}
        >
          <span
            style={{
              textTransform: "capitalize",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            3. medications
          </span>
          <div
            style={{
              gap: "1rem",
              flexWrap: "wrap",
              padding:"0 1rem",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            {medications.map((med, index) => {
              return (
                <div
                  style={{
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    borderRadius: ".5rem",
                    border:"1px solid gray",
                    width:"100%"
                  }}
                >
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontSize: "14px",
                    }}
                  >
                    {med?.inq1}
                  </span>
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontSize: "14px",
                    }}
                  >
                    {med?.inq2}
                  </span>
                  <span
                    style={{
                      textTransform: "capitalize",
                      fontSize: "14px",
                    }}
                  >
                    {med?.inq3}
                  </span>
                </div>
              );
            })}
          </div>
        </span>
      </div>
    </div>
  );
}
