

export default {
    index: {
        handleSelect: (index) => { return 'gender' },
        type: 'simple',
        question: 'Where would you like the appointment to take place?',
        formId: 'index',
        answers: [
            {
                text: 'inPerson'
            },
            {
                text: 'phone call'
            }
        ]
    },
    gender: {
        handleSelect: (index) => {
            if (index == 0) {
                return 'exp_before'
            }
            return 'pregnant'
        },
        type: 'simple',
        question: 'What is your gender?',
        formId: 'gender',
        answers: [
            { text: 'Male' },
            { text: 'Female' },
            { text: 'Other' }
        ],
        optionalKey: 2,
        label: "Please self-identify:",
        placeholder: "(optional)",
        inputType: "text",
        require: true
    },
    pregnant: {
        handleSelect: (index) => { return 'breastfeeding' },
        type: 'simple',
        question: 'Are you (or could you be) pregnant?',
        formId: 'pregnant',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },
    breastfeeding: {
        handleSelect: (index) => { return 'exp_before' },
        type: 'simple',
        question: 'Are you breastfeeding?',
        formId: 'breastfeeding',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },
    exp_before: {
        handleSelect: (index) => { return 'age' },
        type: 'simple',
        question: 'Have you experienced hemorrhoids before?',
        formId: 'exp_before',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },
    age: {
        handleSelect: (index) => { return 'daily_basis_exp' },
        type: 'simple',
        question: 'Are you 50 years of age or older?',
        formId: 'age',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },
    // to do add select
    daily_basis_exp: {
        handleSelect: (index) => { return 'symptoms' },
        type: 'multi',
        select: 1,
        question: 'On a daily basis, are you experiencing:',
        formId: 'daily_basis_exp',
        answers: [
            { text: 'More bowel movements than normal' },
            { text: 'Less bowel movements than normal' },
            { text: 'When lying down or bending over' },
            { text: 'About the same number of bowel movements as normal' },
        ],
    },
    symptoms: {
        handleSelect: (index) => { return 'other_symptoms' },
        type: 'multi',
        question: 'Do you experience any of the symptoms listed below?',
        formId: 'symptoms',
        cancleAllIndex: 5,
        answers: [
            { text: 'During the Dark red blood or blood mixed within stools' },
            { text: 'Black or tarry stools' },
            { text: 'Severe pain' },
            { text: 'Swelling or a mass in the rectal area' },
            { text: 'Unexplained weight loss' },
            { text: 'None of the above' },
        ],
        require: true
    },
    other_symptoms: {
        handleSelect: (index) => { return 'family_hostory' },
        type: 'simple',
        question: 'Do you have any other symptoms not mentioned in the previous questions?',
        formId: 'other_symptoms',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        inputType: "text"

    },
    family_hostory: {
        handleSelect: (index) => { return 'ibd' },
        type: 'simple',
        question: 'Do you have a personal or family history of colorectal cancer or polyps?',
        formId: 'family_hostory',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
    },
    ibd: {
        handleSelect: (index) => { return 'treatments' },
        type: 'simple',
        question: 'Do you have a personal or family history of Inflammatory bowel disease (IBD)?',
        formId: 'ibd',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
    },
    treatments: {
        handleSelect: (index) => {
            if (index == 1)
                return 'medications'
            else
                return 'feel_better'
        },
        type: 'simple',
        question: 'Have you tried any treatments (eg creams, medication) for your symptoms?',
        formId: 'treatments',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },
    feel_better: {
        handleSelect: (index) => { return 'feel_worse' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel better?',
        formId: 'feel_better',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate",
        placeholder: "e.g. Tums",
        inputType: "text"
    },
    feel_worse: {
        handleSelect: (index) => { return 'effect' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel worse?',
        formId: 'feel_worse',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate which treatments made your symptoms feel worse",
        placeholder: "Type hear ...",
        inputType: "text"
    },
    effect: {
        path: 'effect',
        handleSelect: (index) => { return 'medications' },
        type: 'simple',
        question: 'Did any of the treatments have no effect on your symptoms?',
        formId: 'effect',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate ",
        placeholder: "Type hear ...",
        inputType: "text"
    },
    medications: {
        handleSelect: (index) => { return 'anything' },
        type: 'simple',
        question: 'Do you have any allergies to medications?',
        formId: 'medications',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: 'Please specify medication allergies',
        placeholder: '... ',
        inputType: 'addItem',
    },
    anything: {
        handleSelect: (index) => { return 'finish' },
        type: 'simple',
        question: 'Is there anything else we should know?',
        formId: 'anything',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        placeholder: "Type hear ...",
        inputType: "text"
    },
}

