import { Paper, TableCell, TableRow } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { modalContext } from "../../components/modal/modal";
import Footer, { CustomTableBody, CustomTableContainer, TableHeader } from "../../components/table";
import dayjs from "dayjs";
import Table, { Row } from "../../global/table";
import Filter from "../../components/filter/filter";
import CancleModal from "./modal";
import MainFrame from "../../components/MainFrame";
// import useRequestApi from "../../api/requests.api";

const filters = [
    { label: "Title", type: 'name', collection: 'title' }
]

const stateMapper = ['IDLE', 'Accepted', 'Send', 'Cancled']
function Requests() {
    const { setShow, setContent } = useContext(modalContext);
    const [requests, setRequests] = React.useState([{},{}]);
    const navigate = useNavigate()
    // const api = useRequestApi();
    const [query, setQuery] = React.useState({
        page: 0,
        size: 20
    })
    const loadRequests = useCallback((query) => {
        let isActive = true;
        // api.getRequests(query)
        //     .then((result) => {
        //         if (isActive)
        //             setRequests(result)
        //     })
        //     .catch(err => console.log(err))

        return () => { isActive = false; }
    }, [])

    useEffect(loadRequests, [query]);

    function handleOpenModal(type, ...args) {
        if (type === "cancle") {
            setContent({
                title: "cancel request",
                body: <CancleModal
                    requestId={args[0]}
                    onCancle={() => loadRequests(query)}
                />
            })
        }
        setShow(true)
    }
    return (
        <Fragment>
            <NavBar topic="Requests" />
            <Filter convertor={'toSQL'} filters={filters} dataLoader={loadRequests} />
            < CustomTableContainer component={Paper} style={{ maxHeight: "73vh" }}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHeader >
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>state</TableCell>
                            <TableCell>Patient</TableCell>
                            <TableCell width="15%">Date Created</TableCell>
                            <TableCell width="7%">Action</TableCell>
                        </TableRow>
                    </TableHeader>
                    <CustomTableBody >
                        {requests?.map((request, index) => {
                            return (
                                <Row
                                    style={{ cursor: "pointer" }}
                                    key={index} onClick={() => {
                                        // navigate("/Requests/Request:name",
                                        navigate("/requests/request/:requestId",
                                         {
                                            state: request
                                        })
                                    }} >
                                    <TableCell style={{padding:"10px",fontSize:".9rem"}} >{'Request Title'}</TableCell>
                                    <TableCell style={{padding:"10px",fontSize:".9rem"}} >{'IDLE'}</TableCell>
                                    <TableCell style={{padding:"10px",fontSize:".9rem"}} >{'Mohamamd'}</TableCell>
                                    <TableCell style={{padding:"10px",fontSize:".9rem"}} >{'2024-MAY-05'}</TableCell>
                                    <TableCell style={{padding:"10px",fontSize:".9rem"}}
                                    >
                                        {
                                            request.state === 0 ?
                                                <DeleteIcon
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleOpenModal("cancle", request.id)
                                                    }} />
                                                :
                                                null
                                        }
                                    </TableCell>
                                </Row>
                            )
                        })}
                    </CustomTableBody>
                </Table>
                <Footer value={query} handleChange={(e) => {
                    setQuery({
                        ...query,
                        ...e
                    })
                }} />
            </CustomTableContainer >
        </Fragment>
    )
}

export default MainFrame(Requests, true)
