import React, { useEffect, useState } from "react";
import { InputAdornment } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import { CustomTextField } from "../TextFields";
import formatPhoneNumber from "../../utils/formatPhoneNumber";

const CanadianPhoneField = ({
  label,
  onChange,
  AdornmentIcon = <PhoneIcon />,
  defaultValue,
  value,
  disabled=false,
  helperText,
  error,
  style,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(value);
  useEffect(() => {
    console.log("heare to change phone", defaultValue);
    setPhoneNumber(defaultValue);
  }, [defaultValue]);
  const handlePhoneNumberChange = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    setPhoneNumber(value);
    onChange(value);
  };

  return (
    <CustomTextField
      style={style}
      label={label}
      disabled={disabled}
      value={formatPhoneNumber(phoneNumber)}
      onChange={handlePhoneNumberChange}
      variant="outlined"
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{AdornmentIcon}</InputAdornment>
        ),
        inputProps: {
          maxLength: 14, // Limit the input to a 14-digit phone number
        },
      }}
    />
  );
};

export default CanadianPhoneField;
