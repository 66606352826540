
export default {
    index: {
        handleSelect: (index) => {
            if (index == 0) {
                return 'contact_lenses'
            }
            return 'pregnant'
        },
        type: 'simple',
        question: 'What is your gender?',
        formId: 'index',
        answers: [
            { text: 'Male' },
            { text: 'Female' },
            { text: 'Other' }
        ],
        optionalKey: 2,
        label: "Please self-identify:",
        placeholder: "(optional)",
        inputType: "text",
        require: true
    },

    pregnant: {
        handleSelect: (index) => { return 'breastfeeding' },
        type: 'simple',
        question: 'Are you (or could you be) pregnant?',
        formId: 'pregnant',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    breastfeeding: {
        handleSelect: (index) => { return 'contact_lenses' },
        type: 'simple',
        question: 'Are you breastfeeding?',
        formId: 'breastfeeding',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    contact_lenses: {
        handleSelect: (index) => { return 'eye_symptoms_exp' },
        type: 'simple',
        question: 'Do you wear contact lenses?',
        formId: 'contact_lenses',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' },
        ]
    },

    eye_symptoms_exp: {
        handleSelect: (index) => { return 'symptoms_exp' },
        type: 'multi',
        question: 'What symptoms are you experiencing in your eyes?',
        formId: 'eye_symptoms_exp',
        cancleAllIndex: 4,
        answers: [
            { text: 'Redness' },
            { text: 'Itching' },
            { text: 'Discharge or crusty eyes' },
            { text: 'Watery eyes' },
            { text: 'None of the above' }
        ]
    },

    symptoms_exp: {
        handleSelect: (index) => { return 'symptoms_exp_loc' },
        type: 'multi',
        question: 'What symptoms are you experiencing in your eyes?',
        formId: 'symptoms_exp',
        cancleAllIndex: 5,
        answers: [
            { text: 'Vision changes' },
            { text: 'Sensitivity to light' },
            { text: 'The feeling that something is in your eye' },
            { text: 'A severe headache' },
            { text: 'Nausea or vomiting' },
            { text: 'None of the above' }
        ]
    },


    symptoms_exp_loc: {
        handleSelect: (index) => { return 'other_symptoms' },
        type: 'simple',
        question: 'Are you experiencing symptoms in both eyes?',
        formId: 'symptoms_exp_loc',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
    },


    other_symptoms: {
        handleSelect: (index) => { return 'recently_injury' },
        type: 'simple',
        question: 'Do you have any other symptoms not mentioned in the last question?',
        formId: 'other_symptoms',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        inputType: "text"
    },

    recently_injury: {
        handleSelect: (index) => { return 'idea_what_happen' },
        type: 'simple',
        question: 'Did you recently have trauma, injury, or an accident to your eye?',
        formId: 'recently_injury',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
    },

    idea_what_happen: {
        handleSelect: (index) => { return 'medical_conditions' },
        type: 'simple',
        question: 'Do you have any idea what may have caused your symptoms?',
        formId: 'idea_what_happen',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: 'Please explain',
        inputType: 'text'
    },

    medical_conditions: {
        handleSelect: (index) => { return 'treatments' },
        type: 'simple',
        question: 'Do you have any medical conditions relating to your eyes (e.g. glaucoma)?',
        formId: 'medical_conditions',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' },
        ],
        require: true,
        optionalKey: 0,
        label: 'Please specify',
        inputType: 'text'
    },
    treatments: {
        handleSelect: (index) => {
            if (index == 1)
                return 'medications'
            else
                return 'feel_better'
        },
        type: 'simple',
        question: 'Have you tried any treatments (eg. medicine, eye drops etc.) to help with your eye symptoms?',
        formId: 'treatments',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },
    feel_better: {
        handleSelect: (index) => { return 'feel_worse' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel better?',
        formId: 'feel_better',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate",
        placeholder: "e.g. Tums",
        inputType: "text"
    },
    feel_worse: {
        handleSelect: (index) => { return 'effect' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel worse?',
        formId: 'feel_worse',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate which treatments made your symptoms feel worse",
        placeholder: "Type hear ...",
        inputType: "text"
    },
    effect: {
        path: 'effect',
        handleSelect: (index) => { return 'medications' },
        type: 'simple',
        question: 'Did any of the treatments have no effect on your symptoms?',
        formId: 'effect',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate ",
        placeholder: "Type hear ...",
        inputType: "text"
    },
    medications: {
        handleSelect: (index) => { return 'anything' },
        type: 'simple',
        question: 'Do you have any allergies to medications?',
        formId: 'medications',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: 'Please specify medication allergies',
        placeholder: '... ',
        inputType: 'addItem',
    },
    anything: {
        handleSelect: (index) => { return 'finish' },
        type: 'simple',
        question: 'Is there anything else we should know?',
        formId: 'anything',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        placeholder: "Type hear ...",
        inputType: "text"
    },
}

