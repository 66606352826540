import { Box } from "@mui/material";
import TextContainer from "../../../components/Texts/TextContainer";
import { TherapyTable } from "./table";
import Data from "../../../constants/ailments.db.json"
export const TherapyMonitoring = ({ ailment }) => {
    const {
        headers = [],
        body = []
    } = Data[ailment]?.monitoring;
    return (
        <Box width="100%" padding="0 1rem" display="flex" flexDirection="column" gap="1rem">
            <TextContainer width="100%" justifyContent="left" fontSize=".8rem">&nbsp;This Table contains information on monitoring therapy.</TextContainer>
            <TherapyTable ailment={ailment} headers={headers} body={body} />
        </Box>
    )
}