import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { modalContext } from "../modal/modal";
import { ButtonContainer, NegativeButton, PositiveButton } from "../Buttons/styles";
import { Box, Button, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { CustomTextField } from "../TextFields";
import { Ailments } from "../inq/data/ailments";
import TextContainer from "../Texts/TextContainer";
import SearchIcon from '@mui/icons-material/Search';
export const LogoutModal = ({ id, dataLoader }) => {
    const { setShow } = useContext(modalContext)
    const navigate = useNavigate();
    function handleLogout() {
        navigate("/")
        setShow(false)
    }
    return (
        <>
            <p style={{ textTransform: "capitalize", fontSize: "1rem" }}>are you sure you want to Logout?</p>&nbsp;
            <ButtonContainer>
                <Button variant="outlined" onClick={() => setShow(false)} style={NegativeButton}>cancel</Button>&nbsp;
                <Button variant="contained" onClick={() => handleLogout()} style={PositiveButton} >Logout</Button>
            </ButtonContainer>
        </>
    )
}


export default function SearchModal() {
    const [searchTerm, setSearchTerm] = useState('');
    const { setShow } = useContext(modalContext);
    const navigate = useNavigate();
    const filteredData = Ailments.filter((item) =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    return (
        <React.Fragment>
            <CustomTextField
                fullWidth
                label="Search..."
                value={searchTerm}
                onChange={handleSearch}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
            <List
                sx={{
                    maxHeight: "60vh",
                    overflow: "auto",
                    width: "100%",

                }}>
                {filteredData.length > 0 ?
                    filteredData.map((item, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    setShow(false);
                                    navigate(`/ailments/${item}`, { state: item })
                                }}>
                                <ListItemText sx={{ fontSize: ".9rem" }}>{item}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))
                    :
                    <TextContainer>no itemss</TextContainer>
                }
            </List>
        </React.Fragment>

    );
};
