
export default {
    index: {
        handleSelect: (index) => { return 'exp_before' },
        type: 'simple',
        question: 'Are you filling this form out for your baby?',
        formId: 'index',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    exp_before: {
        handleSelect: (index) => { return 'breastfed' },
        type: 'simple',
        question: 'Has your baby been diagnosed with oral thrush before?',
        formId: 'exp_before',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    breastfed: {
        handleSelect: (index) => { return 'symptoms' },
        type: 'simple',
        question: 'Is this baby currently being breastfed?',
        formId: 'breastfed',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    symptoms: {
        handleSelect: (index) => { return 'other_symptoms' },
        type: 'multi',
        question: 'What symptoms is your baby experiencing?',
        formId: 'symptoms',
        cancleAllIndex: 3,
        answers: [
            { text: 'White patches on the tongue, back of throat, or inside the cheeks' },
            { text: 'Redness inside the mouth and throat' },
            { text: 'Sores in the mouth that are swollen or burning' },
            { text: 'None of the above' },
        ],
        require: true
    },

    other_symptoms: {
        handleSelect: (index) => { return 'symptoms_start' },
        type: 'simple',
        question: 'Does your baby have any other symptoms not mentioned in the last question?',
        formId: 'other_symptoms',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        inputType: "text"
    },


    symptoms_start: {
        handleSelect: (index) => { return 'inhalers' },
        type: 'simple',
        question: 'Does your baby have any other symptoms not mentioned in the last question?',
        formId: 'symptoms_start',
        answers: [],
        optionalKey: -1, // active the optional 
        label: "",
        placeholder: 'e.g. one daye ago',
        inputType: "text"
    },

    inhalers: {
        handleSelect: (index) => { return 'antibiotics' },
        type: 'simple',
        question: 'Does your baby have any other symptoms not mentioned in the last question?',
        formId: 'inhalers',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        inputType: "text"
    },


    antibiotics: {
        handleSelect: (index) => { return 'immune_system' },
        type: 'simple',
        question: 'Has your baby been treated with antibiotics in the past 2 weeks?',
        formId: 'antibiotics',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },



    immune_system: {
        handleSelect: (index) => { return 'immune_medications' },
        type: 'multi',
        question: 'Do you experience any of the symptoms listed below?',
        formId: 'immune_system',
        cancleAllIndex: 5,
        answers: [
            { text: 'HIV/AIDS' },
            { text: 'Cancer' },
            { text: 'Removal of Spleen' },
            { text: 'Transplant' },
            { text: 'Other' },
            { text: 'None of the above' },
            { text: "I don't know" }
        ],
        require: true
    },


    immune_medications: {
        handleSelect: (index) => { return 'new_medications' },
        type: 'multi',
        question: 'Is your baby taking any medications that suppress their immune system?',
        formId: 'immune_medications',
        cancleAllIndex: 5,
        answers: [
            { text: 'Steroids' },
            { text: 'Chemotherapy' },
            { text: 'Biological agents' },
            { text: 'Other' },
            { text: 'None of the above' },
            { text: "I don't know" }
        ],
        require: true
    },


    new_medications: {
        handleSelect: (index) => { return 'treatments' },
        type: 'simple',
        question: 'Has the baby started any new medications recently?',
        formId: 'new_medications',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },


    treatments: {
        handleSelect: (index) => {
            if (index == 1)
                return 'medications'
            return 'feel_better'

        },
        type: 'simple',
        question: "Have you tried any treatments for your baby’s symptoms?",
        formId: 'treatments',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },


    feel_better: {
        handleSelect: (index) => { return 'feel_worse' },
        type: 'simple',
        question: 'Did any of the treatments make your baby’s symptoms feel better?',
        formId: 'feel_better',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate",
        placeholder: "e.g. Tums",
        inputType: "text"
    },


    feel_worse: {
        handleSelect: (index) => { return 'effect' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel worse?',
        formId: 'feel_worse',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate which treatments made your symptoms feel worse",
        placeholder: "Type hear ...",
        inputType: "text"
    },

    effect: {
        path: 'effect',
        handleSelect: (index) => { return 'medications' },
        type: 'simple',
        question: 'Did any of the treatments have no effect on your baby’s symptoms?',
        formId: 'effect',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate ",
        placeholder: "Type hear ...",
        inputType: "text"
    },

    medications: {
        handleSelect: (index) => { return 'anything' },
        type: 'simple',
        question: 'Do you know if your baby has any allergies to medications?',
        formId: 'medications',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey:0,
        label:'Please specify medication allergies',
        placeholder:'... ',
        inputType:'addItem',
    },

    anything: {
        handleSelect: (index) => { return 'finish' },
        type: 'simple',
        question: 'Is there anything else we should know?',
        formId: 'anything',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        placeholder: "Type hear ...",
        inputType: "text"
    },
}

