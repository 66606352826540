import { IconButton } from "@material-ui/core"
import styled from "@emotion/styled"
import colors from "../../global/colors";

const Container = styled(IconButton)({
    fontSize: "15px",
    padding: "0.3rem",
    color: "black",
    height: "fit-content",
    borderRadius: "5px",
    boxShadow: colors.boxShadow,
    width: "fit-content"
})




export default ({onDelete,children}) => {
    return (
        <Container onClick={onDelete} >
            {children}
        </Container>
    )
}