import useApi from "../../api/httpService";
import useNotification from "../../components/notification";

export default function useProfileController() {
  const api = useApi();
  const { toastError, toastSuccess } = useNotification();
  const userId = localStorage.getItem("user_id");

  async function updateProfile(data) {
    // const formData = new FormData();
    // Object.keys(data).forEach((key) => {
    //   formData.append(key, data[key]);
    // });
    await api({
      method: "PUT",
      url: `/owners/${userId}`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        toastSuccess("Successfuly update profiled");
      })
      .catch((err) => {
        toastError("Problem to update profile");
        console.error(err);
      });
  }
  async function getProfile() {
    return api({
      method: "GET",
      url: `/owners/${userId}`,
    })
      .then((result) => {
        const owner = result.data;
        localStorage.setItem("userInfo", JSON.stringify(owner));
        return owner;
      })
      .catch((err) => {
        toastError("Problem to get profile info");
        console.error(err);
        return {};
      });
  }

  async function resetPassword(data) {
    await api({
      method: "POST",
      url: `/owners/${userId}/resetPassword`,
      data,
    })
  }

  return { updateProfile, getProfile, resetPassword };
}
