import { Fragment, useCallback, useContext, useState } from "react";
import MainFrame from "../../components/MainFrame"
import NavBar from "../../components/NavBar";
import { List, ListItemIcon, ListItemText } from "@mui/material";
import TextContainer from "../../components/Texts/TextContainer";
import colors from "../../global/colors";
import TitleIcon from '@mui/icons-material/Title';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Btn } from "../../components/Buttons/styles";
import { modalContext } from "../../components/modal/modal";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { ChangeStateModal } from "../forms/components/progressbar/modal";
import Multistepprogressbar from "../forms/components/progressbar/multistepprogressbar";
// import useRequestApi from "../../api/requests.api";

function Request() {
    // const { state } = useLocation()
    // const [activeStep, setActiveStep] = useState(state.state);
    const [activeStep, setActiveStep] = useState("");
    const { setShow, setContent } = useContext(modalContext);

    // const api = useRequestApi();

    // const setRequestState = useCallback((step) => {
    //     // api.updateRequest(state.id, { state: step })
    //     //     .then(() => { setActiveStep(step) })
    //     //     .catch(err => console.log(err));
    // }, [state])


    function handleOpenModal(type, step, stepLabel) {
        if (type === "NextStep") {
            setContent({
                title: `go to state ${stepLabel}`,
                body: <ChangeStateModal step={step} stepLabel={stepLabel}
                    GoToState={() => {
                        // setRequestState(step);
                    }
                    } />
            })
        }
        setShow(true)
    }

    const nextStep = (step, label) => {
        if (step > activeStep) {
            handleOpenModal("NextStep", step, label);
        }
    }

    return (
        <Fragment>
            <NavBar topic="Request title" >
                {activeStep < 1 &&
                    <Btn variant="contained" onClick={() => nextStep(1, "accept")}>
                        Accept
                    </Btn>
                }
            </NavBar>
            <List style={{ width: "100%", background: colors.white, padding: "1rem", borderRadius: ".5rem", maxHeight: "75vh", overflow: "auto",boxShadow:colors.boxShadow }}>

                <TextContainer margin="0 0 .5rem 0" >
                    <ListItemIcon>
                        <TitleIcon />
                    </ListItemIcon>
                    <ListItemText primary="title" style={{ fontSize: "1.1rem" }} />
                    <TextContainer textAlign="justify" fontSize="1rem" maxWidth="70%" color={colors.darkBlue}>
                        {/* {state.title} */}
                    </TextContainer>
                </TextContainer>

                <TextContainer margin="0 0 .5rem 0" >
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="to" style={{ fontSize: "1.1rem" }} />
                    <TextContainer textAlign="justify" fontSize="1rem" maxWidth="70%" color={colors.darkBlue}>
                        {/* {state.patient} */}
                    </TextContainer>
                </TextContainer>

                <TextContainer margin="0 0 .5rem 0" >
                    <ListItemIcon>
                        <CalendarMonthIcon />
                    </ListItemIcon>
                    <ListItemText primary="Date" style={{ fontSize: "1.1rem" }} />
                    <TextContainer textAlign="justify" fontSize="1rem" maxWidth="70%" color={colors.darkBlue}>
                        {/* {dayjs(state.createdAt).format('YYYY-MM-DD')} */}
                    </TextContainer>
                </TextContainer>

                <TextContainer margin="0 0 .5rem 0" >
                    <ListItemIcon>
                        <TextSnippetIcon />
                    </ListItemIcon>
                    <ListItemText primary="description" style={{ fontSize: "1.1rem" }} />
                    <TextContainer
                        textAlign="justify"
                        fontSize="1rem" maxWidth="70%"
                        color={colors.darkBlue}>
                        {/* {state.desc} */}
                    </TextContainer>
                </TextContainer>

                <Multistepprogressbar
                    steps={["IDL", "Accept", "Send"]}
                    nextStep={nextStep}
                    activeStep={activeStep}
                    skipp={false}
                    previous={false}
                    next={true}
                    reset={false} />&nbsp;
            </List>
        </Fragment >
    )
}

export default MainFrame(Request);