import { memo, useEffect, useState } from "react";
import { FormActiosn } from "../../../../global/redux/form";
import Layout from "../edit/layout";
import CommentInput from "../commentInput";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import TextContainer from "../../../../components/Texts/TextContainer";
import colors from "../../../../global/colors";

function OtherQuestio({
  question,
  formId,
  defaultValue,
  handleSelect,
  formNavigator,
  dispatch,
}) {
  const [err, setErr] = useState("");
  const [formData, setData] = useState({
    comment: "",
    ...defaultValue,
  });
  useEffect(() => {
    setData({
      comment: "",
      ...defaultValue,
    });
  }, [formId, defaultValue]);
  const saveFormData = (data) => {
    dispatch(
      FormActiosn.updateFormData({
        formId,
        formData: data,
      })
    );
  };
  const { comment = "" } = formData;
  const onGoNext = () => {
    if (!comment.length) return setErr("One selection is required!");
    setErr("");
    saveFormData(formData);
    return formNavigator(handleSelect(1));
  };
  return (
    <Layout
      firstButton={{
        text: "Previous",
        onClick: () => formNavigator(-1),
      }}
      secondButton={{
        text: "Next",
        onClick: onGoNext,
      }}
    >
      <CommentInput
        inAccordin={false}
        handleChange={(value) => {
          setData((prev) => ({
            ...prev,
            comment: value,
          }));
        }}
        title="Comment"
        label="Comment"
        value={comment}
        placeholder={question}
      />
    </Layout>
  );
}

function mapStateToProps(state, ownProps) {
  let formData = state.forms.data[ownProps.formId];
  formData = formData ? formData : {};
  return {
    ...ownProps,
    defaultValue: {
      ...ownProps.defaultValue,
      ...formData,
    },
  };
}
export default connect(mapStateToProps)(
  memo(OtherQuestio, function (prev, next) {
    return isEqual(prev, next);
  })
);
