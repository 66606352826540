export default {
  index: {
    handleSelect: (index) => {
      return "gender";
    },
    type: "simple",
    question: "Where would you like the appointment to take place?",
    formId: "index",
    answers: [
      {
        text: "inPerson",
      },
      {
        text: "phone call",
      },
    ],
  },
  gender: {
    handleSelect: (index) => {
      if (index == 0) {
        return "symptoms_exp";
      }
      return "pregnant";
    },
    type: "simple",
    question: "What is your gender?",
    formId: "gender",
    answers: [{ text: "Male" }, { text: "Female" }, { text: "Other" }],
    optionalKey: 2,
    label: "Please self-identify:",
    placeholder: "(optional)",
    inputType: "text",
    require: true,
  },
  pregnant: {
    handleSelect: (index) => {
      return "breastfeeding";
    },
    type: "simple",
    question: "Are you (or could you be) pregnant?",
    formId: "pregnant",
    answers: [{ text: "yes" }, { text: "no" }, { text: "not sure" }],
  },
  breastfeeding: {
    handleSelect: (index) => {
      return "symptoms_exp";
    },
    type: "simple",
    question: "Are you breastfeeding?",
    formId: "breastfeeding",
    answers: [{ text: "yes" }, { text: "no" }, { text: "not sure" }],
  },
  symptoms_exp: {
    handleSelect: (index) => {
      return "other_symptoms";
    },
    type: "multi",
    question: "What symptoms are you experiencing?",
    formId: "symptoms_exp",
    cancleAllIndex: 4,
    answers: [
      { text: "Sneezing" },
      { text: "An itchy nose, mouth or throat" },
      { text: "A runny or stuffy nose" },
      { text: "Itchy or watery eyes" },
      { text: "None of the above" },
    ],
  },
  other_symptoms: {
    handleSelect: (index) => {
      return "other_symptoms_2";
    },
    type: "multi",
    question: "Are there any other symptoms you are experiencing?",
    formId: "other_symptoms",
    cancleAllIndex: 4,
    answers: [
      { text: "Fever (temperature higher than 38°C)" },
      { text: "Sore throat" },
      { text: "Pain or tenderness near your eyes or cheeks" },
      { text: "Greenish or yellow coloured mucus from your nose" },
      { text: "None of the above" },
    ],
    require: true,
  },
  other_symptoms_2: {
    handleSelect: (index) => {
      return "symptoms_present";
    },
    type: "simple",
    question:
      "Do you have any other symptoms not mentioned in the last question?",
    formId: "other_symptoms_2",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
    optionalKey: 0,
    label: "Please explain",
    inputType: "text",
  },
  symptoms_present: {
    handleSelect: (index) => {
      return "triggers";
    },
    type: "simple",
    question: "Are your symptoms present in only one nostril?",
    formId: "symptoms_present",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
  },
  triggers: {
    handleSelect: (index) => {
      return "symptoms_lasted";
    },
    type: "simple",
    question: "Do you know what triggers your allergy symptoms?",
    formId: "triggers",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
    optionalKey: 1,
    label: "What are your triggers?",
    placeholder: "e.g. grass,...",
    inputType: "text",
  },
  symptoms_lasted: {
    handleSelect: (index) => {
      return "normal_activity";
    },
    type: "simple",
    question: "Have your symptoms lasted for 4 or more weeks?",
    formId: "symptoms_lasted",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
  },
  normal_activity: {
    handleSelect: (index) => {
      return "medical_conditions";
    },
    type: "simple",
    question:
      "Are your symptoms making it difficult to perform your normal activities and sleep normally?",
    formId: "normal_activity",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
  },
  medical_conditions: {
    handleSelect: (index) => {
      return "treatments";
    },
    type: "multi",
    question: "Do you have any of the following medical conditions?",
    formId: "medical_conditions",
    cancleAllIndex: 3,
    answers: [
      { text: "Asthma" },
      { text: "History of sinus infections" },
      { text: "History of ear infections" },
      { text: "None of the above" },
      { text: "I don't know" },
    ],
    require: true,
  },
  treatments: {
    handleSelect: (index) => {
      if (index == 1) return "medications";
      else return "feel_better";
    },
    type: "simple",
    question:
      "Have you tried any treatments (eg creams, medication) for your symptoms?",
    formId: "treatments",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
  },
  feel_better: {
    handleSelect: (index) => {
      return "feel_worse";
    },
    type: "simple",
    question: "Did any of the treatments make your symptoms feel better?",
    formId: "feel_better",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
    optionalKey: 0,
    label: "Please indicate",
    placeholder: "e.g. Tums",
    inputType: "text",
  },
  feel_worse: {
    handleSelect: (index) => {
      return "effect";
    },
    type: "simple",
    question: "Did any of the treatments make your symptoms feel worse?",
    formId: "feel_worse",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
    optionalKey: 0,
    label: "Please indicate which treatments made your symptoms feel worse",
    placeholder: "Type hear ...",
    inputType: "text",
  },
  effect: {
    path: "effect",
    handleSelect: (index) => {
      return "medications";
    },
    type: "simple",
    question: "Did any of the treatments have no effect on your symptoms?",
    formId: "effect",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
    optionalKey: 0,
    label: "Please indicate ",
    placeholder: "Type hear ...",
    inputType: "text",
  },
  medications: {
    handleSelect: (index) => {
      return "new_medications";
    },
    type: "simple",
    question: "Do you have any allergies to medications?",
    formId: "medications",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
    optionalKey: 0,
    label: "Please specify medication allergies",
    placeholder: "... ",
    inputType: "addItem",
  },
  new_medications: {
    handleSelect: (index) => {
      return "anything";
    },
    type: "simple",
    question: "Have you started any new medications in the past month?",
    formId: "new_medications",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
    optionalKey: 0,
    label: "Please specify",
    placeholder: "... ",
    inputType: "addItem",
  },
  anything: {
    handleSelect: (index) => {
      return "finish";
    },
    type: "simple",
    question: "Is there anything else we should know?",
    formId: "anything",
    answers: [{ text: "Yes" }, { text: "No" }, { text: "Not Sure" }],
    optionalKey: 0,
    label: "Please explain",
    placeholder: "Type hear ...",
    inputType: "text",
  },
};
