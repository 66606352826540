import { Button } from "@material-ui/core"
import colors from "../../global/colors"
import styled from "@emotion/styled"
export const MinimumButtonStyled = { width: "100px", borderRadius: "22px", height: "36px" }
export const SmallButtonStyle = { width: "100px", borderRadius: "10px", height: "44px", fontWeight: "bold", background: colors.darkBlue }
export const MediumButtonStyle = { width: "70%", borderRadius: "10px", height: "44px", fontWeight: "bold", background: colors.darkBlue }
export const FullWidthButton = {textTransform:"capitalize",fontWeight:"bold", width: "100%", borderRadius: ".6rem", padding: ".5rem 0", background: colors.darkBlue, color: colors.white,boxShadow:colors.boxShadow }
export const PositiveButton = {textTransform:"capitalize", width: "100px", borderRadius: "10px", height: "2.5rem", minWidth: "100px", background: colors.darkBlue, color: colors.white }
export const NegativeButton = {textTransform:"capitalize", width: "100px", borderRadius: "10px", height: "2.5rem", border: `1px solid ${colors.darkBlue}`, color: colors.darkBlue, marginLeft: ".5rem" }
export const CircleButton = { width: "60px", borderRadius: "50%", height: "60px" }
export const ModalCloseBtn = { fontSize: "18px", height: "27px", border: "none", cursor: "pointer", padding: "3px 7px", margin: "0" }
export const ToggleGroupStyle = { borderRadius: "50px", overflow: "hidden", border: "0.1px solid #a2a2a2", height: "36px" }
export const SelectStyle = { borderRadius: "10px", height: "44px", width: "120px", margin: "0px 0px 10px 0px" }
export const SelectStyleBig = { borderRadius: "10px", height: "44px", width: "200px", margin: "0px 0px 10px 0px" }

export const ButtonContainer = styled.div`
    display:flex;
    flex-direction:row;
    gap:1rem;
    justify-content:center;

`
export const Btn = styled(Button)({
    '&.MuiButton-root': {
        background: colors.white,
        textTransform:"capitalize",
        maxHeight:"3rem"
    }

})
