import useApi from "../../api/httpService";

export default function useTrackesController() {
  const api = useApi();
  async function getTracks(patientId, query) {
    return api({
      method: "GET",
      url: `/patients/${patientId}/tracks`,
      params: query,
    }).then((result) => {
      return result.data;
    });
  }
  async function addTrack(patientId, data) {
    await api({
      method: "POST",
      url: `/patients/${patientId}/tracks`,
      data,
    });
  }

  async function deleteTrack(trackId) {
    await api({
      method: "DELETE",
      url: `trackes/${trackId}`,
    });
  }

  async function addTrackItem(trackId, data) {
    await api({
      method: "POST",
      url: `trackes/${trackId}/values`,
      data,
    });
  }
  async function getTrackItems(trackId, query) {
    return api({
      method: "GET",
      url: `trackes/${trackId}/values`,
      params: query,
    }).then((result) => result.data);
  }

  async function editTrackItem(itemId, data) {
    await api({
      method: "PUT",
      url: `trackitems/${itemId}`,
      data,
    });
  }
  async function deleteTrackItem(itemId) {
    await api({
      method: "DELETE",
      url: `trackitems/${itemId}`,
    });
  }

  return {
    getTracks,
    addTrack,
    deleteTrack,
    addTrackItem,
    getTrackItems,
    editTrackItem,
    deleteTrackItem,
  };
}
