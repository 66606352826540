import React, { useContext } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { modalContext } from "../../components/modal/modal";
import { CustomTextField } from "../../components/TextFields/index";
import DateInput from "../../components/Input/dateInput";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import colors from "../../global/colors";
import {
  ButtonContainer,
  NegativeButton,
  PositiveButton,
} from "../../components/Buttons/styles";
import { PatientSelectionInput } from "../forms/components/patientSelection/patientSelectionInput";
import TextContainer from "../../components/Texts/TextContainer";
import useAppointmentApi from "../../api/appointment.api";

const Schema = yup.object().shape({
  patient: yup.string().required(),
  inPlace: yup.boolean().required(),
  date: yup.date().required(),
  desc: yup.string(),
});

export function AddModal({ dataLoader }) {
  const { setShow } = useContext(modalContext);
  const {
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(Schema),
  });

  const { addAppointment } = useAppointmentApi();

  return (
    <React.Fragment>
      <PatientSelectionInput
        onChange={(patient) => {
          setValue("patient", patient?._id);
        }}
        err={!!errors.patient}
        helperText={errors?.patient?.message ?? ""}
      />
      <DateInput
        label="Date"
        error={!!errors?.date}
        helperText={errors?.date ? errors.date.message : ""}
        onChange={(d) => setValue("date", d)}
      />
      <FormControl
        style={{
          display: "flex",
          width: "100%",
          marginBottom: "1rem",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: "1rem",
          paddingLeft: ".5rem",
          alignItems: "center",
        }}
      >
        <FormLabel style={{ color: colors.darkBlue }}>Type:</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          style={{ display: "flex", flexDirection: "row" }}
          onChange={(e, value) => {
            if (value === "yes") setValue("inPlace", true);
            else setValue("inPlace", false);
          }}
        >
          <FormControlLabel
            value="in person"
            control={<Radio />}
            label="In person"
          />
          <FormControlLabel value="online" control={<Radio />} label="Online" />
        </RadioGroup>
      </FormControl>
      <CustomTextField
        multiline
        minRows={3}
        maxRows={4}
        variant="outlined"
        label="description"
        error={!!errors.desc}
        helperText={errors.desc?.message}
        onChange={(e) => setValue("desc", e.target.value)}
      />
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={handleSubmit((data) => {
            addAppointment(data)
              .then(() => {
                dataLoader();
                setShow(false);
              })
              .catch((err) => {
                console.error(err);
              });
          })}
        >
          add
        </Button>
      </ButtonContainer>
    </React.Fragment>
  );
}

export function CancleModal({ dataLoader, appointmentId }) {
  const { setShow } = useContext(modalContext);
  const { deleteAppointment } = useAppointmentApi();
  return (
    <React.Fragment>
      <TextContainer textTransfron="capitalize" fontSize="1rem">
        are you sure you want to delete ?
      </TextContainer>
      &nbsp;
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          No
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={() => {
            deleteAppointment(appointmentId)
              .then(() => {
                setShow(false);
                dataLoader();
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          Cancle
        </Button>
      </ButtonContainer>
    </React.Fragment>
  );
}
