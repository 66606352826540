import styled from "@emotion/styled";
import { TableBody, TableCell, TableContainer, TableFooter, TableHead } from "@mui/material";
import TextContainer from "../Texts/TextContainer";
import { CustomNumberField } from "../TextFields";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import colors from "../../global/colors";



export const Cell = styled(TableCell)({
    "&.MuiTableCell-body": {
        fontSize: ".9rem"
    }
})
export const HeadCell = styled(TableCell)({
    "&.MuiTableCell-head": {
        fontSize: "1rem",
        color: colors.darkBlue,
        textTransform: "capitalize"
    }
})
export const CustomTableContainer = styled(TableContainer)({
    maxHeight: "50vh",
    position: "relative",
    // height: "70vh",
    width: "100%",
    overflow: "auto"
})
export const CustomTableBody = styled(TableBody)({
    width: "100%",
})

export const TableHeader = styled(TableHead)({
    position: "sticky",
    top: "0",
    background:colors.white,   
    zIndex:10, 
    width: "100%"
})



export default function Footer({ value, handleChange }) {


    return (
        <TableFooter style={{ display: "flex", justifyContent: "end", position: "sticky", bottom: 0, background: colors.lightBgGray0, width: "100%" }}>
            <TableCell style={{ padding: 8, display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "0" }}>
                <TextContainer>Number Of rows:</TextContainer>&nbsp;
                <CustomNumberField
                    onChange={(e) => {
                        handleChange({
                            size: e.target.value,
                            page: value.page
                        })
                    }}
                    style={{ maxWidth: "3rem" }}
                    variant="outlined"
                    value={value.size}
                />
            </TableCell>
            <TableCell style={{ padding: 8, display: "flex", justifyContent: "center", alignItems: 'center', borderBottom: "0" }} >
                <KeyboardArrowLeftIcon onClick={() => {
                    handleChange({
                        size: value.size,
                        page: value.page - 1
                    })
                }} />
                <CustomNumberField
                    style={{ maxWidth: "3rem" }}
                    variant="outlined"
                    value={value.page}
                    disabled={true}
                />
                <KeyboardArrowRightIcon onClick={() => {
                    handleChange({
                        size: value.size,
                        page: value.page + 1
                    })
                }} />
            </TableCell>
        </TableFooter>
    )
}