import { InputAdornment } from "@mui/material";
import { CustomTextField } from "../TextFields";
import PinIcon from "@mui/icons-material/Pin";
import formatPostalCode from "../../utils/fotmatPostalCode";
import { useEffect, useState } from "react";

export const PostalCode = ({
  label,
  onChange,
  defaultValue,
  value,
  placeholder,
  helperText,
  error,
  disabled=false,
}) => {
  const [postalCode, setPostalCode] = useState(value);
  useEffect(() => {
    setPostalCode(defaultValue);
  }, [defaultValue]);
  const handlePostalCodeChange = (value) => {
    value = value.toUpperCase();
    setPostalCode(value);
    onChange(value);
  };
  return (
    <CustomTextField
      error={error}
      helperText={helperText}
      variant="outlined"
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      value={formatPostalCode(postalCode)}
      onChange={(e) => {
        const value = formatPostalCode(e.target.value);
        if (value?.length) handlePostalCodeChange(value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <PinIcon />
          </InputAdornment>
        ),
        inputProps: {
          pattern: "[a-zA-Z0-9]*",
          maxLength: 7,
        },
      }}
    />
  );
};
