import TextContainer from "../../../../components/Texts/TextContainer";
import styled from "@emotion/styled";
import colors from "../../../../global/colors";
import { memo, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { CustomTextField } from "../../../../components/TextFields";
import { Box } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { FormActiosn } from "../../../../global/redux/form";
import AddItemInput from "./addItemInput";
import CommentInput from "../commentInput";
import Layout from "../edit/layout";
import { isEqual } from "lodash";

const QuestionBox = styled(Box)({
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
  textAlign: "center",
  backdropFilter: "blur(10px)",
  zIndex: 2,
  maxHeight: "65vh",
  overflow: "auto",
  height: "fit-content",
  borderRadius: ".5rem",
  boxShadow: colors.mainShadow,
});
const AnsawerButton = styled(TextContainer)({
  background: colors.lightBlue,
  color: colors.primary,
  padding: "2% 3.5%",
  alignItems: "center",
  cursor: "pointer",
  width: "30%",
  borderRadius: ".5rem",
  boxShadow: colors.boxShadow,
  textTransform: "capitalize",
  transition: "all .2s",
  "&:hover": {
    backgroundColor: colors.lightGray,
    transform: "scale(1.03) translateY(1px)",
  },
});
function SimpleQuestion({
  question,
  answers,
  optionalKey,
  formId,
  defaultValue,
  handleSelect,
  label,
  placeholder,
  inputType,
  formNavigator,

  dispatch,
}) {
  const [err, setErr] = useState("");
  const [formData, setData] = useState({
    answer: -1,
    comment: "",
    optional: "",
    ...defaultValue,
  });
  useEffect(() => {
    setData({
      answer: -1,
      comment: "",
      optional: "",
      ...defaultValue,
    });
    setErr("");
  }, [defaultValue, formId]);
  const onChange = (key, value) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const setFormData = useCallback(
    (data) => {
      dispatch(
        FormActiosn.updateFormData({
          formId,
          formData: data,
        })
      );
    },
    [formId, dispatch]
  );

  const handleOnClick = (index) => {
    if (answers[index]?.invalid) {
      return setErr(answers[index].message);
    }
    onChange("answer", index);
    if (index !== optionalKey) {
      setFormData({ ...formData, answer: index });
      formNavigator(handleSelect(index));
    }
    setErr("");
  };

  const onGoNext = () => {
    if (formData.answer === -1) return setErr("One selection is required!");
    if (answers[formData.answer].invalid)
      return setErr(answers[formData.answer].message);
    setFormData(formData);
    formNavigator(handleSelect(formData.index));
  };

  return (
    <Layout
      firstButton={{
        text: "Previous",
        onClick: () => formNavigator(-1),
      }}
      secondButton={{
        text: "Next",
        onClick: onGoNext,
      }}
    >
      <QuestionBox>
        <TextContainer
          style={{
            position: "sticky",
            top: 0,
            textAlign: "center",
            width: "100%",
            justifyContent: "center",
            marginBottom: "1rem",
            background: colors.white,
          }}
        >
          {question}
        </TextContainer>
        {err !== "" && (
          <TextContainer
            style={{
              fontSize: "1rem",
              textAlign: "center",
              width: "100%",
              justifyContent: "center",
              padding: ".5rem 0",
              color: "red",
            }}
          >
            <ReportProblemIcon
              fontSize="1rem"
              style={{ marginRight: ".5rem" }}
            />
            {err}
          </TextContainer>
        )}
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: "1rem",
            margin: "2rem 0",
          }}
        >
          {answers.map((item, index) => {
            return (
              <AnsawerButton
                key={index}
                onClick={() => {
                  handleOnClick(index);
                }}
                style={{
                  opacity: formData.answer === index ? "50%" : 1,
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: ".9rem",
                }}
              >
                {item.text}
              </AnsawerButton>
            );
          })}
        </Box>
        {formData.answer === optionalKey &&
          (inputType === "text" ? (
            <CustomTextField
              defaultValue={formData.optional}
              label={label}
              placeholder={placeholder}
              InputLabelProps={{ shrink: true }}
              type={inputType}
              onChange={(e) => onChange("optional", e.target.value)}
              variant="outlined"
            />
          ) : (
            <AddItemInput
              label={label}
              placeholder={placeholder}
              defaultValue={formData.optional}
              onChange={(value) => onChange("optional", value)}
            />
          ))}
        <CommentInput
          handleChange={(value) => onChange("comment", value)}
          title="Comment"
          label="Description"
          value={formData.comment}
          placeholder="Add Your Description Here"
        />
      </QuestionBox>
    </Layout>
  );
}
function mapStateToProps(state, ownProps) {
  let formData = state.forms.data[ownProps.formId];
  formData = formData ? formData : {};

  return {
    ...ownProps,
    defaultValue: {
      ...ownProps.defaultValue,
      ...formData,
    },
  };
}
export default connect(mapStateToProps)(
  memo(SimpleQuestion, function (prev, next) {
    return isEqual(prev, next);
  })
);
