import { useNavigate } from "react-router-dom";
import useApi from "./httpService";

export default function useFormApi() {
  const api = useApi();
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  // if (!user_id) navigate("/login");

  const getPatientForms = async (patientId, query) => {
    const result = await api({
      method: "GET",
      url: `/patients/${patientId}/forms`,
      params: { ...query, ownerId: user_id },
    });
    return result.data;
  };

  const getOwnerForms = async (query) => {
    const result = await api({
      method: "GET",
      url: `/owners/${user_id}/forms`,
      params: query,
    });
    return result.data;
  };

  const getForm = async (formId) => {
    const result = await api({
      method: "GET",
      url: `/forms/${formId}`,
    });
    return result.data;
  };

  const getFormFollowups = async (formId, query) => {
    const result = await api({
      method: "GET",
      params: query,
      url: `/forms/${formId}/followups`,
    });
    return result.data;
  };
  const addFollowup = async (formId, data) => {
    await api({
      method: "POST",
      url: `/forms/${formId}/followups`,
      data: data,
    });
  };

  const getFollowup = async (followupId) => {
    const result = await api({
      method: "GET",
      url: `/followups/${followupId}`,
    });
    return result.data;
  };

  const editFollowup = async (followupId, data) => {
    await api({
      method: "PUT",
      url: `/followups/${followupId}`,
      data,
    });
  };

  const createForm = async (patientId, form) => {
    await api({
      method: "POST",
      url: `patients/${patientId}/forms`,
      data: form,
    });
  };

  const getOwnerFollowup = async (query) => {
    const result = await api({
      method: "GET",
      url: `/owners/${user_id}/followups`,
      params: query,
    });
    return result.data;
  };
  return {
    createForm,
    editFollowup,
    getFollowup,
    getPatientForms,
    getOwnerForms,
    getForm,
    getFormFollowups,
    addFollowup,
    getOwnerFollowup,
  };
}
