import { Box } from "@material-ui/core";
import TextContainer from "../../../../components/Texts/TextContainer";
import colors from "../../../../global/colors";
import styled from "@emotion/styled";
const FinalQuestionWrapper = styled(Box)({
  border: `4px solid ${colors.lightBlue}`,
  borderRadius: ".5rem",
  padding: "1rem",
  background: "#fff",
  margin: "1rem",
  boxShadow: colors.boxShadow,
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});
const Card = styled(Box)({
    boxShadow: colors.boxShadow,
    padding: "1rem",
    display: "flex",
    width:"100%",
    maxHeight:"30vh",
    overflow:"auto",
    flexDirection: "column",
    justifyContent: "flex-start",
    bgcolor: colors.white,
    margin: "0 1rem",
    borderRadius: ".5rem",
  });
const CustomBox = styled(Box)({
  display: "flex",
  gap: "1rem",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});
export default function FinalQuestions({ metadata }) {
  const {
    referredToDoctor = {
      answer: undefined,
      comment: "",
    },
    medicationsPrescribed = {
      answer: undefined,
      comment: "",
    },
    medications = [],
  } = metadata || {};

  return (
    <FinalQuestionWrapper>
      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "1rem",
            marginBottom: ".5rem",
            width: "100%",
          }}
        >
          <TextContainer textTransform="capitalize" fontSize="1rem">
            1. Has the patient been referred to the doctor?
          </TextContainer>
          <TextContainer textTransform="capitalize" color={colors.darkBlue}>
            {referredToDoctor.answer}
          </TextContainer>
        </div>
        <TextContainer justifyContent="left" color="#555" fontSize="1rem">
          comment: {referredToDoctor.comment}
        </TextContainer>
      </Box>

      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "1rem",
            marginBottom: ".5rem",
            width: "100%",
          }}
        >
          <TextContainer textTransform="capitalize" fontSize="1rem">
            2. Have any medications been prescribed?
          </TextContainer>
          <TextContainer textTransform="capitalize" color={colors.darkBlue}>
            {" "}
            {medicationsPrescribed.answer}
          </TextContainer>
        </div>
        <TextContainer justifyContent="left" color="#555" fontSize="1rem">
          comment: {medicationsPrescribed.comment}
        </TextContainer>
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: "1rem",
          width: "100%",
        }}
      >
        <TextContainer
          textTransform="capitalize"
          justifyContent="left"
          fontSize="1rem"
        >
          3. medications
        </TextContainer>
        <CustomBox>
          {medications.map((med, index) => {
            console.log(med);
            return (
                <Card key={index}>
                <TextContainer
                  width="100%"
                  justifyContent="flex-start"
                  fontSize=".8rem"
                  textTransform="capitalize"
                  color="#333"
                >
                  {med?.inq1}
                </TextContainer>
                <TextContainer
                  width="100%"
                  justifyContent="flex-start"
                  fontSize=".8rem"
                  color={colors.darkBlue}
                >
                  {med?.inq2}
                </TextContainer>
                <TextContainer
                  width="100%"
                  justifyContent="flex-start"
                  fontSize=".8rem"
                  color="gray"
                >
                  {med?.inq3}
                </TextContainer>
              </Card>
            );
          })}
        </CustomBox>
      </Box>
    </FinalQuestionWrapper>
  );
}
