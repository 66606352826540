import TextContainer from "../../components/Texts/TextContainer";
import { Box, Button, InputAdornment, useMediaQuery } from "@mui/material";
import { CustomTextField } from "../../components/TextFields";
import { TOPIC } from "../../components/Texts/styles";
import colors from "../../global/colors";
import { FullWidthButton } from "../../components/Buttons/styles";
import useLoginController from "./loginController";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PasswordInput } from "../../components/Input/passwordInput";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import { FormBox } from "../../global/table";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export default function LoginPage() {
  const {
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:868px)");
  const controller = useLoginController();
  const handlePassChange = (pass) => {
    setValue("password", pass);
  };
  return (
    <div
      style={{
        background: colors.lightBgGray0,
        height: "100vh",
        width: "100%",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <FormBox isSmallScreen={isSmallScreen}>
        <Box sx={{ width: "100%" }}>
          <img
            alt="logo"
            src={require("../../assets/images/logo.png")}
            width="20%"
            height="20%"
            style={{ minWidth: "2rem", minHeight: "2.5rem" }}
          />
          <TextContainer {...TOPIC}>RxPharma</TextContainer>
        </Box>
        <TextContainer fontSize=".9rem" color="#555">
          Hello! welcome back to RxPharma.
        </TextContainer>
        <CustomTextField
          label="Email"
          type="email"
          variant="outlined"
          error={!!errors.email}
          onChange={(e) => setValue("email", e.target.value)}
          helperText={errors.email?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MailIcon />
              </InputAdornment>
            ),
          }}
        />
        <PasswordInput
          label="Password"
          error={!!errors.password}
          onChange={handlePassChange}
          helperText={errors.password?.message}
        />
        <TextContainer
          fontSize=".9rem"
          cursor="pointer"
          margin="-1rem 0 0 1rem"
          width="100%"
          justifyContent="left"
          color={colors.darkBlue}
          onClick={() => navigate("/login/forget-password")}
        >
          Forget password?
        </TextContainer>
        <Button
          variant="contained"
          onClick={handleSubmit((data) => {
            controller.login(data.email, data.password);
          })}
          style={FullWidthButton}
        >
          login
        </Button>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            gap: ".5rem",
          }}
        >
          <TextContainer
            fontSize=".9rem"
            margin="0 0 0 1rem"
            justifyContent="left"
          >
            Don't have an account yet?
          </TextContainer>
          <TextContainer
            fontSize=".85rem"
            cursor="pointer"
            textDecoration="underline"
            onClick={() => navigate("/sign-up")}
            color={colors.darkBlue}
          >
            Create one now.
          </TextContainer>
        </div>
      </FormBox>
    </div>
  );
}
