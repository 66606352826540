import React, { Fragment, useContext, useEffect, useState } from "react";
import { CustomTextField } from "../TextFields";
import SearchIcon from "@mui/icons-material/Search";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import {
  ButtonContainer,
  NegativeButton,
  PositiveButton,
} from "../Buttons/styles";
import { modalContext } from "../modal/modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import Data from "../../constants/ailments.db.json";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box } from "@mui/material";
import TextContainer from "../Texts/TextContainer";
import colors from "../../global/colors";
import { styled } from "@mui/styles";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FullScreenModal from "../modal/fullScreenModal";
import { Ailments } from "./data/ailments";
import truncateText from "../../utils/truncatedText";

const Schema = yup.object().shape({
  inq1: yup.string().required(),
  inq2: yup.string(),
  inq3: yup.string().required(),
});
const CustomPaper = styled(Paper)({
  "& .MuiPaper-elevation1": { boxShadow: "none" },
});

const Header = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  background: colors.lightBgGray1,
  zIndex: 10,
  padding: ".5rem 0",
});
export const Inqs = ({ onSubmit }) => {
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(Schema) });
  const { setShow } = useContext(modalContext);
  return (
    <React.Fragment>
      <CustomTextField
        variant="outlined"
        label="Drug"
        focused={watch("inq1") ? true : false}
        placeholder="Example: Acetaminophen"
        value={watch("inq1")}
        onChange={(e) => {
          setValue("inq1", e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <FullScreenModal
                title="Ailments Drugs Table"
                error={!!errors.inq1}
                helperText={errors?.inq1?.message}
                button={
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                }
              >
                <AccordionTable
                  onSelect={(inq1, inq3) => {
                    setValue("inq1", inq1);
                    setValue("inq3", inq3);
                  }}
                />
              </FullScreenModal>
            </InputAdornment>
          ),
        }}
        error={!!errors.inq1}
        helperText={errors?.inq1?.message}
      />

      <CustomTextField
        variant="outlined"
        label="Additional medication"
        onChange={(e) => setValue("inq2", e.target.value)}
        error={!!errors.inq2}
        helperText={errors?.inq2?.message}
      />
      <CustomTextField
        variant="outlined"
        multiline
        maxRows={3}
        label="Medication Instruction"
        onChange={(e) => setValue("inq3", e.target.value)}
        error={!!errors.inq3}
        helperText={errors?.inq3?.message}
        value={watch("inq3")}
      />
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={handleSubmit((data) => {
            setShow(false);
            onSubmit(data);
          })}
        >
          add
        </Button>
      </ButtonContainer>
    </React.Fragment>
  );
};
const AccordionTable = ({ onSelect, handleClose }) => {
  const [search, setSearch] = useState();
  const [hover, setHover] = useState([-1, -1]);
  const [ctrl, setCtrl] = useState(false);
  const [tmp, setTmp] = useState("");
  useEffect(() => {}, [search]);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === "Control") {
        setTmp("");
        setCtrl(true);
      }
    }
    async function handleKeyUp(e) {
      if (e.key === "Control") {
        setTmp("");
        setCtrl(false);
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  async function handleCopy(text) {
    if (!ctrl) {
      await navigator.clipboard.writeText(text);
      return;
    }
    await navigator.clipboard.writeText(tmp + " " + text);
    setTmp(tmp + " " + text);
  }
  const {
    drugTables: { headers = [], drugClasses = {} } = {
      headers: [],
      drugClasses: {},
    },
  } = Data[search] || {};
  return (
    <Fragment>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        style={{ width: "50%" }}
        options={Ailments.map((option) => option)}
        filterOptions={(options, state) => {
          return options.filter((option) =>
            option
              .toLocaleLowerCase()
              .startsWith(state.inputValue.toLocaleLowerCase())
          );
        }}
        onChange={(e, value) => {
          setSearch(value);
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            variant="outlined"
            label="Search by minor ailments or drug"
            placeholder="Example: Acetaminophen"
            InputProps={{
              ...params.InputProps,
              type: "search",
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    cursor: "pointer",
                    transition: "all .2s",
                    ":hover": { transform: "scale(1.1)" },
                  }}
                  onClick={() => console.log("search")}
                ></InputAdornment>
              ),
            }}
          />
        )}
      />
      {search ? (
        <TableContainer component={CustomPaper}>
          <Table aria-label="Accordion Table">
            <Header>
              {headers.map((header, index) => (
                <TextContainer key={index} fontSize="1rem" padding="0 1rem">
                  {header}
                </TextContainer>
              ))}
            </Header>
            <TableBody>
              {Object.keys(drugClasses).map((drugClass, index) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${index}-content`}
                    id={`panel-${index}-header`}
                  >
                    <Typography
                      style={{
                        color: colors.darkBlue,
                        textTransform: "capitalize",
                        fontSize: "1rem",
                      }}
                    >
                      {drugClass}
                    </Typography>
                  </AccordionSummary>
                  {drugClasses[drugClass].map((columns, row) => {
                    return (
                      <AccordionDetails
                        sx={{ border: `1px solid ${colors.lightBgGray0}` }}
                        onClick={() => {
                          onSelect(columns[headers[0]], columns[headers[1]]);
                          handleClose();
                        }}
                      >
                        <TableRow
                          sx={{
                            cursor: "pointer",
                            ":hover": { bgcolor: colors.lightBgGray1 },
                          }}
                        >
                          {Object.keys(columns)?.map((key, indx) => (
                            <Tooltip title={columns[key]}>
                              <TableCell
                                sx={{
                                  width: "auto",
                                  border: 0,
                                  position: "relative",
                                  padding: ".1rem",
                                }}
                                onMouseEnter={() => setHover([row, indx])}
                                onMouseLeave={() => setHover([row, indx])}
                              >
                                {hover[0] === row && hover[1] === indx && (
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCopy(columns[key]);
                                    }}
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      bgcolor: colors.white,
                                      ":hover": { bgcolor: colors.lightBlue },
                                    }}
                                  >
                                    <ContentCopyIcon
                                      style={{ fontSize: "1rem" }}
                                    />
                                  </IconButton>
                                )}
                                {truncateText(columns[key], 100)}
                              </TableCell>
                            </Tooltip>
                          ))}
                        </TableRow>
                      </AccordionDetails>
                    );
                  })}
                </Accordion>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default AccordionTable;
