import TextContainer from "../../components/Texts/TextContainer";
import { Box, Button, useMediaQuery } from "@mui/material";
import { TOPIC } from "../../components/Texts/styles";
import colors from "../../global/colors";
import { FullWidthButton } from "../../components/Buttons/styles";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PasswordInput } from "../../components/Input/passwordInput";
import { useLocation, useNavigate } from "react-router-dom";
import { FormBox } from "../../global/table";
import useLoginController from "./loginController";

const schema = yup.object().shape({
  password: yup.string().required(),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Password must match")
    .required(),
});

export default function PasswordSettings() {
  const {
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const isSmallScreen = useMediaQuery("(max-width:868px)");
  const { state } = useLocation();
  console.log(state);
  const { signup, resetPassword } = useLoginController();
  return (
    <div
      style={{
        background: colors.lightBgGray0,
        height: "100vh",
        width: "100%",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <FormBox isSmallScreen={isSmallScreen}>
        <Box sx={{ width: "100%" }}>
          <img
            alt="logo"
            src={require("../../assets/images/logo.png")}
            width="20%"
            height="20%"
            style={{ minWidth: "2rem", minHeight: "2.5rem" }}
          />
          <TextContainer {...TOPIC}>PharmaCanada</TextContainer>
        </Box>
        <TextContainer fontSize=".9rem" color="#555">
          choose a safe password that remember easily.
        </TextContainer>
        <PasswordInput
          label="Password"
          error={!!errors.password}
          onChange={(e) => setValue("password", e)}
          helperText={errors.password?.message}
        />
        <PasswordInput
          label="Repeat Password"
          error={!!errors.repeatPassword}
          onChange={(e) => setValue("repeatPassword", e)}
          helperText={errors.repeatPassword?.message}
        />
        <Button
          variant="contained"
          onClick={handleSubmit(
            (data) => {
              if (!!state?.resetPassword)
                return resetPassword(state.email, data.password);
              return signup({ ...data, email: state.email });
            },
            (err) => {
              console.log(err);
            }
          )}
          style={FullWidthButton}
        >
          Confirm
        </Button>
      </FormBox>
    </div>
  );
}
