import { modalContext } from "../../components/modal/modal";
import { Fragment, useContext, useState } from "react";
import { CustomTextField } from "../../components/TextFields";
import {
  ButtonContainer,
  NegativeButton,
  PositiveButton,
} from "../../components/Buttons/styles";
import { Button, Autocomplete } from "@mui/material";
import TextContainer from "../../components/Texts/TextContainer";
import styled from "@emotion/styled";
import { PatientSelectionInput } from "../forms/components/patientSelection/patientSelectionInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export function AddModal({ defualtValue, onSubmit }) {
  const { setShow } = useContext(modalContext);
  const [value, setValue] = useState(defualtValue);
  const [err, setErr] = useState("");
  const editValue = () => {
    if (value === "") return setErr("value is required");
    onSubmit(value);
  };
  return (
    <Container>
      <CustomTextField
        label="Value"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        error={err !== ""}
        helperText={err}
      />
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          Cancel
        </Button>
        &nbsp;
        <Button variant="contained" onClick={editValue} style={PositiveButton}>
          Add
        </Button>
      </ButtonContainer>
    </Container>
  );
}
export function DeleteModal({ onSubmit }) {
  const { setShow } = useContext(modalContext);
  return (
    <Container>
      <TextContainer> Are you sure ?</TextContainer>
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          Cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          onClick={onSubmit}
          style={PositiveButton}
        >
          Delete
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export function DeleteTrackModal({ onDelete }) {
  const { setShow } = useContext(modalContext);
  return (
    <Container>
      <TextContainer> Are you sure you want to delete this track?</TextContainer>
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          Cancel
        </Button>
        &nbsp;
        <Button variant="contained" onClick={onDelete} style={PositiveButton}>
          Delete
        </Button>
      </ButtonContainer>
    </Container>
  );
}

const CustomAutoComplete = styled(Autocomplete)({
  "&&.css-1hsevg8-MuiAutocomplete-listbox .MuiAutocomplete-option": {
    fontSize: ".9rem",
  },
});
const TrackSchema = yup.object().shape({
  title: yup.string().required(),
  subject: yup.string().required(),
  desc: yup.string().required(),
});
export function AddTrackModal({ onAdd }) {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(TrackSchema) });
  const { setShow } = useContext(modalContext);

  return (
    <Fragment>
      <CustomTextField
        type="text"
        label="Illness"
        variant="outlined"
        onChange={(e) => {
          setValue("title", e.target.value);
        }}
        error={!!errors?.title}
        helperText={errors?.title?.message}
      />
      <CustomAutoComplete
        disablePortal
        fullWidth
        id="combo-box-demo"
        options={["Blood Pressure", "Blood Suger", "Test"]}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <CustomTextField
            error={!!errors?.subject}
            helperText={errors?.subject?.message}
            {...params}
            fullWidth
            variant="outlined"
            label="Subject"
          />
        )}
        onChange={(e, value) => {
          if (value == null) return setValue("subject", "");
          setValue("subject", value);
        }}
      />
      <CustomTextField
        multiline
        minRows={3}
        maxRows={4}
        variant="outlined"
        label="description"
        error={!!errors.desc}
        helperText={errors.desc?.message}
        onChange={(e) => setValue("desc", e.target.value)}
      />
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={handleSubmit(onAdd)}
        >
          add
        </Button>
      </ButtonContainer>
    </Fragment>
  );
}
