import React, { useState, useEffect, createContext, useContext } from "react";
import Alert from "@mui/material/Alert";
import { Stack } from "@mui/material";

const NotificationContext = createContext();

function Notification({ severity, text, duration, onClick, onClose }) {
  const [showAlert, setShowAlert] = useState(true);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    let timeoutId;
    if (showAlert) {
      // Show alert and set timeout to hide it
      setOpacity(1);
      timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, duration);
    } else {
      // Hide alert gradually
      let opacityValue = 1;
      const intervalId = setInterval(() => {
        opacityValue -= 0.1;
        setOpacity(opacityValue);
        if (opacityValue <= 0) {
          clearInterval(intervalId);
          onClose();
        }
      }, 50);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showAlert, duration]);

  const handleClose = () => {
    setShowAlert(false);
    onClose();
  };

  return (
    <>
      {showAlert && (
        <Alert
          color={severity}
          onClose={handleClose}
          onClick={onClick}
          severity={severity}
          style={{
            opacity: opacity,
            transition: "opacity 0.5s ease-in-out",
            margin: ".5rem 0",
            cursor: "pointer",
            zIndex: "1000",
          }}
        >
          {text}
        </Alert>
      )}
    </>
  );
}

const Style = {
  width: "fit-content",
  position: "absolute",
  zIndex: 100,
};

export function NotificationProvider({ children }) {
  //notifications object {id:notif}
  const [notifications, setNotifications] = useState({});

  function onClose(id) {
    if (id in notifications) {
      let tmp = { ...notifications };
      delete tmp[id];
      setNotifications(tmp);
    }
  }

  /**
   *
   * @param {{severity:String,text:String,duration:Number,onClick:Function}} notification
   */
  function addNotification(notification) {
    let id = Date.now();
    while (id in notifications) id += 10;
    setNotifications((prev) => {
      return { ...prev, [id]: { ...notification } };
    });
  }

  function toastError(text, onClick) {
    addNotification({
      severity: "error",
      text,
      duration: 2000,
      onClick,
    });
  }
  function toastSuccess(text, onClick) {
    addNotification({
      severity: "success",
      duration: 2000,
      text,
      onClick,
    });
  }

  return (
    <NotificationContext.Provider
      value={{ addNotification, toastError, toastSuccess }}
    >
      <Stack sx={Style} spacing={2}>
        {Object.keys(notifications).map((id) => {
          const notification = notifications[id];
          return (
            <Notification
              key={id}
              severity={notification.severity}
              text={notification.text}
              duration={notification.duration}
              onClick={notification.onClick}
              onClose={() => onClose(id)}
            />
          );
        })}
      </Stack>
      {children}
    </NotificationContext.Provider>
  );
}

export default function useNotification() {
  return useContext(NotificationContext);
}
