import ActiveFilterBtn from "./ActiveFilterBtn";

import CloseIcon from "@mui/icons-material/Close";

const render = (filter, onDelete) => {
  return (
    <ActiveFilterBtn onDelete={onDelete}>
      <CloseIcon style={{ fontSize: "15px" }} />
      &nbsp; {`${filter?.label}`}
    </ActiveFilterBtn>
  );
};
const createFilterQuery = (query, filter) => {
  if (!query[filter.collection]) {
    query[filter.collection] = {
      value: false,
      toMongoo: function () {
        return this.value;
      },
      toSQL: function () {
        return this.value;
      },
    };
  }
  query[filter.collection].value = filter.value;
};
export default {
  modal: null,
  single: true,
  render,
  createFilterQuery,
};
