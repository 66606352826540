export default function formatPhoneNumber(phone) {
  if (!phone) return "";
  if (phone.length <= 3) {
    return phone;
  } else if (phone.length <= 6) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
  } else if (phone.length <= 9) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
  } else {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
  }
}
