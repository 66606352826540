import Footer, {
  CustomTableBody,
  CustomTableContainer,
  TableHeader,
} from "../../components/table";
import { TableCell, TableRow, Paper } from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import Table, { Row } from "../../global/table";
import Filter from "../../components/filter/filter";
import Navbar from "../../components/NavBar";
import MainFrame from "../../components/MainFrame";
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import useFormApi from "../../api/forms.api";
import dayjs from "dayjs";
import { modalContext } from "../../components/modal/modal";
import { DeleteFollowUpModal } from "./modal";

const filters = [
  { label: "Patient", type: "name", collection: "patient" },
  { label: "Today", type: "boolean", collection: "today" }, // TODOO
];
function FollowUps() {
  const [query, setQuery] = useState({
    page: 0,
    size: 20,
  });
  const [followups, setFollowups] = useState([]);
  const navigate = useNavigate();
  const { setContent, setShow } = useContext(modalContext);
  const api = useFormApi();
  const loadFollowUps = async (query = {}) => {
    api
      .getOwnerFollowup(query)
      .then((results) => {
        setFollowups(results);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // //first time
  // useEffect(() => {
  //   loadFollowUps();
  // }, []);

  useEffect(() => {
    loadFollowUps(query);
  }, [query]);
  const openModal = (type,id) => {
    setContent({
      title: "delete followUp",
      body: <DeleteFollowUpModal
        onDelete={() => {
          //api
          setShow(false);
        }} />
    })
    setShow(true)
  }
  return (
    <>
      <Navbar topic="Followups"></Navbar>
      <Filter
        convertor={"toMongoo"}
        filters={filters}
        dataLoader={(q) =>
          setQuery((prev) => ({
            page: prev.page,
            size: prev.size,
            ...q,
          }))
        }
      />
      <CustomTableContainer component={Paper} style={{ maxHeight: "70vh" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHeader>
            <TableRow>
              <TableCell style={{ textTransform: "capitalize" }} width="25%">
                Title
              </TableCell>
              <TableCell style={{ textTransform: "capitalize" }} width="25%">
                Patient
              </TableCell>
              <TableCell style={{ textTransform: "capitalize" }} width="25%">
                Date
              </TableCell>
              <TableCell style={{ textTransform: "capitalize" }} width="5%">
                Action
              </TableCell>
            </TableRow>
          </TableHeader>
          <CustomTableBody>
            {followups?.map((followup, index) => {
              const pName = `${followup.patient.firstName} ${followup.patient.lastName}`;
              return (
                <Row
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/followup/${followup._id}`, {
                      state: {
                        followup: followup,
                        patient: {
                          userName: pName,
                          _id: followup.patient._id,
                        },
                      },
                    });
                  }}
                >
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {followup.title}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {pName}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {dayjs(followup.createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell onClick={(e) => {
                    e.stopPropagation();
                    openModal("delete", followup._id);
                  }}>
                    <DeleteIcon />
                  </TableCell>
                </Row>
              );
            })}
          </CustomTableBody>
        </Table>
        <Footer
          value={query}
          handleChange={(e) => {
            setQuery((prev) => ({
              ...prev,
              ...e,
            }));
          }}
        />
      </CustomTableContainer>
    </>
  );
}
export default MainFrame(FollowUps, true);
