import { configureStore } from "@reduxjs/toolkit";

import mainFrame from "./mainFrame";
import formReducer from "./form";
import notifReducer from "./notif";
import algReducer from "./alg";
const store = configureStore({
  reducer: {
    mainFrame,
    forms: formReducer,
    notif: notifReducer,
    alg: algReducer,
  },
});

export default store;
