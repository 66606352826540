import MainFrame from "../../components/MainFrame";
import {
  Box,
  InputAdornment,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import TextContainer from "../../components/Texts/TextContainer";
import SickIcon from "@mui/icons-material/Sick";
import colors from "../../global/colors";
import React, { useState } from "react";
import { useEffect } from "react";
import { CalendarIcon } from "@mui/x-date-pickers";
import NavBar from "../../components/NavBar";
import { Btn } from "../../components/Buttons/styles";
import NotesIcon from "@mui/icons-material/Notes";
import EditIcon from "@mui/icons-material/Edit";
import { CustomTextField } from "../../components/TextFields";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import { useLocation } from "react-router-dom";
import useAppointmentApi from "../../api/appointment.api";
import dayjs from "dayjs";

function Appointment() {
  const [comment, setComment] = useState();
  const [editable, setEditable] = useState(false);
  const {
    state: {
      patient = undefined,
      _id = "",
      date = "",
      inPlace = false,
      desc = "",
      comment: defaultComment = "",
    },
  } = useLocation();
  const { appointmentComment } = useAppointmentApi();
  return (
    <React.Fragment>
      <NavBar topic={`${patient?.firstName} ${patient?.lastName}`} />
      <List
        style={{
          width: "100%",
          background: colors.white,
          padding: "1rem",
          borderRadius: ".5rem",
        }}
      >
        <TextContainer>
          <ListItemIcon>
            <SickIcon />
          </ListItemIcon>
          <ListItemText primary="patient:" style={{ fontSize: "1rem" }} />
          &nbsp;
          <TextContainer fontSize="1rem" color={colors.darkBlue}>
            {`${patient.firstName} ${patient.lastName}`}
          </TextContainer>
        </TextContainer>

        <TextContainer>
          <ListItemIcon>
            <OtherHousesIcon />
          </ListItemIcon>
          <ListItemText primary="inplace:" style={{ fontSize: "1rem" }} />
          &nbsp;
          <TextContainer fontSize="1rem" color={colors.darkBlue}>
            {inPlace ? "Yess" : "No"}
          </TextContainer>
        </TextContainer>

        <TextContainer>
          <ListItemIcon>
            <CalendarIcon />
          </ListItemIcon>
          <ListItemText primary="date:" style={{ fontSize: "1rem" }} />
          &nbsp;
          <TextContainer fontSize="1rem" color={colors.darkBlue}>
            {dayjs(date).format("YYYY-MMM-DD:HH-mm")}
          </TextContainer>
        </TextContainer>

        <TextContainer>
          <ListItemIcon>
            <NotesIcon />
          </ListItemIcon>
          <ListItemText primary="description:" style={{ fontSize: "1rem" }} />
          &nbsp;
          <TextContainer
            style={{ maxHeight: "200px", maxWidth: "60%" }}
            overflow="auto"
            fontSize="1rem"
            color={colors.darkBlue}
          >
            {desc}
          </TextContainer>
        </TextContainer>
      </List>
      &nbsp;
      <CustomTextField
        disabled={!editable}
        label="comment"
        multiline
        minRows={10}
        maxRows={20}
        variant="outlined"
        value={comment}
        defaultValue={defaultComment}
        onChange={(e) => setComment(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              style={{ position: "absolute", right: 0, bottom: "15%" }}
            >
              <Btn
                variant="contained"
                onClick={() => {
                  if (editable) {
                    appointmentComment(_id, { comment })
                      .then(() => {
                        setEditable(false);
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  } else {
                    setEditable(true);
                  }
                }}
              >
                {editable ? "save changes" : <EditIcon />}
              </Btn>
            </InputAdornment>
          ),
        }}
      />
    </React.Fragment>
  );
}

export default MainFrame(Appointment, true);
