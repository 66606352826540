import { Box, IconButton } from "@mui/material";
import TextContainer from "../Texts/TextContainer";
import { TOPIC } from "../Texts/styles";
import styled from "@emotion/styled";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate } from "react-router-dom";
const NavbarContainer = styled.div`
  top: 0;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;
const blackList = [
  "/Patients",
  "/forms",
  "/followUps",
  "/requests",
  "/profile",
  "/tracks",
  "/appointments",
];
export default function Navbar({ topic, children, onGoBack }) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  const show = blackList.indexOf(pathName) === -1;

  return (
    <NavbarContainer>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        {show && (
          <IconButton
            onClick={() => {
              if (onGoBack) onGoBack();
              else navigate(-1);
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        )}
        <TextContainer {...TOPIC} style={{ textAlign: "left" }}>
          {topic}
        </TextContainer>
      </Box>
      {children}
    </NavbarContainer>
  );
}
