import useApi from "./httpService";



export default function useIllnessApi() {

    const api = useApi();


    /**
     * 
     * @param {number} patientId 
     * @param {Record<string,any>} query 
     */
    const getPatientIllnesses = async (patientId) => {
        const result = await api({
            method: "GET",
            url: `/patients/${patientId}/illnesses`
        });
        return result.data;
    }


    /**
     * 
     * @param {number} patientId 
     * @param {string} name 
     * @param {string} description 
     */
    const addIllnessToPatient = async (patientId, form) => {
        await api({
            method: "POST",
            url: `/patients/${patientId}/illnesses`,
            data: form
        });
    }

    const editIllness = async (illnessId, form) => {
        await api({
            method: "PUT",
            url: `/illnesses/${illnessId}`,
            data: form
        })
    }


    const deleteIllness = async (illnessId) => {
        await api({
            method: "DELETE",
            url: `/illnesses/${illnessId}`,
        })
    }


    return { getPatientIllnesses, addIllnessToPatient, deleteIllness,editIllness }

}