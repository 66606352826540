
export default function SimpleQuestion(
    {
        question,
        answers,
        formData,
    }
) {
    return (
        <div
            style={{
                padding: ".5rem 1rem",
                margin:".5rem 0",
                width: "100%",
                borderBottom: "1px dashed gray",
            }}>

            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
                width: "100%"
            }}>
                <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                    {question}
                </p>
                <p style={{ fontSize: "14px" }}>
                    {answers[formData.answer].text}
                </p>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start"
            }}>
                {
                    (formData?.optional && formData.optional !== '') &&
                    <p style={{ fontSize: "14px" }}>
                        <b>optional:</b>&nbsp;
                        {formData.optional}
                    </p>
                }
                {
                    (formData?.comment && formData.comment !== '') &&
                    <p style={{ fontSize: "14px" }}>
                        <b style={{ fontSize: "14px" }}>comment:</b>&nbsp;
                        {formData.comment}
                    </p>
                }
            </div>
        </div>
    )
}