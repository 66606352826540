import React, { useContext, useEffect, useState } from "react";
import MainFrame from "../../components/MainFrame";
import NavBar from "../../components/NavBar";
import { Autocomplete, Box, Button, styled } from "@mui/material";
import FaxIcon from "@mui/icons-material/Fax";
import colors from "../../global/colors";
import { CustomTextField } from "../../components/TextFields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CanadianPhoneField from "../../components/Input/phoneNumberInput";
import ProfileImagePicker from "../../components/uploader/profileUploader";
import {
  Btn,
  ButtonContainer,
  FullWidthButton,
  NegativeButton,
  PositiveButton,
} from "../../components/Buttons/styles";
import { useNavigate } from "react-router-dom";
import useProfileController from "./profileController";
import { PostalCode } from "../../components/Input/postalCodeInput";
import PhoneIcon from "@mui/icons-material/Phone";
import { modalContext } from "../../components/modal/modal";
import { ResetPassModal } from "./modal";
const CustomAutoComplete = styled(Autocomplete)({
  "&&.css-1hsevg8-MuiAutocomplete-listbox .MuiAutocomplete-option": {
    fontSize: ".9rem",
  },
});
const Container = styled(Box)({
  width: "100%",
  padding: "1rem",
  maxWidth: "45rem",
  minWidth: "30rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "center",
  background: colors.white,
  textAlign: "center",
  backdropFilter: "blur(10px)",
  zIndex: 2,
  maxHeight: "77vh",
  height: "77vh",
  borderRadius: ".5rem",
  boxShadow: colors.boxShadow,
});
const Row = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  width: "100%",
});
const Wrapper = styled(Box)({
  alignItems: "center",
  width: "100%",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
});
const ProfileSchema = yup.object().shape({
  businessName: yup.string(),
  postalCode: yup.string(),
  phone: yup.string(),
  address: yup.string(),
  city: yup.string(),
  province: yup.string(),
  firstName: yup.string(),
  lastName: yup.string(),
  role: yup.string(),
  fax: yup.string(),
});

function Profile() {
  const [profile, setProfile] = useState({});
  const { updateProfile, getProfile } = useProfileController();
  useEffect(() => {
    getProfile().then((result) => {
      setProfile(result);
      setValue("firstName", result.firstName ?? "");
      setValue("lastName", result.lastName ?? "");
      setValue("address", result.address ?? "");
      setValue("businessName", result.businessName ?? "");
      setValue("phone", result.phone ?? "");
      setValue("city", result.city ?? "");
      setValue("province", result.province ?? "");
      setValue("postalCode", result.postalCode ?? "");
      setValue("role", result.role ?? "");
      setValue("fax", result.fax ?? "");
    });
  }, []);

  const {
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ProfileSchema),
  });

  const [image, setImage] = useState();
  const [edit, setEdit] = useState(false);
  const { setContent, setShow } = useContext(modalContext);
  const handleOpenModal = (type) => {
    if (type === "reset-pass") {
      setContent({
        title: "change password",
        body: <ResetPassModal />,
      });
    }
    setShow(true);
  };
  return (
    <Wrapper>
      <NavBar topic="profile settings">
        <Btn variant="contained" onClick={() => handleOpenModal("reset-pass")}>
          change password
        </Btn>
      </NavBar>
      <Container>
        <Box style={{ width: "100%", maxHeight: "63vh", overflow: "auto" }}>
          <Row>
            <ProfileImagePicker onChange={setImage} />
            <CustomTextField
              label="Business Name"
              variant="outlined"
              disabled={edit ? false : true}
              error={!!errors.businessName}
              helperText={errors.businessName?.message}
              value={watch("businessName")}
              onChange={(e) => setValue("businessName", e.target.value)}
              InputLabelProps={{
                shrink: watch("businessName") ? true : false,
              }}
            />
            <CustomAutoComplete
              options={["B.Pharm", "M.Pharm", "Pharm.D"]}
              disablePortal
              disabled={edit ? false : true}
              id="combo-box-demo"
              fullWidth
              defaultValue={watch("role")}
              onChange={(e, value) => {
                if (value == null) return setValue("role", "");
                setValue("role", value);
              }}
              renderInput={(params) => (
                <CustomTextField
                  disabled={edit ? false : true}

                  variant="outlined"
                  {...params}
                  label="Affiliation"
                />
              )}
            />
          </Row>
          <Row>
            <CustomTextField
              disabled={edit ? false : true}
              label="First Name"
              variant="outlined"
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              value={watch("firstName")}
              onChange={(e) => setValue("firstName", e.target.value)}
              InputLabelProps={{
                shrink: watch("firstName") ? true : false,
              }}
            />
            <CustomTextField
              disabled={edit ? false : true}
              label="Last Name"
              variant="outlined"
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              value={watch("lastName")}
              onChange={(e) => setValue("lastName", e.target.value)}
              InputLabelProps={{
                shrink: watch("lastName") ? true : false,
              }}
            />
          </Row>
          <Row>
            <CanadianPhoneField
              disabled={edit ? false : true}
              label="Phone Number"
              onChange={(value) => setValue("phone", value)}
              defaultValue={profile.phone ?? ""}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              AdornmentIcon={<PhoneIcon />}
            />
            <PostalCode
              disabled={edit ? false : true}
              error={!!errors.postalCode}
              helperText={errors?.postalCode ? errors.postalCode.message : ""}
              label="Postal Code"
              value={watch("postalCode")}
              defaultValue={getValues().postalCode}
              placeholder="Enter Your Postal Code..."
              onChange={(e) => {
                setValue("postalCode", e);
              }}
            />
          </Row>
          <Row style={{ justifyContent: "flex-start" }}>
            <CanadianPhoneField
              disabled={edit ? false : true}
              style={{
                width: "50%",
              }}
              label="Fax"
              onChange={(value) => setValue("fax", value)}
              defaultValue={profile.fax ?? ""}
              error={!!errors.fax}
              helperText={errors.fax?.message}
              AdornmentIcon={<FaxIcon />}
            />
          </Row>
          <CustomTextField
            disabled={edit ? false : true}
            label="Address"
            multiline
            minRows={3}
            maxRows={4}
            InputLabelProps={{
              shrink: watch("address") ? true : false,
            }}
            variant="outlined"
            error={!!errors.address}
            helperText={errors.address?.message}
            value={watch("address")}
            onChange={(e) => setValue("address", e.target.value)}
          />
          <Row>
            <CustomTextField
              disabled={edit ? false : true}
              label="City"
              variant="outlined"
              error={!!errors.city}
              helperText={errors.city?.message}
              value={watch("city")}
              InputLabelProps={{
                shrink: watch("city") ? true : false,
              }}
              onChange={(e) => setValue("city", e.target.value)}
            />
            <CustomTextField
              disabled={edit ? false : true}
              label="Province"
              variant="outlined"
              error={!!errors.province}
              helperText={errors.province?.message}
              value={watch("province")}
              InputLabelProps={{
                shrink: watch("province") ? true : false,
              }}
              onChange={(e) => setValue("province", e.target.value)}
            />
          </Row>
        </Box>
        <ButtonContainer style={{ width: "40%" }}>
          {/* <Button
            variant="outlined"
            style={NegativeButton}
            onClick={() => {
              navigate(-1);
            }}
          >
            cancel
          </Button>
          &nbsp; */}
          <Button
            variant="contained"
            style={FullWidthButton}
            onClick={handleSubmit((data) => {
              setEdit(!edit)
              if (edit)
                updateProfile(data);

            })}
          >
            {edit ?
              "save changes"
              :
              "Edit"
            }
          </Button>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
}

export default MainFrame(Profile);
