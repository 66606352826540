import useApi from "./httpService";

export default function usePatientsApi() {
  const api = useApi();
  const user_id = localStorage.getItem("user_id");

  /**
   *
   * @param {any} form
   */
  const addPatient = async (form) => {
    await api({
      method: "POST",
      url: `/owners/${user_id}/patients`,
      data: form,
    });
  };

  const getPatients = async (query) => {
    const result = await api({
      method: "get",
      url: `/owners/${user_id}/patients`,
      params: query,
    });
    return result.data;
  };

  const updatePatient = async (id, form) => {
    await api({
      method: "PUT",
      url: `/patients/${id}`,
      data: form,
    });
  };
  const removePatient = async (patient) => {
    await api({
      method: "DELETE",
      url: `/owners/${user_id}/patients/${patient}`,
    });
  };

  return { addPatient, getPatients, updatePatient, removePatient };
}
