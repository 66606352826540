import React, { useEffect, useState } from 'react';
import { TextField, List, ListItem, ListItemText, IconButton, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import colors from '../../../../global/colors';
import { CustomTextField } from '../../../../components/TextFields';


const AddItemInput = ({
    label,
    defaultValue,
    placeholder,
    onChange,
    scroll = true }) => {
    const [inputValue, setInputValue] = useState('');
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (Array.isArray(defaultValue))
            setItems(defaultValue)
    }, [defaultValue])

    const handleAddItem = () => {
        if (inputValue.trim() !== '') {
            setItems([...items, inputValue]);
            if (onChange)
                onChange([...items, inputValue])
            setInputValue('');
        }
    };
    const handleDeleteItem = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
        if (onChange)
            onChange(updatedItems)
    };
    
    return (
        <>
            <CustomTextField
                variant='outlined'
                label={label}
                placeholder={placeholder}
                InputLabelProps={{ shrink: true }}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton style={{ background: colors.lightPurple }} onClick={handleAddItem}>
                                <AddIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <List style={{ padding: "0 .5rem", maxHeight: scroll ? "35vh" : "", overflow: scroll && "auto", height: "fit-content", minHeight: "10vh" }}>
                {items.map((item, index) => (
                    <ListItem key={index} style={{ paddingTop: 0, border: "1px solid #fff", background: colors.lightBgGray0, boxShadow: colors.mainShadow, borderRadius: ".5rem" }}>
                        <ListItemText primary={item} />
                        <IconButton onClick={() => handleDeleteItem(index)}>
                            <DeleteIcon fontSize="20px" />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        </>
    );
};

export default AddItemInput;
