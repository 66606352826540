import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import totalTheme from "./global/theme";
import Modal, { modalContext } from "./components/modal/modal";
import { useEffect, useState } from "react";
import Patients from "./pages/Patients";
import Login from "./pages/login";
import Reminder from "./pages/Reminder";
import Patient from "./pages/Patients/Patient";
import Forms from "./pages/forms";
import Form from "./pages/forms/form";
import FormDetails from "./pages/forms/formDetails";
import Appointments from "./pages/Appointments";
import Appointment from "./pages/Appointments/appointment";
import Followup from "./pages/forms/followup";
import NewForm from "./pages/forms/newForm";
import Preview from "./pages/forms/preview";
import Followups from "./pages/followups";
import Profile from "./pages/profile";
import Trackes from "./pages/trackes";
import Track from "./pages/trackes/track";
import Requests from "./pages/Requests";
import Request from "./pages/Requests/request";
import SignUp from "./pages/login/signUp";
import PasswordSettings from "./pages/login/passwordSettings";
import ForgetPassword from "./pages/login/forgetPassword";
import Ailments from "./pages/Ailments";
import Algorithms from "./pages/Algorithms";
import Algorithm from "./pages/Algorithms/algorithm";

const App = () => {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState({
    title: "Abstract Modal",
    body: "this is empty modal",
  });

  return (
    <ThemeProvider theme={totalTheme}>
      <modalContext.Provider value={{ show, setShow, content, setContent }}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login/forget-password" element={<ForgetPassword />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/initial-settings" element={<PasswordSettings />} />
            <Route path="/profile" element={<Profile />} />

            <Route path="/Patients" element={<Patients />} />
            <Route path="/PersonalPatientPage" element={<Patient />} />

            <Route path="/forms" element={<Forms />} />
            <Route path="/form" element={<Form />} />
            <Route path="/form-details" element={<FormDetails />} />
            <Route path="/forms/new/:illness" element={<NewForm />} />
            <Route path="/forms/preview/:illness" element={<Preview />} />

            <Route path="/followUps" element={<Followups />} />
            <Route path="/followup/:followupId" element={<Followup />} />

            <Route path="/appointments" element={<Appointments />} />
            <Route path="/appointment" element={<Appointment />} />

            {/* <Route path="/reminder" element={<Reminder />} /> */}

            <Route path="/requests" element={<Requests />} />
            <Route path="/requests/request/:requestId" element={<Request />} />

            <Route path="/tracks" element={<Trackes />} />
            <Route path="/tracks/track/" element={<Track />} />

            <Route path="/ailments/:ailment" element={<Ailments />} />

            <Route path="/algorithms/:patient" element={<Algorithms />} />
            <Route path="/algorithms/algorithm/:ailment" element={<Algorithm />} />
          </Routes>
          <Modal />
        </Router>
      </modalContext.Provider>
    </ThemeProvider>
  );
};

export default App;
