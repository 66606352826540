import React, { useState } from "react";
import Navbar from "../../components/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import MainFrame from "../../components/MainFrame";
import AilmentsAlgorithm from "../Ailments/algorithm";
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { styled } from "@mui/styles";
import colors from "../../global/colors";
import {
  ButtonContainer,
  NegativeButton,
  PositiveButton,
} from "../../components/Buttons/styles";
import TextContainer from "../../components/Texts/TextContainer";

import PersonIcon from "@mui/icons-material/Person";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";

import BasicAccordion from "../../components/Buttons/collapsibleButton";
import VoiceInput from "../../components/Input/voiceInput";
import Medications from "../forms/components/medications";
import { CustomTextField } from "../../components/TextFields";
import { RowBox } from "../../global/table";
import DateInput from "../../components/Input/dateInput";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AlgActions } from "../../global/redux/alg";
import useAlgApi from "../../api/algorithm.api";

const Container = styled(Box)({
  maxHeight: "78vh",
  borderRadius: "5px",
  width: "100%",
});
const AlgorithmWrapper = styled(Box)({
  maxHeight: "70vh",
  marginBottom: "1rem",
  overflow: "auto",
  width: "100%",
  background: colors.white,
});

const CustomContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: ".3rem",
  overflow: "auto",
  maxHeight: "50vh",
});
const CustomBox = styled(Box)({
  width: "100%",
  display: "flex",
  gap: "1rem",
  flexDirection: "row",
  justifyContent: "flex-start",
});
const YesNoQuestion = ({ question, value, onChange, answers }) => {
  return (
    <CustomBox>
      <TextContainer textTransform="capitalize" fontSize=".9rem">
        {question}
      </TextContainer>
      <TextContainer
        color={colors.darkBlue}
        style={{ textTransform: "capitalize", fontSize: "1rem" }}
      >
        <RadioGroup
          row
          aria-labelledby="demo-form-control-label-placement"
          name="position"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          <FormControlLabel
            value={answers[0]}
            control={<Radio size="small" color="primary" />}
            label={answers[0]}
            labelPlacement="left"
          />
          <FormControlLabel
            value={answers[1]}
            control={<Radio size="small" color="primary" />}
            label={answers[1]}
            labelPlacement="left"
          />
        </RadioGroup>
      </TextContainer>
    </CustomBox>
  );
};
function Info({ preview }) {
  const state = useSelector((state) => state.alg);
  const dispatch = useDispatch();
  const {
    ailment = "Illness",
    patient = {
      userName: "Mohamamd",
    },
    questions = {
      referredToDoctor: {
        answer: false,
        comment: "",
      },
      medicationsPrescribed: {
        answer: false,
        comment: "",
      },
    },
    medications = [],
  } = state;
  return (
    <CustomContainer>
      <CustomBox>
        <TextContainer
          textTransform="capitalize"
          fontSize="1rem"
          color={colors.darkBlue}
          fontWeight="bold"
        >
          <CoronavirusIcon fontSize="22px" />
          &nbsp;Illness:
        </TextContainer>
        <TextContainer textTransform="capitalize" fontSize="1rem">
          {ailment}
        </TextContainer>
      </CustomBox>
      <CustomBox>
        <TextContainer
          textTransform="capitalize"
          fontSize="1rem"
          color={colors.darkBlue}
          fontWeight="bold"
        >
          <PersonIcon fontSize="22px" />
          &nbsp;patient:
        </TextContainer>
        <TextContainer textTransform="capitalize" fontSize="1rem">
          {patient?.userName}
        </TextContainer>
      </CustomBox>
      <YesNoQuestion
        answers={["yes", "no"]}
        question="Has the patient been referred to the doctor?"
        value={questions.referredToDoctor.answer}
        onChange={(value) => {
          if (preview) return;
          dispatch(
            AlgActions.updateAlg({
              questions: {
                ...questions,
                referredToDoctor: {
                  comment: questions.referredToDoctor.comment,
                  answer: value,
                },
              },
            })
          );
        }}
      />
      <BasicAccordion title="comment" isOpen={false}>
        <VoiceInput
          value={questions.referredToDoctor.comment}
          handleChange={(value) => {
            if (preview) return;
            dispatch(
              AlgActions.updateAlg({
                questions: {
                  ...questions,
                  referredToDoctor: {
                    comment: value,
                    answer: questions.referredToDoctor.answer,
                  },
                },
              })
            );
          }}
        />
      </BasicAccordion>
      <YesNoQuestion
        answers={["yes", "no"]}
        question="Have any medications been prescribed?"
        value={questions.medicationsPrescribed.answer}
        onChange={(value) => {
          if (preview) return;
          dispatch(
            AlgActions.updateAlg({
              questions: {
                ...questions,
                medicationsPrescribed: {
                  comment: questions.medicationsPrescribed.comment,
                  answer: value,
                },
              },
            })
          );
        }}
      />
      <BasicAccordion title="comment" isOpen={false}>
        <VoiceInput
          value={questions.medicationsPrescribed.comment}
          handleChange={(value) => {
            if (preview) return;
            dispatch(
              AlgActions.updateAlg({
                questions: {
                  ...questions,
                  medicationsPrescribed: {
                    comment: value,
                    answer: questions.medicationsPrescribed.answer,
                  },
                },
              })
            );
          }}
        />
      </BasicAccordion>
      <Medications
        preview={preview}
        medications={medications}
        onChange={(data) => {
          dispatch(
            AlgActions.updateAlg({
              medications: data,
            })
          );
        }}
      />
    </CustomContainer>
  );
}
const TotalInformations = ({ preview }) => {
  const metadata = useSelector((state) => state.alg.metadata);
  const dispatch = useDispatch();
  const {
    dBefore = new Date(),
    eDate = new Date(),
    dQ = 0,
    dDS = 2,
    pQ = 10,
    dS = 0,
    route = "route",
    refilles = "refilles",
    trial = false,
  } = metadata;
  return (
    <>
      <RowBox>
        <DateInput
          onChange={(e) => {
            if (preview) return;
            dispatch(
              AlgActions.updateAlg({
                metadata: {
                  ...metadata,
                  dBefore: e.toString(),
                },
              })
            );
          }}
          value={dayjs(dBefore)}
          label="dont dispense before"
        />
        <DateInput
          onChange={(e) => {
            if (preview) return;
            dispatch(
              AlgActions.updateAlg({
                metadata: {
                  ...metadata,
                  eDate: e.toString(),
                },
              })
            );
          }}
          value={dayjs(eDate)}
          label="last fill expiry date"
        />
      </RowBox>
      <RowBox>
        <CustomTextField
          disabled={preview}
          onChange={(e) => {
            dispatch(
              AlgActions.updateAlg({
                metadata: {
                  ...metadata,
                  dQ: e.target.value,
                },
              })
            );
          }}
          value={dQ}
          variant="outlined"
          label="dispense quantity"
        />
        <CustomTextField
          disabled={preview}
          onChange={(e) => {
            dispatch(
              AlgActions.updateAlg({
                metadata: {
                  ...metadata,
                  dDS: e.target.value,
                },
              })
            );
          }}
          value={dDS}
          variant="outlined"
          label="dispense days supply"
        />
      </RowBox>
      <RowBox>
        <CustomTextField
          disabled={preview}
          onChange={(e) => {
            dispatch(
              AlgActions.updateAlg({
                metadata: {
                  ...metadata,
                  pQ: e.target.value,
                },
              })
            );
          }}
          value={pQ}
          variant="outlined"
          type="number"
          label="total prescribed quantity"
        />
        <CustomTextField
          disabled={preview}
          onChange={(e) => {
            dispatch(
              AlgActions.updateAlg({
                metadata: {
                  ...metadata,
                  dS: e.target.value,
                },
              })
            );
          }}
          value={dS}
          type="number"
          variant="outlined"
          label="total days supply"
        />
      </RowBox>
      <RowBox>
        <CustomTextField
          disabled={preview}
          onChange={(e) => {
            dispatch(
              AlgActions.updateAlg({
                metadata: {
                  ...metadata,
                  route: e.target.value,
                },
              })
            );
          }}
          value={route}
          variant="outlined"
          label="route"
        />
        <CustomTextField
          disabled={preview}
          onChange={(e) => {
            dispatch(
              AlgActions.updateAlg({
                metadata: {
                  ...metadata,
                  refilles: e.target.value,
                },
              })
            );
          }}
          value={refilles}
          type="number"
          variant="outlined"
          label="refills"
        />
      </RowBox>
      <YesNoQuestion
        answers={["yes", "no"]}
        question="trial:"
        value={trial}
        onChange={(value) => {
          if (preview) return;
          dispatch(
            AlgActions.updateAlg({
              metadata: {
                ...metadata,
                trial: value,
              },
            })
          );
        }}
      />
    </>
  );
};
function Spec({ preview }) {
  const state = useSelector((state) => state.alg);
  const dispatch = useDispatch();
  const {
    ailment = "Test",
    patient = {
      userName: "Mohammad",
    },
    metadata = {
      ABULI: "ABULI",
      DIN: "DIN",
      N_ORDER: "Order",
      status: false,
    },
  } = state;

  const { ABULI, DIN, N_ORDER, STATUS } = metadata;

  return (
    <CustomContainer>
      <CustomBox>
        <TextContainer
          textTransform="capitalize"
          fontSize="1rem"
          color={colors.darkBlue}
          fontWeight="bold"
        >
          <CoronavirusIcon fontSize="22px" />
          &nbsp;Illness:
        </TextContainer>
        <TextContainer textTransform="capitalize" fontSize="1rem">
          {ailment}
        </TextContainer>
      </CustomBox>
      <CustomBox>
        <TextContainer
          textTransform="capitalize"
          fontSize="1rem"
          color={colors.darkBlue}
          fontWeight="bold"
        >
          <PersonIcon fontSize="22px" />
          &nbsp;patient:
        </TextContainer>
        <TextContainer textTransform="capitalize" fontSize="1rem">
          {patient?.userName}
        </TextContainer>
      </CustomBox>
      <RowBox>
        <CustomTextField
          disabled={preview}
          value={ABULI}
          onChange={(value) => {
            dispatch(
              AlgActions.updateAlg({
                metadata: {
                  ...metadata,
                  ABULI: value.target.value,
                },
              })
            );
          }}
          label="AB ULI"
          type="tel"
          variant="outlined"
          InputProps={{
            inputProps: {
              maxLength: 9,
            },
          }}
        />
        <CustomTextField
          disabled={preview}
          value={DIN}
          onChange={(value) => {
            dispatch(
              AlgActions.updateAlg({
                metadata: {
                  ...metadata,
                  DIN: value.target.value,
                },
              })
            );
          }}
          label="DIN"
          type="tel"
          variant="outlined"
          InputProps={{
            inputProps: {
              maxLength: 8,
            },
          }}
        />
      </RowBox>
      <CustomTextField
        disabled={preview}
        value={N_ORDER}
        onChange={(value) => {
          dispatch(
            AlgActions.updateAlg({
              metadata: {
                ...metadata,
                N_ORDER: value.target.value,
              },
            })
          );
        }}
        label="NetCare Order"
        type="tel"
        variant="outlined"
        InputProps={{
          inputProps: {
            maxLength: 8,
            pattern: "[a-zA-Z0-9]*",
          },
        }}
      />
      <YesNoQuestion
        question="status:"
        answers={["active", "inActive"]}
        value={STATUS}
        onChange={(value) => {
          if (preview) return;
          dispatch(
            AlgActions.updateAlg({
              metadata: {
                ...metadata,
                STATUS: value,
              },
            })
          );
        }}
      />
      <TotalInformations preview={preview} />
    </CustomContainer>
  );
}
function Algorithm() {
  const algState = useSelector((state) => state.alg);
  const ailment = algState.ailment;
  const { addAlg } = useAlgApi();

  const location = useLocation();
  const preview = location.state?.preview ? true : false;

  const navigate = useNavigate();
  const [state, setState] = useState("alg"); // alg, pill / spec
  const render = () => {
    switch (state) {
      case "alg":
        return (
          <AlgorithmWrapper>
            <AilmentsAlgorithm preview={preview} />
          </AlgorithmWrapper>
        );
      case "pill":
        return <Info preview={preview} />;
      case "spec":
        return <Spec preview={preview} />;
      default:
        return null;
    }
  };
  const onClick = () => {
    switch (state) {
      case "alg":
        setState("pill");
        break;
      case "pill":
        setState("spec");
        break;
      case "spec":
        addAlg(algState)
          .then(() => {
            navigate(-1);
          })
          .catch((err) => {});
        break;
      default:
        break;
    }
  };
  const onGoBack = () => {
    switch (state) {
      case "alg":
        navigate(-1);
        break;
      case "pill":
        setState("alg");
        break;
      case "spec":
        setState("pill");
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Navbar topic={`${ailment} Algorithm`} onGoBack={onGoBack} />
      <Container>
        {render()}
        <ButtonContainer>
          <Button
            variant="outlined"
            onClick={() => navigate(-1)}
            style={NegativeButton}
          >
            cancel
          </Button>
          &nbsp;
          <Button variant="contained" style={PositiveButton} onClick={onClick}>
            Next
          </Button>
        </ButtonContainer>
      </Container>
    </React.Fragment>
  );
}
export default MainFrame(Algorithm);
