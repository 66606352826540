import { Stage, Layer, Rect, Image, Group } from "react-konva";
import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import Data from "../../../constants/ailments.db.json";
import colors from "../../../global/colors";
import { useDispatch, useSelector } from "react-redux";
import { AlgActions, getAlgNode } from "../../../global/redux/alg";

function Node({ rect, id, type, questions, preview }) {
  const dispatch = useDispatch();
  const node = useSelector(getAlgNode(id));
  const { items = [], isSelected = false } = node;
  function onClick() {
    if (preview) return;
    console.log(id);
    dispatch(
      AlgActions.selectNode({
        id,
        select: !isSelected,
      })
    );
  }
  switch (type) {
    case "SIMPLE":
      return (
        <Rect
          onClick={onClick}
          x={rect.x}
          y={rect.y}
          width={rect.w}
          height={rect.h}
          fill={isSelected ? "" : "white"}
          opacity={0.5}
          stroke={isSelected ? "black" : "white"}
        />
      );
    case "MU-Q":
      return (
        <Group>
          <Rect
            onClick={onClick}
            x={rect.x}
            y={rect.y}
            width={rect.w}
            height={rect.h}
            fill={isSelected ? "" : "white"}
            opacity={0.5}
            stroke={isSelected ? "black" : "white"}
          />

          {isSelected &&
            questions.map((question, indx) => (
              <Rect
                key={indx}
                x={question.x}
                y={question.y}
                width={7}
                height={7}
                onClick={() => {
                  if (preview) return;
                  if (items.includes(indx))
                    dispatch(
                      AlgActions.updateAlgNode({
                        id,
                        data: {
                          items: items.filter((index) => index !== indx),
                        },
                      })
                    );
                  else {
                    dispatch(
                      AlgActions.updateAlgNode({
                        id,
                        data: {
                          items: [...items, indx],
                        },
                      })
                    );
                  }
                }}
                fill={items.includes(indx) ? colors.darkBlue : ""}
                stroke="black"
                strokeWidth={1}
              />
            ))}
        </Group>
      );
    default:
      return null;
  }
}

function AilmentsAlgorithm({ preview }) {
  const state = useSelector((state) => state.alg);
  const [aImage, setAImage] = useState(null);
  const ref = useRef(null);
  const {
    image = "",
    imageSize = { x: 0, y: 0 },
    items = {
      ids: [],
      entities: {},
    },
  } = Data[state?.ailment];
  useEffect(() => {
    if (ref.current) setAImage(ref.current);
  }, [ref]);

  return (
    <Box width="100%">
      <img
        src={require(`../../../assets/images/graphs/${image}`)}
        alt="bgimage"
        style={{
          display: "none",
        }}
        ref={ref}
      />
      <Stage width={790} height={800}>
        <Layer width={790} height={800}>
          <Image width={imageSize?.x} height={imageSize?.y} image={aImage} />
        </Layer>
        <Layer>
          {items.ids.map((id) => {
            const item = items.entities[id];
            return (
              <Node
                key={item.id}
                id={item.id}
                rect={item.rect}
                type={item.type}
                questions={item.questions}
                preview={preview}
                selectedItems={item?.selectedItems}
              />
            );
          })}
        </Layer>
      </Stage>
    </Box>
  );
}

export default AilmentsAlgorithm;
