import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import styled from "@emotion/styled"
import dayjs from 'dayjs';
const Provider = styled(LocalizationProvider)({
    "& .css-a643pv-MuiStack-root": {
        width: '100%',
    },
})

const FullWidthDateTimePicker = styled(DateTimePicker)({
    "&&": {
        marginBottom: "1rem",
        width: "100%"
    }
});

export default function BasicDateTimePicker({
    value,
    label,
    onChange
}) {
    return (
        <Provider dateAdapter={AdapterDayjs}>
            <FullWidthDateTimePicker
                value={value}
                label={label}
                onChange={(value) => onChange(value)}
                minDateTime={dayjs(new Date())}
            />
        </Provider>
    );
}