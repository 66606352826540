import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";
import colors from "../../global/colors";
import TextContainer from "../Texts/TextContainer";
import { TOPIC } from "../Texts/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenModal({ children, button, title }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { handleClose });
    }
    return child;
  });

  return (
    <React.Fragment>
      <Box style={{ width: "100%" }} onClick={handleClickOpen}>
        {button}
      </Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", boxShadow: "none" }}>
          <Toolbar sx={{ bgcolor: colors.lightBlue }}>
            <IconButton
              edge="start"
              color="#222"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <TextContainer
              {...TOPIC}
              style={{ marginLeft: "1rem", color: "#222" }}
              component="div"
            >
              {title}
            </TextContainer>
          </Toolbar>
        </AppBar>
        <Box sx={{ padding: "2rem" }}>{childrenWithProps}</Box>
      </Dialog>
    </React.Fragment>
  );
}
