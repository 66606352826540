import SimpleQuestion from "./simpleQuestion";
import MultiQuestion from "./multiQuestion";
import Slider from "./slider";
import Other from "./otherQuestion";
import PatientSelection from "./patientSelection";
import submitState from "./submitState";

export default function getFormComponent(resolver, preview) {
  let comp = null;
  switch (resolver) {
    case "simple":
      comp = SimpleQuestion;
      break;
    case "multi":
      comp = MultiQuestion;
      break;
    case "slider":
      comp = Slider;
      break;
    case "other":
      comp = Other;
      break;
    default:
      break;
  }

  if (resolver === "p_info") return PatientSelection;
  if (resolver === "submit") return submitState;
  if (preview) return comp.preview;
  return comp.edit;
}

export function getToPdfComponent(resolver) {
  switch (resolver) {
    case "simple":
      return SimpleQuestion.pdf;
    case "multi":
      return MultiQuestion.pdf;
    case "slider":
      return Slider.pdf;
    case 'other':
      return Other.pdf;
    default:
      return null;
  }
}
