import TextContainer from "../../components/Texts/TextContainer";
import { Box, Button, InputAdornment, useMediaQuery } from "@mui/material";
import { CustomTextField } from "../../components/TextFields";
import { useRef, useState } from "react";
import { TOPIC } from "../../components/Texts/styles";
import colors from "../../global/colors";
import { FullWidthButton } from "../../components/Buttons/styles";
import ReactInputVerificationCode from "react-input-verification-code";
import CoutDownTimer from "../../components/timer/coutDownTimer";
import useLoginController from "./loginController";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import MailIcon from "@mui/icons-material/Mail";
import { FormBox } from "../../global/table";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

export default function Verification({ text, handleVerify, handleEditEmail }) {
  const code = useRef();
  const {
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [resend, setResend] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:868px)");
  const controller = useLoginController();
  return (
    <div
      style={{
        background: colors.lightBgGray0,
        height: "100vh",
        width: "100%",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <FormBox isSmallScreen={isSmallScreen}>
        <Box sx={{ width: "100%" }}>
          <img
            alt="logo"
            src={require("../../assets/images/logo.png")}
            width="20%"
            height="20%"
            style={{ minWidth: "2rem", minHeight: "2.5rem" }}
          />
          <TextContainer {...TOPIC}>PharmaCanada</TextContainer>
        </Box>
        <TextContainer fontSize=".9rem" color="#555">
          {text}
        </TextContainer>
        <TextContainer
          color={colors.darkBlue}
          textTransform="capitalize"
          cursor="pointer"
          onClick={() => handleEditEmail()}
        >
          edit email
        </TextContainer>
        <div className="custom-styles">
          <ReactInputVerificationCode
            type="text"
            length={6}
            onChange={(data) => {
              code.current = data;
            }}
          />
        </div>
        {resend ? (
          <TextContainer
            onClick={() => {
              setResend(false);
              controller.requestCode(getValues("email")).catch((err) => { });
            }}
            cursor="pointer"
            textTransform="lowercase"
            textDecoration="underline"
            color={colors.darkBlue}
          >
            resend
          </TextContainer>
        ) : (
          <CoutDownTimer
            initialSeconds={60}
            ResendCode={() => {
              setResend(true);
            }}
          />
        )}
        <Button
          variant="contained"
          onClick={() => handleVerify(code.current)}
          style={FullWidthButton}
        >
          submit
        </Button>
      </FormBox>
    </div>
  );
}
