
import { Slider } from "@material-ui/core";
import CollapsibleButton from "../../../../components/Buttons/collapsibleButton";
import TextContainer from "../../../../components/Texts/TextContainer";
import { IconButton, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import colors from "../../../../global/colors";


export default function SliderQuestio({
    question,
    formId,
    onEdit,
    min,
    max,
    step,
    formData
}) {
    return (
        <CollapsibleButton
            titleComponent={() => {
                return (
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <TextContainer>
                            <Typography textTransform="capitalize" fontSize="1rem">
                                {question}
                            </Typography>
                        </TextContainer>
                        <IconButton style={{ marginRight: "1rem" }}
                            onClick={(e) => {
                                e.stopPropagation()
                                onEdit(formId)
                            }}
                        >
                            <EditIcon
                                style={{ color: colors.darkBlue }}
                                fontSize="22px" />
                        </IconButton>
                    </div>
                )
            }}
            isOpen={false}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", maxHeight: "35vh", overflow: "auto" }}>
                <div style={{ padding: "3.5% 2%" }}>
                    <Slider
                        color="primary"
                        aria-label="{label}"
                        value={formData.value}
                        valueLabelDisplay="auto"
                        step={step}
                        min={min}
                        max={max}
                        marks
                    />
                </div>
                <TextContainer>
                    <Typography width="100%" textAlign="left" textTransform="capitalize" fontSize="1rem">
                        <b style={{fontSize:"14px"}}>comment:</b>&nbsp;
                        {formData.comment}
                    </Typography>
                </TextContainer>
            </div>
        </CollapsibleButton>
    )
}