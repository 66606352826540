export const Ailments = [
  "Aphthous Ulcers Canker Sores",
  "Assessment Of Foot Symptoms",
  "Periodontal Conditions",
  "Perspiration And Body Odour",
  "Pinworms",
  "Plantar Warts",
  "Premenstrual Syndrome",
  "Prenatal And Postpartum Care",
  "Prevention And Treatment Of Sun Induced Skin Damage",
  "Smoking Cessation",
  "Sports Injuries",
  "Vaginal Symptoms, Hygiene And Infections",
  "Vertigo And Dizziness",
  "Viral Rhinitis, Influenza, Rhinosinusitis And Pharyngitis",
  "Viral Skin Infections Common And Flat Plane Warts",
  "Viral Skin Rashes",
  "Acute Cough",
  "Allergic Arhinitis",
  "Athletes Foot",
  "Atopic Contact And Stasis Dermatitis",
  "Bacterial Skin Infections Impetigo, Furuncles And Carbuncles",
  "Cold Sores",
  "Complications Affecting The Ear",
  "Conjunctivitis",
  "Constipation",
  "Corns Calluses Bunions And Ingrown Toenails",
  "Dandruff And Seborrheic Dermatitis",
  "Dental Conditions",
  "Diarrhea",
  "Disper Dermatitis",
  "Drug Induced Skin Reactions",
  "Dry Eye",
  "Dry Mouth",
  "Dysmenorrhea",
  "Dyspepsia And GERD",
  "Eyelid Conditions",
  "Fever",
  "Fungal Nail Infections",
  "Fungal Skin Infections",
  "Gastrointestinal Gas",
  "Hair Care And Hair Growth",
  "Headache In Adults",
  "Hemorrhoids",
  "Impacted Earwax",
  "Infant Colic",
  "Insect Bites And Stings",
  "Insomnia",
  "Low Back Pain",
  "Minor Cuts And Wounds",
  "Nausea And Vomiting",
  "Oral Candidiasis",
  "Oral Hygiene Dentalplaque And Caries",
  "Osteoarthritis",
  "Otitis Externa",
  "Parasitic Skin Nfections",
  "Teething",
];
