import * as React from "react";
import Footer, {
  CustomTableBody,
  CustomTableContainer,
  TableHeader,
} from "../../components/table";
import Table, { Row } from "../../global/table";
import { Paper, TableCell, TableRow } from "@mui/material";
import Filter from "../../components/filter/filter";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import useAlgApi from "../../api/algorithm.api";
import { useDispatch } from "react-redux";
import { AlgActions } from "../../global/redux/alg";

export default function AlgorithmsTable({ filters, open }) {
  const [algs, setAlgs] = React.useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const { getAlgs } = useAlgApi();
  const patient = location.state;
  React.useEffect(() => {
    getAlgs(patient._id)
      .then((res) => setAlgs(res))
      .catch((err) => console.error(err));
  }, [patient._id]);

  const [query, setQuery] = React.useState({
    page: 0,
    size: 20,
  });
  const navigate = useNavigate();
  const getAlgorithms = (query = {}) => {
    //api
  };

  React.useEffect(() => {
    getAlgorithms(query);
  }, [query]);

  return (
    <>
      <Filter
        convertor={"toMongoo"}
        filters={filters}
        dataLoader={(q) =>
          setQuery((prev) => ({
            page: prev.page,
            size: prev.size,
            ...q,
          }))
        }
      />
      <CustomTableContainer component={Paper} style={{ maxHeight: "58vh" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHeader>
            <TableRow>
              <TableCell style={{ padding: 5 }} width="33.5%">
                Ailments
              </TableCell>
              <TableCell style={{ padding: 5 }} width="33.5%">
                Date Created
              </TableCell>
              <TableCell style={{ padding: 5 }} width="33.5%">
                Created By
              </TableCell>
            </TableRow>
          </TableHeader>
          <CustomTableBody>
            {algs?.map((alg, index) => {
              return (
                <Row
                  key={index}
                  onClick={() => {
                    dispatch(AlgActions.setAlg(alg));
                    navigate(`/algorithms/algorithm/${alg.ailment}`, {
                      state: {
                        preview: true,
                      },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell style={{ padding: 5 }}>{alg.ailment}</TableCell>
                  <TableCell style={{ padding: 5 }}>
                    {dayjs(alg.createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell style={{ padding: 5 }}>Owner</TableCell>
                </Row>
              );
            })}
          </CustomTableBody>
        </Table>
        <Footer
          value={query}
          handleChange={(e) => {
            setQuery((prev) => ({
              ...prev,
              ...e,
            }));
          }}
        />
      </CustomTableContainer>
    </>
  );
}
