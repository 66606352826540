import BasicAccordion from "../../../components/Buttons/collapsibleButton";
import React from "react";
import VoiceInput from "../../../components/Input/voiceInput";

export default function CommentInput({
  handleChange,
  title,
  label,
  placeholder,
  value,
  inAccordin = true,
}) {
  return (
    <React.Fragment>
      {inAccordin ? (
        <BasicAccordion title={title} isOpen={false}>
          <VoiceInput
            label={label}
            placeholder={placeholder}
            handleChange={handleChange}
            value={value}
          />
        </BasicAccordion>
      ) : (
        <VoiceInput
          label={label}
          placeholder={placeholder}
          handleChange={handleChange}
          value={value}
        />
      )}
    </React.Fragment>
  );
}
