export default {
    // state one
    index: {
        handleSelect: (index) => { return 'gender' },
        type: 'simple',
        question: 'Where would you like the appointment to take place?',
        formId: 'index',
        answers: [
            {
                text: 'inPerson'
            },
            {
                text: 'phone call'
            }
        ]
    },

    // //state two
    gender: {
        handleSelect: (index) => {
            if (index == 0) {
                return 'gred'
            }
            return 'pregnant'
        },
        type: 'simple',
        question: 'What is your gender?',
        formId: 'gender',
        answers: [
            { text: 'Male' },
            { text: 'Female' },
            { text: 'Other' }
        ],
        optionalKey: 2,
        label: "Please self-identify:",
        placeholder: "(optional)",
        inputType: "text",
        require: true
    },

    // //bt two tree

    pregnant: {
        handleSelect: (index) => { return 'breastfeeding' },
        type: 'simple',
        question: 'Are you (or could you be) pregnant?',
        formId: 'pregnant',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    breastfeeding: {
        path: 'breastfeeding',
        handleSelect: (index) => { return 'gred' },
        type: 'simple',
        question: 'Are you breastfeeding?',
        formId: 'breastfeeding',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    // //state tree
    gred: {
        path: 'gred',
        handleSelect: (index) => { return 'symptoms_1' },
        type: 'simple',
        question: 'Have you been officially diagnosed with heartburn (GERD or gastroesophageal reflux disease) before?',
        formId: 'gred',
        answers: [
            { text: 'yes' },
            { text: 'no' },
            { text: 'not sure' }
        ]
    },

    // //state for
    symptoms_1: {
        path: 'symptoms_1',
        handleSelect: (index) => { return 'symptoms_2' },
        type: 'multi',
        question: 'Are you experiencing any of these symptoms?',
        formId: 'symptoms_1',
        cancleAllIndex: 4,
        answers: [
            { text: 'Heartburn (uncomfortable burning feeling in your chest or throat)' },
            { text: 'An unpleasant, sour taste in your mouth' },
            { text: 'Bad breath' },
            { text: 'Belching/burping' },
            { text: 'None of the above' },
        ],
        require: true
    },
    // //state five
    symptoms_2: {
        path: 'symptoms_2',
        handleSelect: (index) => { return 'symptoms_3' },
        type: 'multi',
        question: 'Are you experiencing any of these symptoms?',
        formId: 'symptoms_2',
        cancleAllIndex: 7,
        answers: [
            { text: 'Chest pain' },
            { text: 'Difficulty or pain when swallowing' },
            { text: 'Unexplained weight loss' },
            { text: 'Vomiting' },
            { text: 'Tarry or black stools' },
            { text: 'Abnormal swelling in stomach region' },
            { text: 'Feeling dizzy, weak or tired' },
            { text: 'None of the above' },

        ],
        require: true
    },

    // //state six
    symptoms_3: {
        path: 'symptoms_3',
        handleSelect: (index) => { return 'first_exp' },
        type: 'simple',
        question: 'Do you have any other symptoms not mentioned in the previous questions?',
        formId: 'symptoms_3',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        placeholder: "additional symptomps you may have",
        inputType: "text",
        require: true
    },


    // //state seven
    first_exp: {
        path: 'first_exp',
        handleSelect: (index) => { return 'age' },
        type: 'simple',
        question: 'Is this the first time you experienced any of your symptoms?',
        formId: 'first_exp',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },


    // //state eight
    age: {
        handleSelect: (index) => { return 'daily_activity' },
        type: 'simple',
        question: 'Are you 50 years of age or older?',
        formId: 'age',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },

    // //state nine

    daily_activity: {
        path: 'daily_activity',
        handleSelect: (index) => { return 'week_exp' },
        type: 'simple',
        question: 'Are your symptoms severe or preventing you from performing your daily activities?',
        formId: 'daily_activity',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },


    week_exp: {
        path: 'week_exp',
        handleSelect: (index) => { return 'times' },
        type: 'simple',
        question: 'Do you experience your symptoms more than twice a week?',
        formId: 'week_exp',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },


    times: {
        path: 'times',
        handleSelect: (index) => { return 'conditions' },
        type: 'multi',
        question: 'Are there specific times when you experience your symptoms the most?',
        formId: 'times',
        cancleAllIndex: 5,
        answers: [
            { text: 'During the day' },
            { text: 'During the night' },
            { text: 'When lying down or bending over' },
            { text: 'After eating meals or specific foods' },
            { text: 'There is no specific time' },
            { text: 'None of the above' },

        ],
        require: true
    },

    conditions: {
        handleSelect: (index) => { return 'gastrointestinal' },
        type: 'multi',
        question: 'Do you have any of the following conditions?',
        formId: 'conditions',
        cancleAllIndex: -1,
        answers: [
            { text: 'History of stomach ulcers' },
            { text: 'Barrett’s esophagus' },
            { text: 'Hiatus hernia' },
            { text: 'None of the above' },
            { text: 'I don’t know' },
        ],
        require: true
    },

    gastrointestinal: {
        handleSelect: (index) => { return 'treatments' },
        type: 'simple',
        question: 'Do you have a first degree relative with gastrointestinal cancer?',
        formId: 'gastrointestinal',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },

    treatments: {
        path: 'treatments',
        handleSelect: (index) => {
            if (index == 1)
                return 'medications'
            else
                return 'feel_better'
        },
        type: 'simple',
        question: 'Have you tried any treatments for your symptoms?',
        formId: 'treatments',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },


    feel_better: {
        handleSelect: (index) => { return 'feel_worse' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel better?',
        formId: 'feel_better',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate which treatments helped",
        placeholder: "e.g. Tums",
        inputType: "text"
    },

    feel_worse: {
        handleSelect: (index) => { return 'effect' },
        type: 'simple',
        question: 'Did any of the treatments make your symptoms feel worse?',
        formId: 'feel_worse',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate which treatments made your symptoms feel worse",
        placeholder: "Type hear ...",
        inputType: "text"
    },

    effect: {
        path: 'effect',
        handleSelect: (index) => { return 'medications' },
        type: 'simple',
        question: 'Did any of the treatments have no effect on your symptoms?',
        formId: 'effect',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please indicate which ",
        placeholder: "Type hear ...",
        inputType: "text"
    },

    medications: {
        handleSelect: (index) => { return 'start_medications' },
        type: 'simple',
        question: 'Do you have any allergies to medications?',
        formId: 'medications',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: 'som lable',
        placeholder: 'placeHolder',
        inputType: 'addItem',
    },

    start_medications: {
        path: 'start_medications',
        handleSelect: (index) => { return 'anything' },
        type: 'simple',
        question: 'Have you started any new medications in the past month?',
        formId: 'start_medications',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ]
    },
    anything: {
        handleSelect: (index) => { return 'finish' },
        type: 'simple',
        question: 'Is there anything else we should know?',
        formId: 'anything',
        answers: [
            { text: 'Yes' },
            { text: 'No' },
            { text: 'Not Sure' }
        ],
        optionalKey: 0,
        label: "Please explain",
        placeholder: "Type hear ...",
        inputType: "text",
    }
}
