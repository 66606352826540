import Slider from ".";


export default function SliderQuestion({
    question,
    formId,
    min,
    max,
    step,
    formData
}) {

    return (
        <div
            style={{
                margin:".5rem 0",
                padding: ".5rem 1rem",
                width: "100%",
                borderBottom: "1px dashed gray",
            }}>
            <p style={{ fontWeight: "bold", fontSize: "14px" }}>{question}</p>
            <div style={{
                padding: ".5rem 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
            }}>
                <div class="slidecontainer"
                    style={{ alignItems: "center" }}>
                    <input type="range"
                        min={min}
                        max={max}
                        value={formData.value}
                        step={step}
                        class="slider"
                        id="myRange"
                        aria-label={formData.value}
                    />&nbsp;
                    <span style={{ fontSize: "14px" }}>{min} &#60; value:{formData.value} &#60; {max}</span>
                </div>
            </div>
            {(formData?.comment && formData.comment !== '') &&
                <p style={{ fontSize: "14px" }}>
                    <b style={{ fontSize: "14px" }}>comment:</b>&nbsp;
                    {formData.comment}
                </p>
            }
        </div>
    )
}