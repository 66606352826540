import Footer, {
  CustomTableBody,
  CustomTableContainer,
  TableHeader,
} from "../../components/table";
import { TableCell, TableRow, Paper } from "@material-ui/core";
import { useState, useEffect, useContext, useCallback, Fragment } from "react";
import Table, { Row } from "../../global/table";
import { ButtonContainer } from "../../components/Buttons/styles";
import Filter from "../../components/filter/filter";
import Navbar from "../../components/NavBar";
import MainFrame from "../../components/MainFrame";
import { Btn } from "../../components/Buttons/styles";
import styled from "@emotion/styled";
import colors from "../../global/colors";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { AddModal, CancleModal, EditModal } from "./modal";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { modalContext } from "../../components/modal/modal";
import useAppointmentApi from "../../api/appointment.api";
import dayjs from "dayjs";
const filters = [
  { label: "Patient", type: "name", collection: "patient" },
  { label: "Today", type: "boolean", collection: "today" },
];
function Appointments() {
  const { setShow, setContent } = useContext(modalContext);
  const [query, setQuery] = useState({
    page: 0,
    size: 20,
  });
  const { getAppointment } = useAppointmentApi();
  const [appointments, setAppointments] = useState([]);
  const dataLoader = useCallback(() => {
    let isActive = true;
    getAppointment(query)
      .then((result) => {
        if (isActive) setAppointments(result);
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      isActive = false;
    };
  }, [query]);
  const handleOpenModal = (type, ...args) => {
    if (type === "add") {
      setContent({
        title: "add new appointment",
        body: <AddModal dataLoader={dataLoader} />,
      });
    }
    if (type === "cancle") {
      setContent({
        title: "cancle appointment",
        body: <CancleModal dataLoader={dataLoader} appointmentId={args[0]} />,
      });
    }
    setShow(true);
  };
  const navigate = useNavigate();
  useEffect(dataLoader, [query]);

  const now = new Date();
  return (
    <>
      <Navbar topic="Appointments">
        <ButtonContainer>
          <Btn variant="contained" onClick={() => handleOpenModal("add")}>
            <AddIcon />
          </Btn>
        </ButtonContainer>
      </Navbar>
      <Filter
        convertor={"toMongoo"}
        filters={filters}
        dataLoader={(q) =>
          setQuery((prev) => ({
            page: prev.page,
            size: prev.size,
            ...q,
          }))
        }
      />
      <CustomTableContainer component={Paper} style={{ maxHeight: "70vh" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHeader>
            <TableRow>
              <TableCell style={{ textTransform: "capitalize" }} width="25%">
                Patient
              </TableCell>
              <TableCell style={{ textTransform: "capitalize" }} width="25%">
                type
              </TableCell>
              <TableCell style={{ textTransform: "capitalize" }} width="25%">
                Date
              </TableCell>
              <TableCell style={{ textTransform: "capitalize" }} width="25%">
                description
              </TableCell>
              <TableCell style={{ textTransform: "capitalize" }}>
                Cancel
              </TableCell>
            </TableRow>
          </TableHeader>
          <CustomTableBody>
            {appointments?.map((appointment, index) => {
              const appDate = new Date(appointment.date);
              const isActive = now < appDate;
              return (
                <Row
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() =>
                    navigate("/appointment", {
                      state: appointment,
                    })
                  }
                >
                  <TableCell style={{ textTransform: "capitalize",padding:10 }}>
                    {`${appointment.patient?.firstName} ${appointment.patient?.lastName}`}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize",padding:10 }}>
                    {appointment.inPlace ? "in person" : "online"}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize",padding:10 }}>
                    {dayjs(appointment.date).format("DD-MMM-YYYY")}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize",padding:10 }}>
                    {appointment.desc}
                  </TableCell>
                  {isActive ? (
                    <TableCell style={{padding:10}}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenModal("cancle", appointment._id);
                        }}
                      >
                        <CancelIcon/>
                      </IconButton>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <CancelIcon style={{ color: "rgb(199 199 199)" }} />
                    </TableCell>
                  )}
                </Row>
              );
            })}
          </CustomTableBody>
        </Table>
        <Footer
          value={query}
          handleChange={(e) => {
            setQuery((prev) => ({
              ...prev,
              ...e,
            }));
          }}
        />
      </CustomTableContainer>
    </>
  );
}

export default MainFrame(Appointments, true);
