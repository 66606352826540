import { createSlice } from "@reduxjs/toolkit";



export const notifSlice = createSlice({
    name: 'notif',
    initialState: {

    },
    reducers: {
        setNotif: (state, action) => {
            return {...action.payload};
        },
        addNotif: (state, action) => {

            const count = state[action.payload.notifType] ?
                state[action.payload.notifType] + 1 :
                1;

            state[action.payload.notifType] = count;
        },

        subNotif: (state, action) => {
            if (action.payload.notifType in state) {
                state[action.payload.notifType] -= 1;
            }
        },

        cleareNotif: (state, action) => {
            state[action.payload.notifType] = 0;
        },
        clearAll: (state, action) => {
            return {};
        }

    }
})


export const getNotif = (state, notifType) => {
    if (notifType in state)
        return state[notifType];
    else
        return 0;
}



export const {setNotif ,subNotif, addNotif, clearAll, cleareNotif } = notifSlice.actions;
export default notifSlice.reducer;