import MainFrame from "../../components/MainFrame";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Paper,
  TableCell,
  TableRow,
} from "@mui/material";
import styled from "@emotion/styled";
import TextContainer from "../../components/Texts/TextContainer";
import PersonIcon from "@mui/icons-material/Person";
import colors from "../../global/colors";
import { Fragment, useState } from "react";
import Filter from "../../components/filter/filter";
import { useEffect } from "react";
import { CalendarIcon } from "@mui/x-date-pickers";
import NavBar from "../../components/NavBar";
import Footer, {
  CustomTableBody,
  CustomTableContainer,
  TableHeader,
} from "../../components/table";
import Table, { Row } from "../../global/table";
import { Btn, ButtonContainer } from "../../components/Buttons/styles";
import { useContext } from "react";
import { modalContext } from "../../components/modal/modal";
import { useLocation, useNavigate } from "react-router-dom";
import useFormApi from "../../api/forms.api";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

// import { setForm as setFormContent } from "../../global/redux/form";

import AddModal from "./modal";
import { FormActiosn } from "../../global/redux/form";

import { usePdf } from "../../pdf";
import PrescriptionReceipt from "./components/pdf/prescription";

const filters = [{ label: "Title", type: "name", collection: "title" }];
const ListItemTitle = styled(ListItemText)({
  "& .css-13q35nq-MuiTypography-root": {
    fontSize: "1rem",
  },
});
function Form() {
  const [query, setQuery] = useState({
    page: 0,
    size: 20,
  });
  const { show, setShow, setContent, content } = useContext(modalContext);
  const [form, setForm] = useState(null);
  const [followUps, setFollowUps] = useState([]);
  const navigate = useNavigate();
  const locations = useLocation();
  const formId = locations.state.formId;
  const patient = locations.state.patient;
  const api = useFormApi();
  const dispatch = useDispatch();
  const loadForm = async (query = {}) => {
    api
      .getForm(formId)
      .then((data) => {
        setForm(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const loadFollowups = async (query = {}) => {
    api
      .getFormFollowups(formId, query)
      .then((data) => setFollowUps(data))
      .catch((err) => {
        console.log(err);
      });
  };
  //first time
  useEffect(() => {
    loadForm();
    loadFollowups();
  }, []);

  useEffect(() => {
    loadFollowups(query);
  }, [query]);

  const handleOpenModal = (type, ...args) => {
    if (type === "AddFollowUp")
      setContent({
        title: "Add New Follow Up",
        body: <AddModal formId={formId} dataLoader={loadFollowups} />,
      });
    else if (type === "editFollowUp")
      setContent({
        title: "Edit Follow Up",
        body: (
          <AddModal
            formId={formId}
            followup={args[0]}
            dataLoader={loadFollowups}
          />
        ),
      });
    setShow(true);
  };
  const { generatePdf } = usePdf();
  return (
    <Fragment>
      <NavBar topic={`${patient.userName}'s Form`}>
        <ButtonContainer>
          <Btn
            variant="contained"
            onClick={() => {
              dispatch(
                FormActiosn.setForm({
                  ...form.content,
                  patient: {
                    name: patient,
                    id: -1,
                  },
                })
              );
              navigate(`/forms/preview/${form.ailment}?preview=true`);
            }}
          >
            More Details
          </Btn>
          <Btn
            variant="contained"
            onClick={() => {
              generatePdf({
                component: PrescriptionReceipt,
                props: {
                  illness: form.ailment,
                  formState: form.content,
                },
              });
            }}
          >
            Prescription
          </Btn>
        </ButtonContainer>
      </NavBar>
      &nbsp;
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <List
          style={{
            width: "100%",
            background: colors.white,
            padding: "1rem",
            borderRadius: ".5rem",
            boxShadow: colors.boxShadow,
          }}
        >
          <TextContainer>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemTitle primary="Created By" />
            <TextContainer fontSize="1rem" color={colors.darkBlue}>
              {form?.isCreatedByOwner
                ? form?.owner?.userName
                : form?.patient?.userName}
            </TextContainer>
          </TextContainer>
          <TextContainer>
            <ListItemIcon>
              <CoronavirusIcon />
            </ListItemIcon>
            <ListItemTitle primary="Ailment" />
            <TextContainer fontSize="1rem" color={colors.darkBlue}>
              {form?.ailment}
            </TextContainer>
          </TextContainer>
          <TextContainer>
            <ListItemIcon>
              <CalendarIcon />
            </ListItemIcon>
            <ListItemTitle primary="Date" />
            <TextContainer fontSize="1rem" color={colors.darkBlue}>
              {dayjs(form?.createdAt).format("YYYY-MM-DD")}
            </TextContainer>
          </TextContainer>
        </List>
        &nbsp;
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextContainer justifyContent="flex-start">Follow Ups:</TextContainer>
          <Btn
            variant="contained"
            onClick={() => handleOpenModal("AddFollowUp")}
          >
            +Add Followup
          </Btn>
        </div>
        <Filter
          convertor={"toMongoo"}
          filters={filters}
          dataLoader={(q) =>
            setQuery((prev) => ({
              page: prev.page,
              size: prev.size,
              ...q,
            }))
          }
        />
        <CustomTableContainer component={Paper} style={{ maxHeight: "38vh" }}>
          <Table aria-label="simple table" stickyHeader>
            <TableHeader>
              <TableRow>
                <TableCell style={{ textTransform: "capitalize", padding: 10 }} width="43%">
                  Title
                </TableCell>
                <TableCell style={{ textTransform: "capitalize", padding: 10 }} width="43%">
                  Follow Up Date
                </TableCell>
                <TableCell style={{ textTransform: "capitalize", padding: 10 }}>
                  edit
                </TableCell>
              </TableRow>
            </TableHeader>
            <CustomTableBody>
              {followUps?.map((followup, index) => {
                return (
                  <Row
                    key={index}
                    onClick={() => {
                      navigate(`/followup/${followup._id}`, {
                        state: { followup, patient },
                      });
                    }}
                  >
                    <TableCell
                      style={{ fontSize:".9rem",padding: 7, textTransform: "capitalize", cursor: "pointer" }}
                    >
                      {followup.title}
                    </TableCell>
                    <TableCell
                      style={{ fontSize:".9rem",padding: 7, textTransform: "capitalize", cursor: "pointer" }}
                    >
                      {dayjs(followup.date).format("YYYY-MM-DD")}{" "}
                      {dayjs(followup.date).format("HH-mm")}
                    </TableCell>
                    <TableCell
                      style={{ fontSize:".9rem",padding: 7 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenModal("editFollowUp", followup);
                      }}
                    >
                      <IconButton style={{ padding: 0 }}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </Row>
                );
              })}
            </CustomTableBody>
          </Table>
          <Footer
            value={query}
            handleChange={(e) => {
              setQuery((prev) => ({ ...prev, ...e }));
            }}
          />
        </CustomTableContainer>
      </Box>
    </Fragment>
  );
}

export default MainFrame(Form, true);
