import TextContainer from "../../components/Texts/TextContainer";
import { Box, Button, InputAdornment, useMediaQuery } from "@mui/material";
import { CustomTextField } from "../../components/TextFields";
import { useRef, useState } from "react";
import { TOPIC } from "../../components/Texts/styles";
import colors from "../../global/colors";
import { FullWidthButton } from "../../components/Buttons/styles";
import useLoginController from "./loginController";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import MailIcon from "@mui/icons-material/Mail";
import { FormBox } from "../../global/table";
import { useLocation, useNavigate } from "react-router-dom";
import Verification from "./verification";

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

export default function SignUp() {
  const [verification, setVerification] = useState(true);
  const {
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const isSmallScreen = useMediaQuery("(max-width:868px)");
  const controller = useLoginController();
  const navigate = useNavigate();
  return (
    <div
      style={{
        background: colors.lightBgGray0,
        height: "100vh",
        width: "100%",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {verification ? (
        <FormBox isSmallScreen={isSmallScreen}>
          <Box sx={{ width: "100%" }}>
            <img
              alt="logo"
              src={require("../../assets/images/logo.png")}
              width="20%"
              height="20%"
              style={{ minWidth: "2rem", minHeight: "2.5rem" }}
            />
            <TextContainer {...TOPIC}>PharmaCanada</TextContainer>
          </Box>
          <TextContainer fontSize=".9rem" color="#555">
            hello! welcome to PharmaCanada enter you email to join us.
          </TextContainer>
          <CustomTextField
            label="Email"
            type="email"
            variant="outlined"
            error={!!errors.email}
            onChange={(e) => setValue("email", e.target.value)}
            helperText={errors.email?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MailIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            onClick={handleSubmit((data) => {
              controller
                .requestCode(data.email)
                .then(() => {
                  setVerification(false);
                })
                .catch((err) => { });
            })}
            // onClick={handleSubmit((data) => setVerification(false))}
            style={FullWidthButton}
          >
            Confirm
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              gap: ".5rem",
            }}
          >
            <TextContainer
              fontSize=".9rem"
              justifyContent="left"
              margin="0 0 0 1rem"
            >
              i have an active account.
            </TextContainer>
            <TextContainer
              fontSize=".85rem"
              cursor="pointer"
              color={colors.darkBlue}
              textDecoration="underline"
              justifyContent="left"
              onClick={() => navigate("/")}
            >
              login.
            </TextContainer>
          </div>
        </FormBox>
      ) : (
        <Verification
          text={`Please enter verification code we sent to ${getValues().email
            }.`}
          handleVerify={(code) => {
            if (code.length === 6) {
              controller.validateCode(getValues("email"), code, () => {
                navigate("/initial-settings", {
                  state: { email: getValues("email") },
                });
              });
            }
          }}
        />
      )}
    </div>
  );
}
