import Navbar from "../../components/NavBar";
import { TableCell, TableRow, Paper, Button } from '@material-ui/core';
import { useState, useContext, useEffect } from "react";
import Table, { Row } from "../../global/table";
import { modalContext } from "../../components/modal/modal";
import { AddModal, DeleteModal } from "./modal";
import Filter from "../../components/filter/filter";
import Footer, { CustomTableBody, CustomTableContainer, TableHeader } from "../../components/table";
import MainFrame from "../../components/MainFrame";
import { Btn, ButtonContainer } from "../../components/Buttons/styles";
import AddIcon from '@mui/icons-material/Add';
const filters = [
    { label: "name", type: 'name', collection: 'name' },
    { label: "title", type: 'name', collection: 'title' },
    { label: "date", type: 'name', collection: 'title' },
]
const reminders = [{}, {}, {}, {}, {}]
function Reminder() {
    const [reminder, setReminder] = useState([])
    const [querySize, setQuerySize] = useState({
        page: 0,
        size: 20
    })
    const { setContent, setShow } = useContext(modalContext);
    const loadReminders = async (query = {}) => {
        //api
    }

    const handleOpenModal = (type, organ) => {
        if (type == 'delete')
            setContent({
                title: "remove reminder",
                body: <DeleteModal id={organ._id} dataLoader={loadReminders} />
            })
        else if (type == 'Add') {
            setContent({
                title: "add new reminder",
                body: <AddModal dataLoader={loadReminders} />
            })
        }
        else {
            setContent({
                title: "edit reminder",
                body: <AddModal organ={organ} dataLoader={loadReminders} />
            })
        }
        setShow(true);
    }

    useEffect(() => {
        loadReminders();
    }, []);

    return (
        <>
            <Navbar topic="Reminders" >
                <ButtonContainer  >
                    <Btn variant="contained" onClick={() => handleOpenModal('Add')}><AddIcon /></Btn>&nbsp;
                </ButtonContainer>
            </Navbar>
            <Filter filters={filters} dataLoader={loadReminders} />
            <CustomTableContainer component={Paper} style={{ maxHeight: "70vh" }}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHeader>
                        <TableRow>
                            <TableCell width="20%" >title</TableCell>
                            <TableCell width="20%" >date</TableCell>
                            <TableCell width="20%" >time</TableCell>
                            <TableCell width="20%" >patient</TableCell>
                            <TableCell width="20%" >description</TableCell>
                            <TableCell >edit</TableCell>
                            <TableCell >delete</TableCell>
                            <TableCell >export</TableCell>
                        </TableRow>
                    </TableHeader>
                    <CustomTableBody>
                        {reminders?.map((reminder, index) => {
                            return (
                                <Row key={index}>
                                    <TableCell>{reminder.name}</TableCell>
                                    <TableCell >{reminder.date}</TableCell>
                                    <TableCell >{reminder.time}</TableCell>
                                    <TableCell >{reminder.patient}</TableCell>
                                    <TableCell >{reminder.description}</TableCell>
                                    <TableCell >
                                        <img src={require("../../assets/images/edit.png")} onClick={() => handleOpenModal('edit', reminder)} width="20px" style={{ cursor: "pointer" }} />
                                    </TableCell>
                                    <TableCell>
                                        <img src={require("../../assets/images/trash.png")} onClick={() => handleOpenModal('delete', reminder)} width="20px" style={{ cursor: "pointer" }} />
                                    </TableCell>
                                    <TableCell>
                                        <img src={require("../../assets/images/export.png")} width="22px" style={{ cursor: "pointer" }} />
                                    </TableCell>
                                </Row>
                            )
                        })}
                    </CustomTableBody>
                </Table>
                <Footer value={querySize} handleChange={(e) => {
                    setQuerySize(e)
                }} />
            </CustomTableContainer>
        </>
    )
}


export default MainFrame(Reminder, true);

