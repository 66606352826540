import { useState, useContext, Fragment } from "react";
import BasicDateTimePicker from "../../components/Input/dateTimeInput";
import { CustomDateInput, CustomTextField } from "../../components/TextFields";
import { modalContext } from "../../components/modal/modal";
import { Button, Switch } from "@material-ui/core";
import {
  ButtonContainer,
  NegativeButton,
  PositiveButton,
} from "../../components/Buttons/styles";
import { FormControlLabel, FormGroup } from "@mui/material";
import useFormApi from "../../api/forms.api";
import dayjs from "dayjs";
import VoiceInput from "../../components/Input/voiceInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const Schema = yup.object().shape({
  title: yup.string().required(),
  date: yup.string().required(),
  desc: yup.string(),
});

export default function AddModal({ formId, followup, dataLoader }) {
  const { show, setShow } = useContext(modalContext);
  const {
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      ...followup,
      date: followup ? dayjs(followup.date) : null,
    },
  });

  const api = useFormApi();
  const add = (data) => {
    api
      .addFollowup(formId, data)
      .then(() => {
        dataLoader();
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const edit = (data) => {
    api
      .editFollowup(followup._id, data)
      .then(() => {
        dataLoader();
        setShow(false);
      })
      .catch((err) => console.log(err));
  };
  const onSubmit = followup ? edit : add;
  return (
    <>
      <CustomTextField
        label="Title"
        value={watch("title")}
        onChange={(e) => setValue("title", e.target.value)}
        variant="outlined"
      />
      <BasicDateTimePicker
        value={watch("date")}
        label="Scheduled for"
        onChange={(value) => setValue("date", value)}
      />

      <VoiceInput
        label={"Description"}
        placeholder={"Add your Description..."}
        key={"desc"}
        value={watch("desc")}
        handleChange={(value) => {
          setValue("desc", value);
        }}
      />
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          canel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={handleSubmit(onSubmit)}
        >
          {followup ? "Save" : "Add"}
        </Button>
      </ButtonContainer>
    </>
  );
}
