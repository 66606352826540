import { CustomTextField } from "../TextFields";
import { ButtonContainer } from "../Buttons/styles";
import { Button } from "@material-ui/core";
import { PositiveButton, NegativeButton } from "../Buttons/styles";
import React, { useContext, useState } from "react";
import { modalContext } from "../modal/modal";
import ActiveFilterBtn from "./ActiveFilterBtn";
import CloseIcon from "@mui/icons-material/Close";

const FilterModal = ({ onConfirm, label, value }) => {
  const { setShow } = useContext(modalContext);
  const [name, setName] = useState(value);
  return (
    <React.Fragment>
      <CustomTextField
        variant="outlined"
        label={label}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          onClick={() => {
            onConfirm(name);
          }}
          style={PositiveButton}
        >
          Apply
        </Button>
      </ButtonContainer>
    </React.Fragment>
  );
};
const render = (filter, onDelete) => {
  return (
    <ActiveFilterBtn onDelete={onDelete}>
      <CloseIcon style={{ fontSize: "15px" }} />
      &nbsp; {filter?.value}
    </ActiveFilterBtn>
  );
};
const createFilterQuery = (query, filter) => {
  if (!query[filter.collection]) {
    query[filter.collection] = {
      value: [],
      toMongoo: function () {
        return `(${this.value.join("|")})`;
      },
      toSQL: function () {
        return this.value;
      },
    };
  }
  query[filter.collection].value.push(filter.value);
};
export default {
  modal: FilterModal,
  single: false,
  render,
  createFilterQuery,
};
