import styled from '@emotion/styled'
import React from 'react'
import colors from '../../../../global/colors'


const MainContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0 1rem;
`
const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin:2rem;
  :before {
    content: '';
    position: absolute;
    background: ${colors.lightGray};
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: green;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`
const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`
const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${colors.white};
  border: 3px solid ${({ step }) =>
        step === 'completed' ? "green" : colors.lightGray};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StepCount = styled.span`
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`
const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
`
const StepLabel = styled.span`
  font-size: 14px;
  text-transform:capitalize;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`
const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: green;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`
const steps = [
    {
        label: 'IDLE',
        step: 0,
    },
    {
        label: 'Accepted',
        step: 1,
    },
    {
        label: 'Sent',
        step: 2,
    },
]

export default function Multistepprogressbar({ activeStep, nextStep }) {
    
    const totalSteps = steps.length
    const width = `${(100 / (totalSteps - 1)) * (activeStep )}%`
    return (
        <MainContainer>
            <StepContainer width={width}>
                {steps.map(({ step, label }) => (
                    <StepWrapper key={step}>
                        <StepStyle style={{ cursor: "pointer" }}
                            onClick={() => nextStep(step, label)}
                            step={activeStep >= step ? 'completed' : 'incomplete'}>
                            {activeStep >= step ? (
                                <CheckMark>L</CheckMark>
                            ) : (
                                <StepCount

                                >
                                    {step}
                                </StepCount>
                            )}
                        </StepStyle>
                        <StepsLabelContainer>
                            <StepLabel key={step}>
                                {label}
                            </StepLabel>
                        </StepsLabelContainer>
                    </StepWrapper>
                ))}
            </StepContainer>
            
        </MainContainer>
    )
}
