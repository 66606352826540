import { useMediaQuery } from "@material-ui/core";
// import TextContainer from "../../../components/Texts/TextContainer";
import TextContainer from "../../../../components/Texts/TextContainer";
import { TOPIC } from "../../../../components/Texts/styles";
import styled from "@emotion/styled";
import colors from "../../../../global/colors";
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";

// import { getFormData, setFormData } from "../../../../global/redux/form.action"
import { CustomTextField } from "../../../../components/TextFields";
import BasicAccordion from "../../../../components/Buttons/collapsibleButton";
import { Box, Slider } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CommentInput from "../commentInput";
import { FormActiosn } from "../../../../global/redux/form";
import Layout from "../edit/layout";
import { isEqual } from "lodash";

const QuestionBox = styled(Box)({
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
  textAlign: "center",
  backdropFilter: "blur(10px)",
  zIndex: 2,
  maxHeight: "65vh",
  overflow: "auto",
  height: "fit-content",
  borderRadius: ".5rem",
  boxShadow: colors.mainShadow,
});

function SliderQuestion({
  question,
  min,
  max,
  step,
  formId,
  handleSelect,
  formNavigator,
  label,
  prefix,
  defaultValue,

  dispatch,
}) {
  const [formData, setData] = useState({
    value: min,
    comment: "",
    ...defaultValue,
  });

  const { value, comment } = formData;

  useEffect(() => {
    setData({
      value: min,
      comment: "",
      ...defaultValue,
    });
  }, [formId, defaultValue]);

  const onChange = (key, value) => {
    setData({
      ...formData,
      [key]: value,
    });
  };

  const onGoNext = () => {
    dispatch(
      FormActiosn.updateFormData({
        formId,
        formData,
      })
    );
    formNavigator(handleSelect(1));
  };

  return (
    <Layout
      firstButton={{
        text: "Previous",
        onClick: () => formNavigator(-1),
      }}
      secondButton={{
        text: "Next",
        onClick: onGoNext,
      }}
    >
      <QuestionBox>
        <TextContainer
          {...TOPIC}
          style={{
            position: "sticky",
            top: 0,
            textAlign: "center",
            width: "100%",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          {question}
        </TextContainer>
        &nbsp;
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: "2rem",
            marginBottom: "1rem",
            padding: "0 1rem",
          }}
        >
          <Slider
            color="secondary"
            aria-label={label}
            value={value}
            onChange={(e, value) => onChange("value", value)}
            getAriaValueText={(value) => `${value}${prefix}`}
            valueLabelDisplay="auto"
            step={step}
            min={min}
            max={max}
            marks
          />
        </Box>
        <CommentInput
          handleChange={(value) => onChange("comment", value)}
          title="Comment"
          label="Description"
          value={comment}
          placeholder="Add Your Description Here"
        />
      </QuestionBox>
    </Layout>
  );
}

function mapStateToProps(state, ownProps) {
  let formData = state.forms.data[ownProps.formId];
  formData = formData ? formData : {};
  return {
    ...ownProps,
    defaultValue: {
      ...ownProps.defaultValue,
      ...formData,
    },
  };
}

export default connect(mapStateToProps)(
  memo(SliderQuestion, function (prev, next) {
    return isEqual(prev, next);
  })
);
