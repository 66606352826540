import React from "react";
import { Line } from "recharts";
import dayjs from "dayjs";
import { Box, Paper, TableCell, TableRow } from "@mui/material";
// import useTrackApi from "../../../api/track.api";
import LineChart from "../../forms/components/charts/lineChart";
import {
  CustomTableBody,
  CustomTableContainer,
  TableHeader,
} from "../../../components/table";
import Table, { Row } from "../../../global/table";
import colors from "../../../global/colors";

export default function TrackPdf({ values }) {
  return (
    <Box
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <LineChart
        data={values.map((value) => ({
          name: dayjs(value.updatetAt).format("DD HH:mm"),
          value: Number(value.value),
        }))}
        XAxisDataKey="name"
      >
        <Line
          type="linear"
          dot={{ r: 5 }}
          dataKey="value"
          stroke={colors.darkBlue}
        />
      </LineChart>
      <CustomTableContainer component={Paper} style={{ maxHeight: "35vh" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHeader>
            <TableRow>
              <TableCell style={{ padding: 5 }} width="50%">
                Date
              </TableCell>
              <TableCell style={{ padding: 5 }} width="50%">
                Value
              </TableCell>
            </TableRow>
          </TableHeader>
          <CustomTableBody>
            {values?.map((track, index) => {
              const updatetAt = dayjs(track.updatedAt);
              return (
                <Row style={{ cursor: "pointer" }} key={index}>
                  <TableCell style={{ padding: 5, fontSize: "1rem" }}>
                    {updatetAt.format("YYYY-MMM-DD")}&nbsp;&nbsp;
                    <span style={{ color: colors.darkBlue, fontSize: ".9rem" }}>
                      {updatetAt.format("HH:mm")}
                    </span>
                  </TableCell>
                  <TableCell style={{ padding: 5, fontSize: "1rem" }}>
                    {track.value}
                  </TableCell>
                </Row>
              );
            })}
          </CustomTableBody>
        </Table>
      </CustomTableContainer>
    </Box>
  );
}
