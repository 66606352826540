import React, { useContext } from "react";
import Navbar from "../../components/NavBar";
import { Btn, ButtonContainer } from "../../components/Buttons/styles";
import AddIcon from "@mui/icons-material/Add";
import MainFrame from "../../components/MainFrame";
import { styled } from "@mui/styles";
import { Box, List, ListItemIcon, ListItemText } from "@mui/material";
import TextContainer from "../../components/Texts/TextContainer";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PhoneIcon from "@mui/icons-material/Phone";
import { useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import colors from "../../global/colors";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
import AlgorithmsTable from "./table";
import { modalContext } from "../../components/modal/modal";
import CreateAlgoModal from "./modal";
const InformationBox = styled(Box)({
  width: "100%",
  padding: "0 1rem",
  borderRadius: ".5rem",
  display: "flex",
  marginBottom: "1rem",
  flexDirection: "column",
  background: "#fff",
  justifyContent: "space-between",
  alignItems: "center",
});
const CustomText = styled(ListItemText)({
  "&& .MuiTypography-root": {
    fontSize: "1rem",
  },
});
const filters = [{ label: "Ailment", type: "name", collection: "illness" }];

function Algorithms() {
  const location = useLocation();
  const patient = location.state;
  const { setShow, setContent } = useContext(modalContext);
  function handleOpenModal(type) {
    if (type === "add") {
      setContent({
        title: "Add New Algorithm",
        body: <CreateAlgoModal patient={patient} />,
      });
    }
    setShow(true);
  }
  return (
    <React.Fragment>
      <Navbar topic={`${patient.firstName} ${patient.lastName}'s Algorithms`}>
        <ButtonContainer>
          <Btn variant="contained" onClick={() => handleOpenModal("add")}>
            <AddIcon />
          </Btn>
        </ButtonContainer>
      </Navbar>
      <InformationBox>
        <List style={{ width: "100%" }}>
          <TextContainer>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <CustomText primary="Name" />
            &nbsp;
            <TextContainer fontSize="1rem" color={colors.darkBlue}>
              {" "}
              {`${patient.firstName} ${patient.lastName}`}
            </TextContainer>
          </TextContainer>
          <TextContainer>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <CustomText primary="Phone Number" />
            &nbsp;
            <TextContainer fontSize="1rem" color={colors.darkBlue}>
              {formatPhoneNumber(patient.phone)}
            </TextContainer>
          </TextContainer>
          <TextContainer>
            <ListItemIcon>
              <EventAvailableIcon />
            </ListItemIcon>
            <CustomText primary="Birthday" />
            &nbsp;
            <TextContainer fontSize="1rem" color={colors.darkBlue}>
              {" "}
              {patient.birthDay?.split("T")[0]}
            </TextContainer>
          </TextContainer>
        </List>
      </InformationBox>
      <AlgorithmsTable
        patientId={patient._id}
        filters={filters}
        patient={`${patient.firstName} ${patient.lastName}`}
      />
    </React.Fragment>
  );
}

export default MainFrame(Algorithms);
