import Footer, {
  CustomTableBody,
  CustomTableContainer,
  TableHeader,
} from "../../components/table";
import { TableCell, TableRow, Paper } from "@material-ui/core";
import { useState, useContext, useEffect } from "react";
import Table, { Row } from "../../global/table";
import { modalContext } from "../../components/modal/modal";
import { ButtonContainer } from "../../components/Buttons/styles";
import Filter from "../../components/filter/filter";
import Navbar from "../../components/NavBar";
import MainFrame from "../../components/MainFrame";
import { Btn } from "../../components/Buttons/styles";
import styled from "@emotion/styled";
import colors from "../../global/colors";
import { useNavigate } from "react-router-dom";
import useFormApi from "../../api/forms.api";
import dayjs from "dayjs";
import CreateFormModal from "../Patients/modal";

const filters = [
  { label: "Patient", type: "name", collection: "patient" },
  { label: "Ailment", type: "name", collection: "illness" },
];

function Forms() {
  const [query, setQuery] = useState({
    page: 0,
    size: 20,
  });
  const { setShow, setContent } = useContext(modalContext);
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();
  const api = useFormApi();

  function handleOpenModal(type) {
    if (type === "createform") {
      setContent({
        title: "select Ailment",
        body: <CreateFormModal />,
      });
    }
    setShow(true);
  }
  const loadForms = async (query = {}) => {
    api
      .getOwnerForms(query)
      .then((data) => {
        setForms(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // //first time
  // useEffect(() => {
  //   loadForms();
  // }, []);

  useEffect(() => {
    loadForms(query);
  }, [query]);

  return (
    <>
      <Navbar topic="Forms">
        <ButtonContainer>
          <Btn
            variant="contained"
            onClick={() => handleOpenModal("createform")}
          >
            + Ailment assessment
          </Btn>
        </ButtonContainer>
      </Navbar>
      <Filter
        convertor={"toMongoo"}
        filters={filters}
        dataLoader={(query) => {
          setQuery((prev) => ({ page: prev.page, size: prev.size, ...query }));
        }}
      />
      <CustomTableContainer component={Paper} style={{ maxHeight: "70vh" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHeader>
            <TableRow>
              <TableCell style={{ textTransform: "capitalize" }} width="25%">
                Patient
              </TableCell>
              <TableCell style={{ textTransform: "capitalize" }} width="25%">
                Created By
              </TableCell>
              <TableCell style={{ textTransform: "capitalize" }} width="25%">
                Ailment
              </TableCell>
              <TableCell style={{ textTransform: "capitalize" }} width="25%">
                Date
              </TableCell>
            </TableRow>
          </TableHeader>
          <CustomTableBody>
            {forms?.map((form, index) => {
              return (
                <Row key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/form", {
                      state: {
                        formId: form._id,
                        patient: form.patient,
                      },
                    });
                  }}>
                  <TableCell
                    style={{ textTransform: "capitalize" }}
                  >
                    {form.patient.userName}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {form.isCreatedByOwner
                      ? form.owner.userName
                      : form.patient.userName}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {form.ailment}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {dayjs(form.createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                </Row>
              );
            })}
          </CustomTableBody>
        </Table>
        <Footer
          value={query}
          handleChange={(e) => {
            setQuery((prev) => ({ ...prev, ...e }));
          }}
        />
      </CustomTableContainer>
    </>
  );
}

export default MainFrame(Forms, true);
