import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CustomTextField } from '../TextFields';
import { InputAdornment } from '@mui/material';
import { useState } from 'react';


export function PasswordInput({ error, helperText, onChange, label }) {
    const [show, setShow] = useState(false)
    return (
        <CustomTextField
            label={label}
            type={show ? "text" : "password"}
            variant="outlined"
            error={error}
            onChange={(e) => onChange(e.target.value)}
            helperText={helperText}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" style={{cursor:"pointer"}} onClick={() => setShow(!show)}>
                        {show ?
                            <VisibilityIcon style={{fontSize:"1.2rem"}} /> :
                            <VisibilityOffIcon style={{fontSize:"1.2rem"}} />}
                    </InputAdornment>
                )
            }}
        />
    )
}