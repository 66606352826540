import { connect, useSelector } from "react-redux";
import CollapsibleButton from "../../../../components/Buttons/collapsibleButton";
import TextContainer from "../../../../components/Texts/TextContainer";
import { IconButton, Typography } from "@mui/material";
import { Grid, List, ListItem } from "@material-ui/core";
import { memo } from "react";
import { isEqual } from "lodash";
import colors from "../../../../global/colors";
import EditIcon from '@mui/icons-material/Edit';


export default function MultiSelectQuestion({
    question,
    answers,
    formId,
    onEdit,
    formData
}) {
    return (
        <CollapsibleButton
            titleComponent={() => {
                return (
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <TextContainer>
                            <Typography textTransform="capitalize" fontSize="1rem">
                                {question}
                            </Typography>
                        </TextContainer>
                        <IconButton style={{ marginRight: "1rem" }}
                            onClick={(e) => {
                                e.stopPropagation()
                                onEdit(formId)
                            }}
                        >
                            <EditIcon
                                style={{ color: colors.darkBlue }}
                                fontSize="22px" />
                        </IconButton>
                    </div>
                )
            }}

            isOpen={false}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", maxHeight: "35vh", overflow: "auto" }}>
                <TextContainer>
                    <Typography borderBottom="1px solid #EEE" marginBottom="2%" paddingBottom="1%" width="100%" textAlign="left" textTransform="capitalize" fontSize="1rem">
                        <b>ansawer:</b>&nbsp;
                        <List>
                            {
                                formData?.selectedItems.map((item, indx) => {
                                    return (
                                        <ListItem>&#x2022;&nbsp;{answers[indx].text}</ListItem>
                                    )
                                })
                            }
                        </List>
                    </Typography>
                </TextContainer>

                <TextContainer>
                    <Typography width="100%" textAlign="left" textTransform="capitalize" fontSize="1rem">
                        <b style={{fontSize:"14px"}}>comment:</b>&nbsp;
                        {formData?.comment}
                    </Typography>
                </TextContainer>
            </div>
        </CollapsibleButton>
    )

}
