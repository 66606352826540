import { useContext } from "react"
import { Button } from "@material-ui/core"
import styled from "@emotion/styled"
import { modalContext } from "../../../../components/modal/modal"
import { ButtonContainer, NegativeButton, PositiveButton } from "../../../../components/Buttons/styles"

const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
`
export const ChangeStateModal = ({ step,stepLabel, GoToState }) => {
    const { setShow } = useContext(modalContext)
    function handleSetState() {
        GoToState();
        setShow(false)
    }
    return (
        <Container>
            <p style={{ textTransform: "capitalize",width:"100%",textAlign:"center" }}>are you sure you want to go to {stepLabel} state ?</p>&nbsp;
            <ButtonContainer>
                <Button variant="outlined" onClick={() => setShow(false)} style={NegativeButton}>cancel</Button>&nbsp;
                <Button variant="contained" style={PositiveButton} onClick={handleSetState} >yes</Button>
            </ButtonContainer>
        </Container>
    )
}