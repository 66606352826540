import React, { useContext } from "react";
import { modalContext } from "../../components/modal/modal";
import { Button } from "@mui/material";
import TextContainer from "../../components/Texts/TextContainer";
import {
  ButtonContainer,
  NegativeButton,
  PositiveButton,
} from "../../components/Buttons/styles";

export function DeleteFollowUpModal({ onDelete }) {
  const { setShow } = useContext(modalContext);
  return (
    <React.Fragment>
      <TextContainer
        style={{
          marginBottom: "1rem",
        }}
      >
        {" "}
        Are you sure you want to delete this followup?
      </TextContainer>
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          Cancel
        </Button>
        &nbsp;
        <Button variant="contained" onClick={onDelete} style={PositiveButton}>
          Delete
        </Button>
      </ButtonContainer>
    </React.Fragment>
  );
}
