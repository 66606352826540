import MainFrame from "../../components/MainFrame";
import PatientForms from "./patientForms";
import EditableList from "./editableList";
import { Box, Button, List, ListItemIcon, ListItemText } from "@mui/material";
import styled from "@emotion/styled";
import TextContainer from "../../components/Texts/TextContainer";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import colors from "../../global/colors";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { Btn, ButtonContainer } from "../../components/Buttons/styles";
import { modalContext } from "../../components/modal/modal";
import CreateFormModal, { PatientInfo } from "./modal";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
const filters = [{ label: "Ailment", type: "name", collection: "illness" }];
const CustomText = styled(ListItemText)({
  "&& .MuiTypography-root": {
    fontSize: "1rem",
  },
});
const InformationBox = styled(Box)({
  width: "100%",
  padding: "0 1rem",
  borderRadius: ".5rem",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  justifyContent: "space-between",
  alignItems: "center",
});
const Container = styled(Box)({
  width: "100%",
  paddingTop: ".5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
});
function Patient() {
  const { setShow, setContent } = useContext(modalContext);
  const [open] = useState(false);
  const location = useLocation();
  const patient = location.state;
  const navigate = useNavigate();
  function handleOpenModal(type) {
    if (type === "create") {
      setContent({
        title: "Create New Form",
        body: (
          <CreateFormModal
            patient={{
              _id: patient._id,
              userName: `${patient.firstName} ${patient.lastName}`,
              phone: patient.phone,
              address: patient.address,
              postalCode: patient.postalCode,
              gender: patient.gender,
              birthDay: patient.birthDay,
            }}
          />
        ),
      });
    } else if (type === "more") {
      setContent({
        title: "patient informations",
        body: <PatientInfo patient={patient} />,
      });
    }
    setShow(true);
  }
  return (
    <>
      <NavBar topic={`Patient ${patient.firstName} ${patient.lastName}`}>
        <ButtonContainer>
          <Btn variant="contained" onClick={() => handleOpenModal("create")}>
            + Ailment assessment
          </Btn>
          <Btn
            variant="contained"
            onClick={() => {
              navigate("/tracks", { state: patient });
            }}
          >
            Tracks
          </Btn>
        </ButtonContainer>
      </NavBar>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <InformationBox>
          <List style={{ width: "100%" }}>
            <TextContainer>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <CustomText primary="Name" />
              &nbsp;
              <TextContainer fontSize="1rem" color={colors.darkBlue}>
                {" "}
                {`${patient.firstName} ${patient.lastName}`}
              </TextContainer>
            </TextContainer>
            <TextContainer>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <CustomText primary="Phone Number" />
              &nbsp;
              <TextContainer fontSize="1rem" color={colors.darkBlue}>
                {formatPhoneNumber(patient.phone)}
              </TextContainer>
            </TextContainer>
            <TextContainer>
              <ListItemIcon>
                <EventAvailableIcon />
              </ListItemIcon>
              <CustomText primary="Birthday" />
              &nbsp;
              <TextContainer fontSize="1rem" color={colors.darkBlue}>
                {" "}
                {patient.birthDay?.split("T")[0]}
              </TextContainer>
            </TextContainer>
            <TextContainer
              onClick={() => handleOpenModal("more")}
              textDecoration="underline"
              fontSize=".9rem"
              color="gray"
              width="100%"
              justifyContent="right"
              cursor="pointer"
            >
              more info
            </TextContainer>
            <EditableList patientId={patient._id} />
          </List>
        </InformationBox>
        <Container>
          <PatientForms
            open={open}
            patientId={patient._id}
            filters={filters}
            patient={`${patient.firstName} ${patient.lastName}`}
          />
        </Container>
      </Box>
    </>
  );
}

export default MainFrame(Patient, true);
