import { Fragment, useEffect, useState } from "react";
import MainFrame from "../../components/MainFrame";
import NavBar from "../../components/NavBar";
import { Btn } from "../../components/Buttons/styles";
import {
  Box,
  InputAdornment,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import TextContainer from "../../components/Texts/TextContainer";
import PersonIcon from "@mui/icons-material/Person";
import colors from "../../global/colors";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useParams } from "react-router-dom";
import useFormApi from "../../api/forms.api";
import SickIcon from "@mui/icons-material/Sick";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import { CustomTextField } from "../../components/TextFields";
import dayjs from "dayjs";
function Followup() {
  const [editable, setEditable] = useState(false);
  const [comment, setComment] = useState();
  const { followupId } = useParams();
  const {
    state: { followup, patient },
  } = useLocation();
  useEffect(() => {
    setComment(followup?.comment);
  }, [followup?.comment]);
  const api = useFormApi();
  const loadFollowup = () => {
    // api.getFollowup(followupId)
    //     .then(data => {
    //         setFollowup(data)
    //         setComment(data.comment)
    //     })
    //     .catch(err => console.log(err));
  };
  // useEffect(() => {
  //     loadFollowup();
  // }, [])

  const handelSubmit = () => {
    api
      .editFollowup(followupId, {
        comment,
      })
      .then(() => {
        setEditable(false);
        loadFollowup();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Fragment>
      <NavBar topic={followup?.title} />
      &nbsp;
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <List
          style={{
            width: "100%",
            background: colors.white,
            padding: "1rem",
            borderRadius: ".5rem",
          }}
        >
          <TextContainer>
            <ListItemIcon>
              <SickIcon />
            </ListItemIcon>
            <ListItemText primary="Patient" />
            &nbsp;
            <TextContainer fontSize="1rem" color={colors.darkBlue}>
              {patient.userName}
            </TextContainer>
          </TextContainer>
          <TextContainer>
            <ListItemIcon>
              <CalendarMonthIcon />
            </ListItemIcon>
            <ListItemText primary="Date" />
            &nbsp;
            <TextContainer fontSize="1rem" color={colors.darkBlue}>
              {dayjs(followup?.date).format("DD/MM/YYYY")}
            </TextContainer>
          </TextContainer>
          {/* <TextContainer>
            <ListItemIcon>
              <InterpreterModeIcon />
            </ListItemIcon>
            <ListItemText primary="InPerson" />
            &nbsp;
            <TextContainer fontSize="1rem" color={colors.darkBlue}>
              {followup?.inPerson ? "yes" : "no"}
            </TextContainer>
          </TextContainer> */}
          <TextContainer>
            <ListItemIcon>
              <TextSnippetIcon />
            </ListItemIcon>
            <ListItemText primary="Description" />
            &nbsp;
            <TextContainer fontSize="1rem" color={colors.darkBlue}>
              {followup?.desc}
            </TextContainer>
          </TextContainer>
        </List>
        &nbsp;
        <CustomTextField
          disabled={!editable}
          label="comment"
          multiline
          minRows={10}
          maxRows={20}
          variant="outlined"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ position: "absolute", right: 0, bottom: "15%" }}
              >
                <Btn
                  variant="contained"
                  onClick={() => {
                    if (editable) {
                      return handelSubmit();
                    }
                    setEditable(!editable);
                  }}
                >
                  {editable ? "save changes" : <EditIcon />}
                </Btn>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Fragment>
  );
}

export default MainFrame(Followup, true);
