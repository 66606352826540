import React, { useState, useContext, Fragment } from "react";
import { Button } from "@material-ui/core";
import {
  Btn,
  ModalCloseBtn,
  NegativeButton,
  PositiveButton,
} from "../../components/Buttons/styles";
import { modalContext } from "../../components/modal/modal";
import { CustomTextField } from "../../components/TextFields";
import { ButtonContainer } from "../../components/Buttons/styles";
import styled from "@emotion/styled";
import usePatientsApi from "../../api/patient.api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import DateInput from "../../components/Input/dateInput";
import dayjs from "dayjs";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PinIcon from "@mui/icons-material/Pin";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  ListItemIcon,
  ListItemText,
  List,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FormActiosn } from "../../global/redux/form";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import useIllnessApi from "../../api/illness.api";
import VoiceInput from "../../components/Input/voiceInput";
import TextContainer from "../../components/Texts/TextContainer";
// import { deleteForm } from "../../global/redux/form"
import EmailIcon from "@mui/icons-material/Email";
import CanadianPhoneField from "../../components/Input/phoneNumberInput";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { RowBox } from "../../global/table";
import CommentInput from "../forms/components/commentInput";
import colors from "../../global/colors";
import { TOPIC } from "../../components/Texts/styles";
import { PostalCode } from "../../components/Input/postalCodeInput";
import formatPhoneNumber from "../../utils/formatPhoneNumber";

const PatientFormSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  birthDay: yup.string(),
  postalCode: yup.string(),
  phone: yup.string(),
  email: yup.string().email(),
  address: yup.string(),
  gender: yup.string(),
  city: yup.string(),
  province: yup.string(),
});
const Label = styled(FormControlLabel)({
  "& .css-fyccqg-MuiTypography-root": {
    fontSize: "1rem",
  },
});
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #c5d4ea;
  padding: 1rem;
  border-radius: 8px 8px 0 0;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  align-items: center;
  width: 100%;
  padding: 1rem;
  max-height: 85vh;
`;
export const AddModal = ({ dataLoader, patient }) => {
  
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <Btn variant="contained" onClick={() => setOpen(true)}>
        <AddIcon />
      </Btn>
      {open && (
        <PatientForm
          title="add new patient"
          open={open}
          onClose={() => setOpen(false)}
          patient={patient}
          dataLoader={dataLoader}
        />
      )}
    </Fragment>
  );
};
export const EditModal = ({ dataLoader, patient }) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <EditIcon />
      </IconButton>
      {open && (
        <PatientForm
          title="edit patient"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          patient={patient}
          dataLoader={dataLoader}
        />
      )}
    </Fragment>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const DeleteModal = ({ id, dataLoader }) => {
  const { setShow } = useContext(modalContext);
  const api = usePatientsApi();
  const removePatient = () => {
    api
      .removePatient(id)
      .then(() => {
        dataLoader();
      })
      .then(() => {
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container>
      <TextContainer
        style={{ textTransform: "capitalize", marginBottom: "1rem" }}
      >
        are you sure you want to delete this patient?
      </TextContainer>
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={removePatient}
        >
          Delete
        </Button>
      </ButtonContainer>
    </Container>
  );
};
function OtherModal() {
  const { setShow } = useContext(modalContext);
  const [formData, setData] = useState({
    value: "",
    comment: "",
  });
  const { value, comment } = formData;

  const onChange = (key, value) => {
    setData({
      ...formData,
      [key]: value,
    });
  };

  return (
    <React.Fragment>
      <CommentInput
        inAccordin={false}
        value={comment}
        handleChange={(value) => onChange("comment", value)}
        label="Other Ailment"
        placeholder="add your comment here..."
      />
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={() => {
            setShow(false);
          }}
        >
          ok
        </Button>
      </ButtonContainer>
    </React.Fragment>
  );
}
const illnesses = [
  "heartburn",
  "hemorrhoids",
  "Allergy",
  "cold sores",
  "oral thrush",
  "pink eye",
  "tick bite",
  "period",
  "urinary tract infection",
  "other",
];
const Form = styled(FormControl)({
  "&&": {
    marginBottom: "1rem",
    background: "white",
    width: "100%",
    borderRadius: "10px",
  },
  "&label": {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  "&legend": {
    fontSize: "0.6rem",
  },
  "&.MuiInputBase-input": {
    width: "100%",
  },
  "& fieldset": {
    width: "100%",
    borderRadius: "10px",
  },
});
const Item = styled(MenuItem)({
  textTransform: "capitalize",
  fontSize: "1rem",
});
export default function CreateFormModal({ patient }) {
  const [illness, setIllness] = useState(null);
  const { setShow, setContent } = useContext(modalContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const handleOpenModal = (type) => {
  //   if (type === "other-ailment") {
  //     setContent({
  //       title: "other ailment",
  //       body: <OtherModal />,
  //     });
  //   }
  //   setShow(true);
  // };
  return (
    <Container>
      <Form fullWidth>
        <InputLabel id="demo-simple-select-label">Ailment</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={illness}
          label="Ailment"
          MenuProps={{
            PaperProps: {
              sx: {
                width: "inherit",
                "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                  maxHeight: "40vh",
                },
              },
            },
          }}
          onChange={(e) => setIllness(e.target.value)}
        >
          {illnesses.map((illness, index) => {
            return (
              <Item value={index + 1} key={index}>
                {illness}
              </Item>
            );
          })}
        </Select>
      </Form>
      &nbsp;
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={() => {
            if (illness) {
              // dispatch(deleteForm())
              dispatch(FormActiosn.clear());
              setShow(false);
              if (patient) {
                dispatch(
                  FormActiosn.setPatient({
                    navigate: true,
                    illness: illnesses[illness - 1],
                    patient,
                  })
                );
              }
              navigate(`forms/new/${illnesses[illness - 1]}`);
            }
          }}
        >
          Next
        </Button>
      </ButtonContainer>
    </Container>
  );
}
export const AddAilmentsModal = ({ patientId, onSubmit }) => {
  const { setShow } = useContext(modalContext);
  const [newItem, setNewItem] = useState("");
  const [description, setDescription] = useState("");
  const api = useIllnessApi();

  const handleAddItem = () => {
    if (newItem.trim() !== "") {
      api
        .addIllnessToPatient(patientId, { name: newItem, desc: description })
        .then(() => {
          setNewItem("");
          setShow(false);
          if (onSubmit) onSubmit();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Container>
      <CustomTextField
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
        label="Title"
        variant="outlined"
        fullWidth
        contentEditable={false}
        style={{ marginBottom: "1.5rem" }}
      />
      <VoiceInput
        value={description}
        label="Description"
        handleChange={(value) => {
          setDescription(value);
        }}
      />
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={handleAddItem}
        >
          add
        </Button>
      </ButtonContainer>
    </Container>
  );
};
export const EditAilmentModal = ({ ailmentId, createdAt, desc, onSubmit }) => {
  const { setShow } = useContext(modalContext);
  const [_desc, setDesc] = useState(desc);

  const api = useIllnessApi();
  function onHandleChanges() {
    api
      .editIllness(ailmentId, {
        desc: _desc,
      })
      .then(() => {
        setShow(false);
        if (onSubmit) onSubmit();
      })
      .catch((err) => {
        console.error(err);
      });
    setShow(false);
  }
  return (
    <Container style={{ padding: ".5rem 0" }}>
      <TextContainer
        textTransform="capitalize"
        textAlign="left"
        justifyContent="left"
        fontSize="1.1rem"
      >
        created on : {dayjs(createdAt).format("DD/MM/YYYY")}
      </TextContainer>
      &nbsp;
      <VoiceInput
        value={_desc}
        label="Description"
        placeholder="Add description here"
        key={"desc"}
        handleChange={(value) => setDesc(value)}
      />
      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={() => setShow(false)}
          style={NegativeButton}
        >
          cancel
        </Button>
        &nbsp;
        <Button
          variant="contained"
          style={PositiveButton}
          onClick={onHandleChanges}
        >
          save
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const CustomText = styled(ListItemText)({
  "&& .MuiTypography-root": {
    fontSize: "1rem",
  },
});
export function PatientInfo({ patient }) {
  return (
    <List
      style={{
        width: "100%",
        padding: 0,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <TextContainer>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <CustomText primary="Name" />
        &nbsp;
        <TextContainer fontSize="1rem" color={colors.darkBlue}>
          {" "}
          {`${patient.firstName} ${patient.lastName}`}
        </TextContainer>
      </TextContainer>
      <TextContainer>
        <ListItemIcon>
          <PhoneIcon />
        </ListItemIcon>
        <CustomText primary="Phone Number" />
        &nbsp;
        <TextContainer fontSize="1rem" color={colors.darkBlue}>
          {formatPhoneNumber(patient.phone)}
        </TextContainer>
      </TextContainer>
      <TextContainer>
        <ListItemIcon>
          <EventAvailableIcon />
        </ListItemIcon>
        <CustomText primary="Birthday" />
        &nbsp;
        <TextContainer fontSize="1rem" color={colors.darkBlue}>
          {" "}
          {patient.birthDay?.split("T")[0]}
        </TextContainer>
      </TextContainer>
      <TextContainer>
        <ListItemIcon>
          <PinIcon />
        </ListItemIcon>
        <CustomText primary="PostalCode" />
        &nbsp;
        <TextContainer fontSize="1rem" color={colors.darkBlue}>
          {patient?.postalCode}
        </TextContainer>
      </TextContainer>
      <TextContainer>
        <ListItemIcon>
          <LocationOnIcon />
        </ListItemIcon>
        <CustomText primary="Address" />
        &nbsp;
        <TextContainer fontSize="1rem" color={colors.darkBlue}>
          {patient?.address}
        </TextContainer>
      </TextContainer>
      <TextContainer>
        <ListItemIcon>
          <ApartmentIcon />
        </ListItemIcon>
        <CustomText primary="City, Province" />
        &nbsp;
        <TextContainer fontSize="1rem" color={colors.darkBlue}>
          {`${patient?.city}, ${patient?.province}`}
        </TextContainer>
      </TextContainer>
    </List>
  );
}

export const PatientForm = ({ dataLoader, patient, onClose, open, title }) => {

  const api = usePatientsApi();
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PatientFormSchema),
    defaultValues: {
      ...patient,
      birthDay: patient ? dayjs(patient.birthDay) : null,
    },
  });

  const isEdit = patient ? true : false;

  const onSubmit = handleSubmit((data) => {
    if (isEdit) {
      api
        .updatePatient(patient._id, data)
        .then(() => {
          onClose();
          dataLoader();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      api
        .addPatient(data)
        .then(() => {
          onClose();
          dataLoader();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      sx={{
        "& .css-1t7tfl2-MuiPaper-root-MuiDialog-paper": {
          maxHeight: "70vh",
          borderRadius: ".5rem",
          maxWidth: "500px",
        },
      }}
    >
      <Header>
        <TextContainer {...TOPIC}>{title}</TextContainer>
        <Button onClick={() => onClose()} style={ModalCloseBtn}>
          X
        </Button>
      </Header>
      <Content>
        <Box style={{ overflow: "scroll", width: "100%", padding: "1rem 0" }}>
          <RowBox>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  type="text"
                  label="First Name"
                  variant="outlined"
                  helperText={errors?.firstName ? errors.firstName.message : ""}
                  error={!!errors?.firstName}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  type="text"
                  label="Last Name"
                  variant="outlined"
                  helperText={!!errors.lastName}
                  error={errors?.lastName ? errors.lastName.message : ""}
                />
              )}
            />
          </RowBox>
          <FormControl style={{ marginBottom: "1rem", padding: "0 .5rem" }}>
            <RadioGroup
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ fontSize: "1rem" }}
              >
                Gender :
              </FormLabel>
              &nbsp;&nbsp;
              <Label
                value="female"
                checked={watch("gender") === "female"}
                onChange={(event) => setValue("gender", event.target.value)}
                control={<Radio />}
                label="Female"
              />
              <Label
                value="male"
                checked={watch("gender") === "male"}
                onChange={(event) => setValue("gender", event.target.value)}
                control={<Radio />}
                label="Male"
              />
              <Label
                value="other"
                checked={watch("gender") === "other"}
                onChange={(event) => setValue("gender", event.target.value)}
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          <Controller
            name="birthDay"
            control={control}
            render={({ field }) => (
              <DateInput
                {...field}
                label="Date Of Birth(MM/DD/YYYY)"
                error={!!errors?.birthDay}
                helperText={errors?.birthDay ? errors.birthDay.message : ""}
              />
            )}
          />
          <RowBox>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <CanadianPhoneField
                  {...field}
                  error={!!errors.phone}
                  helperText={errors?.phone ? errors.phone.message : ""}
                  variant="outlined"
                  label="Phone Number"
                  type="tel"
                  placeholder="Enter Your Number..."
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  error={!!errors.email}
                  helperText={errors?.email ? errors.email.message : ""}
                  variant="outlined"
                  type="email"
                  label="Email Address"
                  placeholder="Enter Your Email..."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailIcon width="18px" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </RowBox>
          <Controller
            name="postalCode"
            control={control}
            render={({ field }) => (
              <PostalCode
                {...field}
                error={!!errors.postalcode}
                helperText={errors?.postalcode ? errors.postalcode.message : ""}
                label="Postal Code"
                // onInput={(e) => {
                //   if (e.target.value.length >= 7) {
                //     e.target.value = e.target.value.slice(0, 7);
                //     return;
                //   }
                // }}
                placeholder="Enter Your Postal Code..."
              />
            )}
          />
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <CustomTextField
                {...field}
                error={!!errors.address}
                helperText={errors?.address ? errors.address.message : ""}
                variant="outlined"
                label="Address"
                multiline
                minRows={2}
                maxRows={3}
                placeholder="Enter Your Address..."
              />
            )}
          />
          <RowBox>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  type="text"
                  label="City"
                  variant="outlined"
                  helperText={!!errors.city}
                  error={errors?.city ? errors.city.message : ""}
                />
              )}
            />
            <Controller
              name="province"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  type="text"
                  label="Province"
                  variant="outlined"
                  helperText={!!errors.province}
                  error={errors?.province ? errors.province.message : ""}
                />
              )}
            />
          </RowBox>
        </Box>
        <ButtonContainer>
          <Button
            variant="outlined"
            onClick={() => onClose()}
            style={NegativeButton}
          >
            cancel
          </Button>
          &nbsp;
          <Button variant="contained" style={PositiveButton} onClick={onSubmit}>
            {isEdit ? "Save" : "Add"}
          </Button>
        </ButtonContainer>
      </Content>
    </Dialog>
  );
};
