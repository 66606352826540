import { Fragment, createContext, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ReactDOM from "react-dom/client";

const pdfContext = createContext();

export default function PdfProvider({ children }) {
  const printRef = useRef(null);
  //   const [generator, setGenerator] = useState({ component: null, props: null });
  const generator = useRef(null);
  const generatorProps = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => {
      const Component = generator.current;
      const props = generatorProps.current;
      return new Promise((res, rej) => {
        const root = ReactDOM.createRoot(
          document.getElementById("pdf-page-worker")
        );
        root.render(<Component {...props} />);
        res();
      });
    },
  });

  function generatePdf({ component, props }) {
    generator.current = component;
    generatorProps.current = props;
    handlePrint();
  }

  return (
    <pdfContext.Provider value={{ generatePdf }}>
      <Fragment>
        <div style={{ display: "none" }}>
          <div ref={printRef} id="pdf-page-worker"></div>
        </div>
        {children}
      </Fragment>
    </pdfContext.Provider>
  );
}

export function usePdf() {
  const value = useContext(pdfContext);
  return value;
}
