
import { createSlice } from "@reduxjs/toolkit";


export const mainFrameSlice = createSlice({
  name: "mainFrame",
  initialState: {
    activeTab: "Categories",
    adminUserName: "",
  },
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setAdminUserName: (state, action) => {
      state.adminUserName = action.payload;
    },
  },
});

export const {setActiveTab,setAdminUserName} = mainFrameSlice.actions;
export default mainFrameSlice.reducer

