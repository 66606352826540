import { TextField, InputAdornment, IconButton, Select } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useStyles } from "../../components/Texts/styles";
import { styled } from '@mui/material/styles';
import colors from '../../global/colors';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { transform } from 'lodash';

export const CustomTextField = styled(TextField)({
  position: "relative",
  "&&": {
    marginBottom: ".7rem",
    justifyContent: "flex-end",
    background: colors.white,
    position: "relative",
    width: "100%",
    borderRadius: "10px"
  },
  "& label": {
    fontSize: "1rem",
  },
  "& .MuiFilledInput-multiline": {
    flexDirection: "column-reverse",
  },
  "& legend": {
    fontSize: "0.8rem"
  },
  "& fieldset": {
    borderRadius: "10px",
  },
  "&.MuiFilledInput-root": {
    borderRadius: '25px',
  },
  '& .MuiOutlinedInput-input': {
    padding: "1rem"
  },
  '& .MuiOutlinedInput-multiline': {
    minHeight: "100px"
  },
  '& .MuiMenu-list': {
    borderRadius: "10px",
  }


})
export const CustomSearchField = styled(TextField)({
  position: "relative",
  "&&": {
    justifyContent: "center",
    background: colors.white,
    position: "relative",
    width: "100%",
    borderRadius: "20px",
    margin: ".5rem"
  },
  "& label": {
    fontSize: "1rem",
  },
  "& .MuiFilledInput-multiline": {
    flexDirection: "column-reverse",
  },
  "& legend": {
    fontSize: "0.8rem"
  },
  "& fieldset": {
    borderRadius: "20px",
  },
  "&.MuiFilledInput-root": {
    borderRadius: '25px',
  },
  '& .MuiOutlinedInput-input': {
    padding: "10.5px 0 10.5px 6px",
  },
  '& .MuiOutlinedInput-multiline': {
  },
  "& .MuiInputLabel-outlined": {
    transform: "translate(11px, 12px) scale(1)"
  },
  '& .MuiMenu-list': {
    borderRadius: "10px",
  }


})

export const CustomDateInput = styled(DatePicker)({
  "&.MuiTextField-root": {
    width: "100%",
    marginBottom: "1rem"
  },
  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderRadius: '.5rem',
  },
  "& label": {
    fontSize: "1rem",
  },
  "& legend": {
    fontSize: "0.8rem"
  },
  "& fieldset": {
    borderRadius: "10px",
  },
})

export const CustomNumberField = styled(TextField)({
  "&&": {
    justifyContent: "flex-end",
    background: colors.white,
    width: "100%",
  },
  "& label": {
    fontSize: "1rem",
    fontWeight: "bold"
  },
  "& legend": {
    fontSize: "0.6rem"
  },
  "& fieldset": {
    borderRadius: "10px",
  },
  "&.MuiFilledInput-root": {
    borderRadius: '25px',
  },
  '& .MuiOutlinedInput-input': {
    padding: "5px",
    textAlign: "center"
  }
})

export const CustomPasswordFiled = (props) => {
  const [showPass, setShowPass] = useState(false);
  const classes = useStyles();
  const handleClickShowPassword = () => {
    setShowPass(!showPass)
  }
  return (
    <CustomTextField
      type={showPass ? "text" : "password"}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={classes.smallerIcon}
              onClick={handleClickShowPassword}
              edge="end">
              {showPass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export const handleNumber = (onChange) => (event) => {
  //english and persion number only
  const regex = /^[0-9\u06F0-\u06F9]+$/;

  if (event.target.value.length > 11)
    return;

  if (regex.test(event.target.value)) {
    const englishNumber = event.target.value.replace(/[۰-۹]/g, function (d) {
      return String.fromCharCode(d.charCodeAt(0) - 1728);
    })
    onChange(englishNumber);
    return;
  }
  if (isNaN(event.target.value.charCodeAt(0)))
    onChange('')

}


