import { useMediaQuery } from "@material-ui/core";
// import TextContainer from "../../../components/Texts/TextContainer";
import TextContainer from "../../../../components/Texts/TextContainer";
import { TOPIC } from "../../../../components/Texts/styles";
import styled from "@emotion/styled";
import colors from "../../../../global/colors";
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";

// import { getFormData, setFormData } from "../../../../global/redux/form.action"
import { CustomTextField } from "../../../../components/TextFields";
import BasicAccordion from "../../../../components/Buttons/collapsibleButton";
import { Box, Checkbox, Container, Typography } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

// import { getForm, setForm, updateForm } from "../../../../global/redux/form";

import AddItemInput from "../simpleQuestion/addItemInput";
import { FormControlLabel } from "@material-ui/core";
import CommentInput from "../commentInput";
import { FormActiosn } from "../../../../global/redux/form";
import Layout from "../edit/layout";
import { isEqual } from "lodash";

const QuestionBox = styled(Box)({
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
  textAlign: "center",
  zIndex: 2,
  maxHeight: "65vh",
  overflow: "auto",
  height: "fit-content",
  borderRadius: ".5rem",
  boxShadow: colors.mainShadow,
});

const Item = styled(FormControlLabel)({
  "& .MuiTypography-body1": {
    fontSize: "1rem",
  },
});
function MultiSelectQuestion({
  question,
  answers,
  formId,
  defaultValue,
  cancleAllIndex,
  handleSelect,
  require,
  formNavigator,
  select,

  dispatch,
}) {
  const [err, setErr] = useState("");
  const [formData, setData] = useState({
    selectedItems: [],
    ...defaultValue,
  });

  useEffect(() => {
    setData({
      selectedItems: [],
      ...defaultValue,
    });
  }, [formId, defaultValue]);

  const { selectedItems = [], comment = "" } = formData;

  const saveFormData = (data) => {
    dispatch(
      FormActiosn.updateFormData({
        formId,
        formData: data,
      })
    );
  };

  const onChange = (key, value) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const onSelect = (index, checked) => {
    if (index === cancleAllIndex) {
      return onChange("selectedItems", [index]);
    }
    let tmp = [...selectedItems];
    if (tmp.includes(cancleAllIndex)) tmp = [];

    if (checked) tmp.push(index);
    else tmp = tmp.filter((item) => item != index);
    onChange("selectedItems", select ? [index] : tmp);
  };
  const onGoNext = () => {
    if (!selectedItems.length) return setErr("One selection is required!");
    setErr("");
    saveFormData(formData);
    return formNavigator(handleSelect(1));
  };
  return (
    <Layout
      firstButton={{
        text: "Previous",
        onClick: () => formNavigator(-1),
      }}
      secondButton={{
        text: "Next",
        onClick: onGoNext,
      }}
    >
      <QuestionBox>
        <TextContainer
          style={{
            background: colors.white,
            textAlign: "center",
            width: "100%",
            justifyContent: "center",
            position: "sticky",
            top: 0,
            zIndex: 3,
          }}
        >
          &nbsp;
          {question}
        </TextContainer>
        &nbsp;
        {err !== "" && (
          <TextContainer
            style={{
              fontSize: "1rem",
              textAlign: "center",
              width: "100%",
              justifyContent: "center",
              padding: ".5rem 0",
              color: "red",
            }}
          >
            <ReportProblemIcon
              fontSize="1rem"
              style={{ marginRight: ".5rem" }}
            />
            {err}
          </TextContainer>
        )}
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0 2rem",
            width: "100%",
            justifyContent: "center",
            gap: ".5rem",
            marginBottom: "1rem",
          }}
        >
          {answers.map((item, index) => {
            return (
              <Item
                key={index}
                style={{
                  textAlign: "left",
                  width: "100%",
                  alignSelf: "center",
                }}
                control={<Checkbox color="primary" />}
                checked={selectedItems.includes(index)}
                onChange={(ev, ch) => onSelect(index, ch)}
                label={item.text}
              />
            );
          })}
        </Box>
        <CommentInput
          handleChange={(value) => onChange("comment", value)}
          title="Comment"
          label="Description"
          value={comment}
          placeholder="Add Your Description Here"
        />
      </QuestionBox>
    </Layout>
  );
}
function mapStateToProps(state, ownProps) {
  let formData = state.forms.data[ownProps.formId];
  formData = formData ? formData : {};
  console.log(formData);
  return {
    ...ownProps,
    defaultValue: {
      ...ownProps.defaultValue,
      ...formData,
    },
  };
}
export default connect(mapStateToProps)(
  memo(MultiSelectQuestion, function (prev, next) {
    return isEqual(prev, next);
  })
);
