import * as React from "react";
import Footer, {
  CustomTableBody,
  CustomTableContainer,
  TableHeader,
} from "../../components/table";
import Table, { Row } from "../../global/table";
import { Paper, TableCell, TableRow } from "@mui/material";
import Filter from "../../components/filter/filter";
import useFormApi from "../../api/forms.api";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export default function PatientForms({
  filters,
  patientId,
  open,
}) {
  const [forms, setForms] = React.useState([]);
  const [query, setQuery] = React.useState({
    page: 0,
    size: 20,
  });
  const api = useFormApi();
  const navigate = useNavigate();
  const getPatientForms = (query = {}) => {
    api
      .getPatientForms(patientId, query)
      .then((data) => {
        setForms(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getPatientForms(query);
  }, [query]);

  return (
    <>
      <Filter
        convertor={"toMongoo"}
        filters={filters}
        dataLoader={(q) =>
          setQuery((prev) => ({
            page: prev.page,
            size: prev.size,
            ...q,
          }))
        }
      />
      <CustomTableContainer
        component={Paper}
        style={{ maxHeight: open ? "30vh" : "34vh" }}
      >
        <Table aria-label="simple table" stickyHeader>
          <TableHeader>
            <TableRow>
              <TableCell style={{ padding: 5 }} width="33.5%">
                Ailments
              </TableCell>
              <TableCell style={{ padding: 5 }} width="33.5%">
                Date Created
              </TableCell>
              <TableCell style={{ padding: 5 }} width="33.5%">
                Created By
              </TableCell>
            </TableRow>
          </TableHeader>
          <CustomTableBody>
            {forms?.map((form, index) => {
              return (
                <Row
                  key={index}
                  onClick={() => {
                    navigate("/form", {
                      state: {
                        formId: form._id,
                        patient: form.patient,
                      },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell style={{ padding: 5 }}>{form.ailment}</TableCell>
                  <TableCell style={{ padding: 5 }}>
                    {dayjs(form.createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell style={{ padding: 5 }}>
                    {form.isCreatedByOwner
                      ? form.owner.userName
                      : form.patient.userName}
                  </TableCell>
                </Row>
              );
            })}
          </CustomTableBody>
        </Table>
        <Footer
          value={query}
          handleChange={(e) => {
            setQuery((prev) => ({
              ...prev,
              ...e,
            }));
          }}
        />
      </CustomTableContainer>
    </>
  );
}
